.ranking__legend {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .home & {
        margin-bottom: 95px;
        .ma-legend__title {
            font-size: 14px;
        }
        .ma-legend {
            flex-wrap: nowrap;
            height: auto;
        }
    }
}
.ma-legend__title {
    .sans-serif-heavy;
    font-size: 11px;
    line-height: 1;
    color: black;
    width: 100%;
    margin: 0;
    margin-bottom: 15px;
    .font-smoothing();
}
.ma-legend {
    padding: 0;
    margin: 0;
    list-style: none;
    .sans-serif-book;
    font-size: 11px;
    line-height: 13px;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    height: 100px;
    width: 100%;
    @media (min-width: @screen-sm) {
        width: 90%;
    }

    li {
        width: 50%;
        margin-bottom: 8px;
        span {
            width: 15px;
            height: 12px;
            display: block;
            position: relative;
            margin: 0;
            padding: 0;
            float: left;
            margin-right: 6px;
        }
    }
}

.cpti-legend {
    padding: 0;
    margin: 0;
    list-style: none;
    .sans-serif-book;
    font-size: 11px;
    width: 100%;
    margin-top: 30px;
    li {
        width: 50%;
        margin-bottom: 8px;
        @media (min-width: @screen-sm) {
            width: 24%;
        }
        float: left;
        display: inline-block;
        span {
            width: 14px;
            height: 12px;
            display: block;
            position: relative;
            margin: 0;
            padding: 0;
            float: left;
            margin-right: 5px;
        }
    }
}




.longread__paragraphs-below {
    @media (min-width: @screen-sm) {
        margin-top: 70px;
        column-count: 2;
        column-gap: 40px;
    }

     @media (min-width: @screen-md) {
        column-gap: 100px;
     }
}