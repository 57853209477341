/* Fonts */

@font-face {
	font-family:'AvenirLTStd-Book';
	src: url('../../assets/fonts/AvenirLTStd-Book_gdi.eot');
	src: url('../../assets/fonts/AvenirLTStd-Book_gdi.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/AvenirLTStd-Book_gdi.woff') format('woff'),
		url('../../assets/fonts/AvenirLTStd-Book_gdi.ttf') format('truetype'),
		url('../../assets/fonts/AvenirLTStd-Book_gdi.svg#AvenirLTStd-Book') format('svg');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-25CA;
}

@font-face {
	font-family:'AvenirLTStd-Medium';
	src: url('../../assets/fonts/AvenirLTStd-Medium_gdi.eot');
	src: url('../../assets/fonts/AvenirLTStd-Medium_gdi.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/AvenirLTStd-Medium_gdi.woff') format('woff'),
		url('../../assets/fonts/AvenirLTStd-Medium_gdi.ttf') format('truetype'),
		url('../../assets/fonts/AvenirLTStd-Medium_gdi.svg#AvenirLTStd-Medium') format('svg');
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-25CA;
}

@font-face {
	font-family:'AvenirLTStd-Heavy';
	src: url('../../assets/fonts/AvenirLTStd-Heavy_gdi.eot');
	src: url('../../assets/fonts/AvenirLTStd-Heavy_gdi.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/AvenirLTStd-Heavy_gdi.woff') format('woff'),
		url('../../assets/fonts/AvenirLTStd-Heavy_gdi.ttf') format('truetype'),
		url('../../assets/fonts/AvenirLTStd-Heavy_gdi.svg#AvenirLTStd-Heavy') format('svg');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-25CA;
}