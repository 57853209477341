

.container-mission {
	.main-column {
		.make-xs-column(12);
		.make-sm-column(10);
		.make-sm-column-push(1);
		text-align: center;

		p {
			.paragraph;
		}
	}
    padding-bottom: 60px;
}

.menu-about {
    margin-left: -10px;
    margin-right: -10px;
	overflow: hidden;
	text-align: center;
	.excerpt-page {
		margin-bottom: 0;
		@media (min-width: @screen-sm-min) {
			width: 130px;
		}
	}
    @media (min-width: @screen-sm-min) {
        position: sticky;
        bottom: 0;
    }
}

.swiper-container {
	.about-column {
        @media (min-width: @screen-sm-min) {
            min-height: 350px !important;
        }
		.line-wrapper {
			display: block;
			position: relative;
			&:after {
				content: '';
				position: absolute;
				width: 2px;
				height: 1000px;
				background: @geel-licht;
				display: block;
				left: 50%;
				margin-left: -1px;
				margin-top: 20px;
				@media (max-width: @screen-xs-max) {
					display: none;
				}
			}
		}
	}
}

.about-content {
	padding-top: 50px;
}


.about {
	.wrapper-height.bg-noise-gray {
		@media (min-width: @screen-sm-min) {
			position: relative;
			overflow: visible;
			&:after {
				background: url(../../img/noise_gray.png) repeat !important;
				content: '';
				bottom: -100px;
				left: 0;
				display: block;
				width: 100%;
				position: absolute;
				height: 100px;
			}
		}
	}
}
