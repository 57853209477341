// ATS grid


.media-object, .thumb {
	//filter: contrast(50%) brightness(99999999%);
}

.ats-grid {
	.clearfix();
	max-width: 1024px;
	margin: 0 auto;

	[class*='ats-col-'] {
		float: left;
		padding-right: 10px;
		background: red;

		&:last-of-type {
			padding-right: 0;
		}
	}

	.ats-col-1-10 {
		width: 10%;
	}

	.ats-col-2-10 {
		width: 20%;
	}

	.ats-col-3-10 {
		width: 30%;
	}

	.ats-col-4-10 {
		width: 40%;
	}

	.ats-col-5-10 {
		width: 50%;
	}

	.ats-col-6-10 {
		width: 60%;
	}

	.ats-col-7-10 {
		width: 70%;
	}

	.ats-col-8-10 {
		width: 80%;
	}

	.ats-col-9-10 {
		width: 90%;
	}

	.ats-col-10-10 {
		width: 100%;
	}
}

// .hoi {
// 	background: red;
// }

// .doei {
// 	background: blue;
// }

// Mixins

.reset-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

// Wrapper

.container-ats-full {
	width: 100%;
	margin: 0 auto;
}

.container-ats {
	width: 100%;
	max-width: 1024px;
	margin: 0 auto;
}

.container--wider{
	max-width:  (1004px + @grid-gutter-width)!important;
	margin-right: auto;
    margin-left: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.container-ats-small {
	max-width: 586px;
	margin: 0 auto;
}

@media (max-width: @screen-xs-max) {
	.container-ats-full, .container-ats {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (max-width: 586px) {
	.container-ats-small {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.bg-gray {
	background: @grijs-licht;
}

.bg-gray-dark {
	background: @grijs-donker;
}

.bg-brown {
	background: @bruin-donker;
}

.bg-brown-dark {
	background: #323232;
}

.bg-raster{
	background: @grijsachterraster;
}

.bg-noise-yellow {
	background: url(../img/noise_yellow.png) repeat;
}

.bg-noise-yellow-bright {
	background: url(../img/noise_yellow_bright.png) repeat;
}


.bg-noise-blue, .bg-noise-global {
	background: url(../img/noise_blue.png) repeat;
}
.bg-noise-blue-light {
	background: url(../img/noise_blue_light.png) repeat;
}
.bg-noise-blue-dark {
	background: url(../img/noise_blue_dark.png) repeat;
}

.bg-noise-gray {
	background: url(../img/noise_gray.png) repeat;
}

.bg-noise-gray-medium {
	background: url(../img/noise_gray_medium.png) repeat;
}

.bg-noise-gray-dark {
	background: url(../img/noise_gray_dark.png) repeat;
}
.bg-noise-gray-darkish {
	background: url(../img/noise_gray_darkish.png) repeat;
}
.bg-noise-gray-darker {
	background: url(../img/noise_gray_darker.png) repeat;
}
.bg-noise-gray-light {
	background: url(../img/noise_gray_light.png) repeat;
}

.bg-noise-rubine-dark, .bg-noise-b-dark {
	background: url(../img/noise_rubine_dark.png) repeat;
}
.bg-noise-rubine-light, .bg-noise-ssea {
	background: url(../img/noise_rubine.png) repeat;
}

.bg-noise-purple-dark, .bg-noise-a-dark {
	background: url(../img/noise_purple_dark.png) repeat;
}
.bg-noise-purple, .bg-noise-purple-light, .bg-noise-a {
	background: url(../img/noise_purple.png) repeat;
}
.bg-noise-rubine, .bg-noise-b {
	background: url(../img/noise_rubine.png) repeat;
}
.bg-noise-mustard, .bg-noise-c {
	background: url(../img/noise_yellow.png) repeat;
}
.bg-noise-mustard-dark, .bg-noise-c-dark {
	background: url(../img/noise_yellow_dark.png) repeat;
}
.bg-noise-pink, .bg-noise-d {
	background: url(../img/noise_pink.png) repeat;
}
.bg-noise-pink-dark, .bg-noise-d-dark {
	background: url(../img/noise_pink_dark.png) repeat;
}
.bg-noise-green, .bg-noise-green-light, .bg-noise-e, .bg-noise-wca {
	background: url(../img/noise_green.png) repeat;
}
.bg-noise-green-dark, .bg-noise-e-dark {
	background: url(../img/noise_green_dark.png) repeat;
}
.bg-noise-orange, .bg-noise-orange-light, .bg-noise-f, .bg-noise-esa {
	background: url(../img/noise_orange.png) repeat;
}
.bg-noise-orange-dark, .bg-noise-f-dark{
	background: url(../img/noise_orange_dark.png) repeat;
}
.bg-noise-brown, .bg-noise-g {
	background: url(../img/noise_brown.png) repeat;
}
.bg-noise-brown-dark, .bg-noise-g-dark {
	background: url(../img/noise_brown_dark.png) repeat;
}

.wrapper-full-height {
	min-height: 100%;
	overflow: hidden;

	&.fixed {
		position: fixed;
		width: 100%;
	}
}





html, body{
  padding: 0;
  margin: 0;

}
html{
  height: 100%;
}
body{
  min-height: 100%;
  overflow: visible;
  padding-top: 44px;

}





.wrapper-content {
	position: relative;
	min-height: 100%;

	&.fixed {
		position: fixed;
	}
}





.wrapper {
	.container-fluid;
	.row {
		.make-row();
		.container {
			.container;
			.row {
				margin: 0;
				.half-column {
					.make-xs-column(12);
					.make-sm-column(6);
				}
				.whole-column {
					.make-xs-column(12);
				}
			}
		}
	}
}


h1 {
	.h1;
	padding: 30px 0;
	margin: 0;
}



.spacer {
	height: 100px;
}




.excerpt-page {
	color: @geel-licht;
	transition-property: color;
	transition-duration: .4s;

	text-align: center;
	display: inline-block;
	.sans-serif;

	width: 182px;
	margin: 18px 12px 18px 12px;
	vertical-align: top;


	h3 {
		.sans-serif;
		.sans-serif-heavy;
		color: inherit;
		margin-top: 10px;
		margin-bottom: 16px;
	}

	.rectangle {
		.square(44px);
		border: 2px solid @geel-licht;
		margin: 0 auto;
	}

	.circle {
		.square(44px);
		border: 2px solid @geel-licht;
		border-radius: 50%;
		margin: 0 auto;
	}

	p {
		font-family:'AvenirLTStd-Book';
		margin: 0;
	}

	.shape {
		.square(46px);
		margin: 0 auto;
		transition-property: fill, filter, -webkit-filter;
		transition-duration: .4s;
	}

	.icon-hover {
		path, rect, polygon, polyline {
			fill: @geel-licht;
			transition-property: fill;
			transition-duration: .4s;
		}
	}

	&:hover, &:active, &:focus {
		text-decoration: none;
		color: #fff;
		transition-property: color;
		transition-duration: .4s;
		.icon-hover {
			path, rect, polygon, polyline, tspan {
				fill: #fff;
				transition-property: fill;
				transition-duration: .4s;
			}
		}

		.shape {
			filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.2));
			transition-property: fill, filter, -webkit-filter;
			transition-duration: .4s;
		}

		.rectangle, .circle {
			//border-color: #fff;
		}
	}
}