.modal-wrapper {
	position: relative;
	padding: 20px;
	display: block;
}

.modal {
	display: block;
	position: relative;
	background: url(../../img/noise_gray_dark.png) repeat;
	width: 100%;
	height: 100%;

	.modal-table {
		display: table;
		width: 100%;
		height: 100%;
		position: relative;

		.modal-table-header {
			width: 100%;
			display: table-row;
		}

		.modal-table-content {
			display: table-row;
			height: 100%;
		}

		.modal-table-pagination {
			display: table-row;
			height: auto;
		}

		.modal-table-footer {
			width: 100%;
			display: table-row;
			height: auto;
		}

		.modal-table-cell {
			display: table-cell;
			vertical-align: middle;
			min-width: 80px;
			@media (max-width: @screen-xs-max) {
				display: none;
			}
		}
		.modal-table-cell--aligntop{
			vertical-align: top;
		}

		.main-modal-content {
			display: table-cell;
			width: 100%;
		}

	}


	.container-div {
		max-width: 100%;
		padding: 10px;
		@media (max-width: @screen-xs-max) {
			padding: 30px;
		}
		@media (min-width: @screen-md-min) {
			.container;
		}

		.row {
			.make-row();
			overflow: hidden;

			.half-column {
				.make-xs-column(12);
				.make-sm-column(6);
				color: @color-white;
				.paragraph;

				img {
					width: 100%;
					height: auto;
				}
			}
			.column-1 {
				.make-sm-column-push(6);
				@media (max-width: @screen-xs-max) {
					margin-bottom: 20px;
				}
			}
			.column-2 {
				.make-sm-column-pull(6);
			}
		}


	}



	.modal-title {
		position: relative;
		text-align: left;
		padding-top: 10px;
		padding-left: 20px;
		padding-right: 70px;
		padding-bottom: 10px;
		width: 100%;
		a {
			.sans-serif;
			.h3;
			color: @color-grey-lighter;
			&:hover, &:active, &:focus {
				text-decoration: none;
				color: @color-white;
			}
		}

	}

	.modal-close {
		.square(16px);
		display: block;
		position: absolute;
		right: 20px;
		top: 12px;

		z-index: 99;
		&:hover {
			svg {
				path {
					stroke: @color-white;
				}
			}
		}
	}


	.modal-arrow {
		background: rgba(0,0,0,0.2);
		display: inline-block;
		margin: 20px;
		margin-top: 10px;
		@media (max-width: @screen-xs-max) {
			display: none;
		}
		svg {
			display: block;
			polyline {
				stroke: @color-white-dark;
			}
		}
		&:hover, &:focus, &:active {
			background: rgba(0,0,0,0.25);
			svg {
				polyline {
					stroke: @color-white;
				}
			}
		}
	}

}


.single-post,
.single-person {
	.footer-site {
		display: none;
	}
}

.template-small_graph {
	.footer-site {
		display: none;
	}
}

.template-large_graph {
	.footer-site {
		display: none;
	}
}


.template-small_graph, .template-large_graph {

	.modal-table-footer, .modal-table-content {
		.paragraph;
		color: @color-white;
		text-align: center;
		.container {
			padding-top: 20px;
			padding-bottom: 20px;
			.graph {
				display: block;
				padding-top: 40px;
				padding-bottom: 60px;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}

}







