.wrapper-longread {
    .container-fluid;
    padding-left: 20px;
    padding-right: 20px;
}

.container-longread {
    .container-fluid;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1024px !important;
    position: relative;
    @media (min-width: @screen-lg) {
        .container;
        padding-left: 20px;
        padding-right: 20px;
        width: 1024px !important;
    }
}

.longread__row {
    .make-row();
    margin-left: -20px;
    margin-right: -20px;
}

.longread__row--flexbox {
    display: flex;
}

.longread__col--full {
    .make-xs-column(12);
    padding-left: 20px;
    padding-right: 20px;
}

.longread__col--mid {
    .make-xs-column(12);
    .make-sm-column(9);
    .make-sm-column-push(1.5);
    padding-left: 20px;
    padding-right: 20px;
}

.longread__col--half {
    .make-xs-column(12);
    .make-sm-column(6);
    padding-left: 20px;
    padding-right: 20px;
}

.longread__col--magazine {
    .make-xs-column(6);
    .make-sm-column(2.975);
    //.make-md-column(3);
}

.longread__col--wide {
    .make-xs-column(12);
    .make-sm-column(8);
    padding-left: 20px;
    padding-right: 20px;
}

.longread__col--small {
    .make-xs-column(12);
    .make-sm-column(4);
    padding-left: 20px;
    padding-right: 20px;
}

.longread__col--wider {
    .make-xs-column(12);
    .make-sm-column(7);
    padding-left: 20px;
    padding-right: 20px;
}

.longread__col--smaller {
    .make-xs-column(12);
    .make-sm-column(5);
    padding-left: 20px;
    padding-right: 20px;
}

.longread__col--quarter {
    .make-xs-column(12);
    .make-sm-column(3);
    padding-left: 20px;
    padding-right: 20px;
}


.longread__header {
    background-size: cover;
    background-position: center center;

    .longread__col--wide {
        .make-xs-column(12);
        .make-sm-column(6);
        padding-left: 20px;
        padding-right: 20px;

        @media (min-width: @screen-sm) {
            padding-left: 0;
            padding-right: 50px;
        }
    }

    .longread__col--small {
        .make-xs-column(12);
        .make-sm-column(6);
        padding-left: 20px;
        padding-right: 20px;
    }

    .header__logo {
        margin: 0;
        margin-top: 70px;
        width: 220px;
        margin-left: auto;
        margin-right: auto;
        filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.8));
    }

    .header__title {
        .h1;
        text-shadow: 0px 0px 10px rgba(50, 50, 50, 0.5);
        line-height: 1.3;
        padding: 0;

        margin-top: 40px;
        margin-bottom: 40px;
        @media (min-width: @screen-sm) {
            text-align: left;
            font-size: 44px;
            margin-top: 110px;
            margin-bottom: 110px;
        }
        span {
            display: block;
            .sans-serif-book;
        }
    }

}

.longread__header--index {
    .header__title {
        @media (min-width: @screen-sm) {
            margin-top: 60px;
            margin-bottom: 60px;
        }
    }
}

.longread__header--ssea {
    background-color: white;
}

.longread__header--paragraph {
    padding-top: 30px;
    padding-bottom: 60px;
    overflow: hidden;
    .row {
        position: relative;
    }
    .header__paragraph {
        .make-xs-column(12);
        .make-sm-column(8);
        .make-sm-column-push(2);
        .serif;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;
        @media (min-width: @screen-sm) {
            font-size: 20px;
        }
        line-height: 1.4;
        color: yellow;
        text-align: center;
        p {
            margin: 0;
        }
    }
    .header__paragraph--ssea {
        color: @color-purple;
        color: #7c7091;
    }
    .header__paragraph--global {
        color: @color-purple;
        color: darken(@color-global, 15%);
    }
    .header__paragraph--esa {
        color: @color-esadark;
    }
    .header__paragraph--wca {
        color: @color-wca;
    }

    .header__lang {
        .make-xs-column(12);
        color: white;
        .sans-serif;
        font-size: 12px;
        line-height: 1.4;
        text-align: center;
        margin-top: 30px;
        a {
            color: white;
            &:hover,&:active,&:focus {
                text-decoration: none;
                color: white;
            }
            .svg {
                width: 40px;
                height: auto;
                margin-bottom: 8px;
            }
            span {
                display: block;
            }
        }
        @media (min-width: @screen-sm) {
            position: absolute;
            width: 110px;
            float: none;
            right: 0;
            text-align: left;
            margin-top: 0;
        }
    }
    .header__lang--black {
        width: 130px;
        color: black;
        a {
            color: black;
            &:hover,&:active,&:focus {
                color:black;
            }
        }
    }
}

.header__video {
    padding: 0;
    margin: 0;
    background: #3c3c3c;
    .embed-container {
		position: relative;
		padding-bottom: 56.25%;
		overflow: hidden;
		max-width: 100%;
		height: auto;
	}
	.embed-container iframe,
	.embed-container object,
	.embed-container embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.longread__header--footer {
    height: 40px;
    .home & {
        position: relative;
        height: 50px;
        width: 100%;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 25px;
            background: linear-gradient(to bottom, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 100%);
        }
    }
}

.longread__header--footer--small {
    height: 10px;
}


.black-arrow-before {
    &:before {
        content: '';
        width: 20px;
        position: absolute;
        left: 0;
        top: 6px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 11px 0 11px 22px;
        border-color: transparent transparent transparent #000;
    }
}

.white-arrow-before {
    &:before {
        content: '';
        width: 20px;
        position: absolute;
        left: 0;
        top: 6px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 11px 0 11px 22px;
        border-color: transparent transparent transparent #ffffff;

        @media (max-width: @screen-xs-max) {
            width: 16px;
            border-width: 6px 0 6px 11px;
        }
    }
}

.longread__introduction {

    .longread__col--wide {
        padding: 40px;
        padding-left: 20px;
        padding-right: 20px;
        @media (min-width: @screen-sm) {
            //padding-left: 40px;
            padding-right: 40px;
        }
        @media (min-width: @screen-md) {
            padding-right: 80px;
        }
    }

    .introduction__title {
        .sans-serif-heavy;
        font-size: 40px;
        font-weight: 800;
        margin: 0;
        padding: 0;
        margin-bottom: 25px;
    }

    .introduction__text {
        .serif;
        font-size: 15px;
        p {
            margin-bottom: 20px;
        }
        a {
            color: inherit;
            text-decoration: underline;
            &:hover,&:active,&:focus {
                color: inherit;
                text-decoration: none;
            }
        }
    }

    .jumpto {
        .jumpto__title {
            .sans-serif-heavy;
            font-size: 20px;
            color: #00b4eb;
            border-bottom: 2px solid #00b4eb;
            text-align: left;
            line-height: 50px;
            margin-top: 40px;
        }

        .jumpto__list {
            list-style: none;
            margin: 0;
            padding: 0;
            padding-top: 10px;
            li {
                position: relative;
                .white-arrow-before;
                a {
                    display: block;
                    padding-left: 40px;
                    .sans-serif-heavy;
                    font-size: 20px;
                    line-height: 25px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    color: #3c3c3c;
                    &:hover,&:active,&:focus {
                        color: white;
                        text-decoration: none;
                    }
                }
            }

        }
    }

}


.longread__introduction--index {
    .longread__row--flexbox {
        display: flex;
        flex-direction: column;
        @media (min-width: @screen-sm) {
            flex-direction: row;
        }
    }
    .learnmore__column {
        padding-top: 20px !important;
    }
    .jumpto {
        .jumpto__title {
            margin-top: 0;
            color: @color-white;
            border-color: @color-white;
        }
        .jumpto__list {
            li {
                .black-arrow-before;
                &:before {
                    opacity: 0.3;
                }
                a {
                    color: @color-white;
                    .font-smoothing;
                    &:hover,&:focus,&:active {
                       color: @color-text;
                    }
                }
            }
        }
    }
}

.introduction_submenu {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 60px;
    margin-bottom: 60px;
    li {
        width: 22px;
        height: auto;
        position: relative;
        svg {
            opacity: 0.3;
            position: absolute;
            left: 0;
            top: 8px;
            path {
                fill: black;
            }
        }
        a {
            color: @color-text;
            .sans-serif-heavy;
            font-size: 16px;
            padding-left: 40px;
            padding-top: 5px;
            padding-bottom: 5px;
            display: block;
            &:hover,&:focus,&:active {
                text-decoration: none;
                color: @color-white;
                .font-smoothing;
            }
        }
    }
}

.report__download {
    overflow: hidden;
    padding-top: 35px;
    display: flex;
    .image {
        .make-xs-column(6);
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        padding-bottom: 40px;
        min-width: 140px;
        a {
            display: table-cell;
            vertical-align: bottom;
        }
        img, svg {
            width: 100%;
            height: auto;
            .image-shadow;
        }
    }
    .text-row {
        .make-xs-column(6);
        .sans-serif;
        color: @color-white;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
        padding-bottom: 40px;
        padding-right: 20px;
        padding-left: 0;
        span {
            font-size: 13px;
            padding-bottom: 10px;
        }
        a {
            color:yellow;
            position: relative;
            .sans-serif;
            .download-arrow-yellow;
            &:hover, &:focus, &:active {
                color: white;
                text-decoration: none;
                filter: drop-shadow(@shadow);
                .download-arrow;
            }
            padding-left: 20px !important;
        }
    }
}

.learnmore {

    .learnmore__column {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 35px;
    }

    .learnmore__title {
        .sans-serif-heavy;
        font-size: 40px;
        font-weight: 800;
        margin: 0;
        padding: 0;
        margin-bottom: 25px;
        color: white;
    }

    .learnmore__list {
        list-style: none;
        padding: 0;
        margin: 0;
        padding-bottom: 40px;
        @media (min-width: @screen-sm) {
            padding-bottom: 200px;
        }
        li {
            position: relative;
            min-height: 90px;
            padding-top: 20px;
            padding-bottom: 20px;
            border-bottom: 2px solid lightgrey;
            &:last-child {
                border: none;
            }
            img, svg {
                width: 50px;
                height: auto;
                position: absolute;
            }
            a {
                span {
                    display: inline-block;
                    .sans-serif-heavy;
                    color: yellow;
                    font-size: 16px;
                    line-height: 1.3;
                    padding-left: 70px;
                    padding-right: 10px;
                }
                &:hover,&:active,&:focus {
                    text-decoration: none;
                    span {
                        color: white;
                    }
                    svg {
                        path, rect, polygon {
                            fill: white;
                        }
                    }
                }
            }
        }
    }
}

.longread__textonimage {
    background: #3c3c3c;
    .container-longread {
        position: relative;
        padding: 0;
        @media (min-width: @screen-lg) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    img, svg {
        width: 100%;
        height: auto;
    }

    .textonimage__wrapper {
        @media (max-width: @screen-xs-max) {
            position: relative;
            padding-left: 20px;
            padding-right: 20px;
            background: #3c3c3c;
        }
        @media (min-width: @screen-sm) {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
        }
    }
    @media (min-width: @screen-sm) {
        .textonimage__wrapper--topleft {
            .textonimage__text {
                left: 80px;
                align-self: flex-start;
            }
        }
        .textonimage__wrapper--topright {
            justify-content: flex-end;
            .textonimage__text {
                left: auto;
                right: 80px;
                align-self: flex-start;
            }
        }
        .textonimage__wrapper--bottomleft {

            .textonimage__text {
                left: 80px;
                align-self: flex-end;
            }
        }
        .textonimage__wrapper--bottomright {
            justify-content: flex-end;
            .textonimage__text {
                left: auto;
                align-self: flex-end;
                right: 80px;
            }
        }
    }
    .textonimage__text {
        color: white;
        .sans-serif-medium;
        font-size: 14px;
        line-height: 1.4;
        .make-xs-column(12);
        .make-sm-column(4);
        .make-sm-column-push(6);
        @media (min-width: @screen-sm) {
            text-shadow: 0px 0px 5px rgba(50, 50, 50, 0.5);
        }
        margin-top: 20px;
        margin-bottom: 20px;

        padding-left: 0;
        padding-right: 0;
        @media (min-width: @screen-sm) {
            margin-top: 80px;
            margin-bottom: 80px;

        }
    }
    .textonimage__text--left {
        .textonimage__text {
            left: 80px;
        }
    }
}

.index-template-index-longread {
    @media (min-width: @screen-sm) {
        .textonimage__text {
            padding-left: 15px;
            .make-sm-column-push(0);
        }
        .longread__textonimage--ssea {
            .textonimage__text {
                border-left: 2px solid @color-yellow;
            }
        }
        .longread__textonimage--global {
            .textonimage__text {
                border-left: 2px solid @color-global;
            }
        }
        .longread__textonimage--wca {
            .textonimage__text {
                border-left: 2px solid @color-wca;
            }
        }
        .longread__textonimage--esa {
            .textonimage__text {
                border-left: 2px solid @color-esa;
            }
        }

    }
}



.longread-section {
    .longread-section__title {
        .font-smoothing();
        .sans-serif-heavy;
        color: white;
        font-size: 35px;
        @media (min-width: @screen-sm) {
            font-size: 50px;
        }
        line-height: 1.1;
        text-align: center;
        margin: 0;
        margin: 30px 0;
        padding: 0;
        span {
            .sans-serif-book;
        }
        .to-uppercase {
            .sans-serif-heavy;
            text-transform: uppercase;
        }
    }
    .longread-section__title--small {
        font-size: 18px;
    }
    .longread-section__lead {
        margin: 0;
        margin: 30px 0;
        p {
            margin-bottom: 30px;
            .serif;
            color: #3c3c3c;
            font-size: 15px;
            line-height: 1.3;
            @media (min-width: @screen-sm) {
                font-size: 20px;
                line-height: 1.4;
            }
            text-align: center;
        }
        a {
            color: inherit;
            text-decoration: underline;
            &:hover,&:focus, &:active {
                text-decoration: none;
            }
        }
        strong, b {
            .sans-serif-heavy;
            font-size: 20px;
            line-height: 1.2;
            @media (min-width: @screen-sm) {
                font-size: 25px;
                line-height: 1.3;
            }
        }

        .longread__section__footnote {
            p {
                .sans-serif;
                font-size: 13px;
                line-height: 16px;
                a {
                    color: inherit;
                    text-decoration: underline;
                    &:hover,&:active,&:focus {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .longread-section__lead--white {
        p, strong, b {
            color: white;
        }
    }
}



// Scope
.scope-segment__lined {
    @media (min-width: @screen-sm) {
        position: relative;
    }
    &:after {
        content: '';
        height: 2px;
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 0px;
        display: block;
        background: #0085aa;
    }

    .longread__col--small {
        @media (min-width: @screen-sm) {
            position: static;
        }
    }
}

.longread__button {
    background: #00b4eb;
    transition: background 0.5 linear 0s;
    display: inline-block;
    .sans-serif-heavy;
    color: white;
    padding-left: 40px;
    position: relative;
    .white-arrow-before;
    font-size: 18px;
    line-height: 30px;
    @media (max-width: @screen-xs-max) {
        padding-left: 35px;
        font-size: 12px;
        line-height: 24px;
    }
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 20px;
    &:before {
        left: 10px;
        top: 10px;
    }
    &:hover,&:active,&:focus {
        background: #0085aa;
        text-decoration: none;
        color: white;
        transition: background 0.5 linear 0s;
    }


}

.segment__button {
    .longread__button;
    background: #00b4eb;
    transition: background .5s linear 0s;
    white-space: nowrap;
    &:hover,&:active,&:focus {
        background: #0085aa;
        transition: background .5s linear 0s;
    }
}

.segment__button--ssea {
     background: @color-ssea;
     &:hover, &:active, &:focus {
         background: darken(@color-ssea, 10%);
     }
}

.segment__button--esa {
     background: @color-esa;
     &:hover, &:active, &:focus {
         background: darken(@color-esa, 10%);
     }
}

.segment__button--wca {
     background: @color-wca;
     &:hover, &:active, &:focus {
         background: darken(@color-wca, 10%);
     }
}

.segment__button--right {
    @media (min-width: @screen-sm) {
        float: right;
    }
}

.segment__button--bottom {
    @media (min-width: @screen-sm) {
        position: absolute;
        bottom: 40px;
        left: 20px;
    }
}

.scope-segment__content {
    @media (min-width: @screen-sm) {
        padding-right: 20px;
    }
    p {
        font-size: 15px;
        margin-bottom: 20px;
        @media (min-width: @screen-sm) {
            margin-bottom: 60px;
        }
    }
    padding-bottom: 40px;
    .longread__col--wide & {
        @media (min-width: @screen-sm) {
            padding-right: 60px;
        }
        padding-bottom: 0 !important;
    }
}

.scope-segment {
    position: relative;
    overflow: hidden;
    padding-top: 40px;
    .segment__title {
        margin: 0;
        padding: 0;
        color: #0085aa;
        .sans-serif-heavy;
        font-size: 35px;
        @media (min-width: @screen-md) {
            font-size: 50px;
        }
        line-height: 1.1;
        text-align: left;
        margin-bottom: 30px;
    }


}
.longread-section--scope {
    img, svg {
        width: 100%;
        height: auto;
        margin-bottom: 40px;
    }
}



.indicators__row {
    padding-top: 40px;
    padding-bottom: 40px;
    @media (min-width: @screen-sm) {
        display: flex;
    }
}

.scorecard-column {
    margin-top: 40px;
    @media (min-width: @screen-sm) {
        margin-top: 0;
        border-left: 2px solid #f27186;
        position: relative;
    }

    img {
        width: 100%;
    }

    @media (max-width: @screen-xs-max) {
        padding-bottom: 50px;
        &:after {
            content: '';
            display: block;
            left: 20px;
            right: 20px;
            height: 2px;
            position: absolute;
            bottom: 0;
            background: #f27186;
        }
    }
}

.scorecard-column__title {
    margin: 0;
    padding: 0;
    .sans-serif-heavy;
    font-size: 26px;
    line-height: 1.2;
    color: #f27186;
    text-align: left;

    margin-bottom: 30px;
    @media (min-width: @screen-sm) {
        margin-bottom: 20px;
    }
}

.scorecard-column__button {
    .longread__button;
    background: #f27186;
    transition: background .5s linear 0s;
    margin-top: 20px;
    white-space: nowrap;
    &:hover,&:active,&:focus {
        color: white;
        background: #b35461;
        transition: background .5s linear 0s;
    }
    @media (min-width: @screen-sm) {
        position: absolute;
        bottom: 0;
        left: 20px;
    }
}

.indicator-column__title {
    margin: 0;
    padding: 0;
    .sans-serif-heavy;
    font-size: 18px;
    line-height: 1.2;
    text-align: left;
}

.indicator-column__text {
    .serif;
    font-size: 15px;
    line-height: 1.4;
    margin-bottom: 30px;
    @media (min-width: @screen-sm) {
        margin-bottom: 60px;
    }
}




.measurement-area {
    .measurement-area__content {
        max-height: 0;
        overflow: hidden;
    }
}

.measurement-area__header {
    .longread__row {
        @media (min-width: @screen-sm) {
            display: flex;
        }
    }
    .longread__col--wide {
        @media (min-width: @screen-sm) {
            position: relative;
            min-height: 280px;
            .measurement-area__button {
                position: absolute;
                bottom: 0;
                left: 20px;
            }
        }
    }
    .longread__col--small {
        @media (max-width: @screen-sm) {
            display: none;
        }
        @media (min-width: @screen-sm) {
            padding: 0;
            img, svg {
                width: 100%;
                height: 100%;
                object-fit: cover;
                font-family: 'object-fit: cover;';
                display: block;
                position: absolute;
                min-height: 280px;
            }
        }
    }
}

.measurement-area__image--mobile {
    width: 100%;
    height: auto;
    margin-top: 40px;
     @media (min-width: @screen-sm) {
        display: none;
    }
}

.measurement-area__title {
    margin: 0;
    padding: 0;
    .sans-serif-heavy;
    text-align: left;
    font-size: 40px;
    @media (max-width: @screen-md) {
        font-size: 30px;
    }
    line-height: 1;
    color: #3c3c3c;
    margin-bottom: 30px;
    margin-top: 30px;
}

.measurement-area__lead {
    .sans-serif-heavy;
    .font-smoothing;
    font-size: 15px;
    line-height: 1.2;
    margin-bottom: 30px;
    @media (min-width: @screen-md) {
        font-size: 20px;
        margin-bottom: 80px;
    }
    @media (max-width: @screen-md) {
        br {
            display: none !important;
        }
    }

}

.measurement-area__button {
    .longread__button;
    .font-smoothing;
    background: none;
    transition: opacity .5s linear 0s;
    white-space: nowrap;
    &:hover,&:active {
        color: white;
        opacity: 0.5;
        background: none;
        transition: opacity .5s linear 0s;
    }
    &:focus {
        background: none;
    }
    margin-bottom: 20px;
}

.measurement-area--collapsed {
    .measurement-area__header {
        .measurement-area__title,
        .measurement-area__lead {
            color: white !important;
        }
    }
    .measurement-area__content {
        max-height: none;
        overflow: hidden;
    }
    .measurement-area__button {
        &:before {
            transform: rotate(90deg);
            @media (max-width: @screen-xs-max) {
                top: 12px;
                left: 8px;
            }
        }
    }
}


.measurement-area__content {

    .longread__row {
        @media (min-width: @screen-sm) {
            display: flex;
        }
    }
    .longread__col--small {
        padding-top: 40px;
        @media (min-width: @screen-sm) {
            padding-top: 0;
            position: relative;
            .measurement-area__column__text {
                margin-bottom: 80px;
            }
            .measurement-area__column__button  {
                position: absolute;
                bottom: 0;
                right: 20px;
                margin-bottom: 0;
            }
        }
    }



    .container-longread {
        padding-top: 40px;
        padding-bottom: 40px;
        .caption {
            .sans-serif;
            font-size: 13px;
            line-height: 1.4;
            margin-top: 20px;
            @media (min-width: @screen-sm) {
                margin-top: 40px;
            }
            a {
                color: inherit;
                text-decoration: none;
                &:hover,&:active,&:focus,&:visited {
                    color: inherit;
                    text-decoration: none;
                }
            }
        }
    }


    .measurement-area__column {
        @media (min-width: @screen-sm) {
            border-left: 2px solid #4688a8;
        }
    }

    .column--purple {
        border-color: @color-purple;
    }
    .column--rubine {
        border-color: @color-rubine;
    }
    .column--yellow {
        border-color: @color-mustard;
    }
    .column--pink {
        border-color: @color-pink;
    }
    .column--green {
        border-color: @color-green;
    }
    .column--orange {
        border-color: @color-orange;
    }
    .column--brown {
        border-color: @color-brown;
    }

    .measurement-area__column__title {
        margin: 0;
        padding: 0;
        .sans-serif-heavy;
        font-size: 26px;
        line-height: 1.2;
        color: #4688a8;
        text-align: left;
        margin-bottom: 20px;
    }

    .title--purple, .title--a {
        color: @color-purple;
    }
    .title--rubine, .title--b {
        color: @color-rubine;
    }
    .title--yellow, .title--c {
        color: @color-mustard;
    }
    .title--pink, .title--d {
        color: @color-pink;
    }
    .title--green, .title--e {
        color: @color-green;
    }
    .title--orange, .title--f {
        color: @color-orange;
    }
    .title--brown, .title--g {
        color: @color-brown;
    }

    .measurement-area__column__text {
        .serif;
        font-size: 15px;
        line-height: 1.4;
        p {
            margin-bottom: 20px;
        }
    }

    .measurement-area__column__button {
        .longread__button;
        white-space: nowrap;
        margin-bottom: 20px;
        transition: background .5s linear 0s;
        &:hover,&:focus,&:active {
            transition: background .5s linear 0s;
        }
    }

    .button--purple, .button--a {
        background: @color-purple;
        &:hover,&:active,&:focus {
            background: darken(@color-purple, 10%);
        }
    }
    .button--rubine, .button--b {
        background: @color-rubine;
        &:hover,&:active,&:focus {
            background: darken(@color-rubine, 10%);
        }
    }
    .button--yellow, .button--c {
        background: @color-mustard;
        &:hover,&:active,&:focus {
            background: darken(@color-mustard, 10%);
        }
    }
    .button--pink, .button--d {
        background: @color-pink;
        &:hover,&:active,&:focus {
            background: darken(@color-pink, 10%);
        }
    }
    .button--green, .button--e {
        background: @color-green;
        &:hover,&:active,&:focus {
            background: darken(@color-green, 10%);
        }
    }
    .button--orange, .button--f {
        background: @color-orange;
        &:hover,&:active,&:focus {
            background: darken(@color-orange, 10%);
        }
    }
    .button--brown, .button--g {
        background: @color-brown;
        &:hover,&:active,&:focus {
            background: darken(@color-brown, 10%);
        }
    }

}


.expectations__row {
    padding-bottom: 40px;
    @media (min-width: @screen-sm) {
        padding-top: 40px;
        display: flex;
    }

    .longread__col--smaller {
        color: @color-white;
        .serif;
        font-size: 15px;
        position: relative;
        p {
            margin-bottom: 30px;
            text-align: center;
            @media (min-width: @screen-sm) {
                text-align: left;
            }
        }
    }

    .longread__col--wider {
        img {
            width: 100%;
        }
    }

    .expectations_button {
        background: yellow;
        transition: background 0.5s linear 0s;
        display: inline-block;
        .sans-serif-heavy;
        color: @color-text;
        padding-left: 40px;
        position: relative;
        .white-arrow-before;
        font-size: 18px;
        line-height: 18px;
        padding-top: 11px;
        padding-bottom: 11px;
        padding-right: 20px;
        &:before {
            left: 10px;
            top: 10px;
            border-color: transparent transparent transparent @color-text;
        }
        &:hover,&:active,&:focus {
            background: #EDE417;
            text-decoration: none;
            color: @color-text;
            transition: background .5s linear 0s;
        }
        margin-bottom: 40px;
        @media (min-width: @screen-sm) {
            position: absolute;
            bottom: 0;
            left: 20px;
        }

    }

    .caption {
        .sans-serif;
        color: @color-white;
        font-size: 13px;
        line-height: 1.4;
        margin-top: 20px;
        @media (min-width: @screen-sm) {
            margin-top: 40px;
            padding-left: 80px;
        }
        margin-bottom: 40px;
        a {
            color: inherit;
            text-decoration: none;
            &:hover,&:active,&:focus,&:visited {
                color: inherit;
                text-decoration: none;
            }
        }
    }
}

.questionnaire__header-image {
    padding: 0;
    img, svg {
        width: 100%;
    }
}

.questionnaire__sections {
    padding-top: 40px;
    padding-bottom: 30px;
}

.questionnaire__section {
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    overflow: hidden;

    .img-responsive {
        margin-top: 30px;
    }

    @media (min-width: @screen-sm) {
        .img-responsive {
            margin-top: 0px;
        }
        .longread__col--half {
            &:nth-child(1) {
                padding-right: 40px;
            }
            &:nth-child(2) {
                padding-left: 40px;
            }
        }
    }

    &:after {
        content: '';
        height: 2px;
        display: block;
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 0;
        background: white;
    }
    &:last-child {
        &:after {
            display: none;
        }
    }

    .questionnaire__section__title {
        margin: 0;
        .sans-serif-heavy;
        font-size: 30px;
        line-height: 1.2;
        margin-bottom: 20px;
    }

    .questionnaire__section__text {
        .serif;
        font-size: 15px;
        line-height: 1.3;
        margin-bottom: 20px;
    }

    .questionnaire__section__link {
        color:white;
        position: relative;
        .sans-serif;
        font-size: 16px;
        .download-arrow;
        filter: drop-shadow(@shadow);
        padding-left: 30px !important;
        &:after {
            top: 17px;
        }
        &:hover, &:focus, &:active {
            color: @color-text;
            text-decoration: none;
            .download-arrow-grey;
            &:after {
                top: 17px;
            }
        }
    }
}


.svg-scroll-wrapper,
.svg-scroll-wrapper--ma {
    position: relative;
    display: block;
    transform: translateY(40px);
    transition: transform .5s ease-out 0s;
    #Step_1 {
        opacity: 1;
    }
    #Step_2 {
        opacity: 0;
        transition: opacity .25s ease-out 0s;
    }
    svg {
        position: relative;
        display: block;
    }
}

.svg-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}


.svg--visible {
    transform: translateY(0px);
    transition: transform .5s ease-out 0s;
    #Step_1 {
        opacity: 1;
    }
    #Step_2 {
        opacity: 1;
        transition: opacity .5s ease-out .5s;
    }
}

.backbutton__wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 30px;
    .segment__button {
        &:before {
            transform: rotate(180deg);
        }
    }
}

.learn-more__row {
    .backbutton__wrapper {
        .segment__button {
            background: yellow;
            transition: background 0.5s linear 0s;
            display: inline-block;
            .sans-serif-heavy;
            color: @color-text;
            padding-left: 40px;
            position: relative;
            .white-arrow-before;
            font-size: 18px;
            line-height: 18px;
            padding-top: 11px;
            padding-bottom: 11px;
            padding-right: 20px;
            &:before {
                left: 10px;
                top: 10px;
                border-color: transparent transparent transparent @color-text;
            }
            &:hover,&:active,&:focus {
                background: #EDE417;
                text-decoration: none;
                color: @color-text;
                transition: background .5s linear 0s;
            }
        }
    }
}