// Reports


.reports-title {
    padding-top: 30px;
    padding-bottom: 30px;
    @media (min-width: @screen-sm-min) {
        font-size: 50px;
        line-height: 22px;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    text-align: center;
    margin: 0;
}


.report-article {
	.make-xs-column(12);
	.make-sm-column(6);      
   
    img {
        .image-shadow;
        margin-bottom: 20px;
        @media (max-width: @screen-xs-max) {
            max-width: 400px;
            height: auto;
        }
    }
    
    .article-title {
        .sans-serif-heavy;
        color: @color-white;
        font-size: 13px;
        line-height: 16px;
    }
     p {
        .sans-serif-book;
        font-size: 13px;
        line-height: 16px;
    }
    
    margin-bottom: 40px;
    @media (min-width: @screen-sm-min) {
        &:nth-child(3n+1) {
            padding-right: 40px;
        }
        &:nth-child(3n+2) {
            padding-left: 40px;
        }
    }
}


.report_line {
    .make-xs-column(12);
    width: 100%;
    display: block;
    position: relative;
    height: 2px;
    background: #aaa;
    margin-bottom: 40px;
    @media (max-width: @screen-xs-max) {
        display: none;
    }
    
}