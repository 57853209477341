.resources {
	max-width: 586px;
	width: 100%;
	margin: 40px auto;
	p {
		.paragraph;
	}
}

.heading-resources {
	font-size: 18px;
	line-height: 1.22;
	color: #fff;
	text-align: center;
	margin: 0;
	border-bottom: 2px solid rgb(160, 160, 160);
	padding-bottom: 20px;
}

@media (max-width: 586px) {
	.resources {
		padding-left: 15px;
		padding-right: 15px;
	}
}