.excerpt-media {
	max-width: 182px;
	width: 100%;
	padding: 29px 0;

	.thumb {
		width: 100%;
		margin-bottom: 5px;
	}

	.media-heading {
		font-size: 13px;
		line-height: 1.3;
		margin-bottom: 5px;
	}

	.published {
		
		color: rgb(160, 160, 160);
	}

	.published, .more {
		display: block;
		margin: 0;
		line-height: 1.2;
	}
}