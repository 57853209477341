.image-keyfindings {
    padding: 0;
    @media (min-width: @screen-sm-min) {
        position: fixed;
        z-index: -10;
        width: 100%;
        top: 44px;
    }
    .swiper-slide {
        min-height: 150px;
        background-size: cover;
        background-position: center top;
    }
    h1 {
        @media (min-width: @screen-sm-min) {
            font-size: 50px;
            line-height: 22px;
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }
}

.key-nav {
    text-align: center;
    ul {
        display: inline-block;
        padding-top: 30px;
        padding-bottom: 26px;
        li {
            float: left;
            display: table;
            vertical-align: middle;
            width: 100%;
            
            
            padding: 6px 20px;
            font-size: 15px;
            line-height: 18px;
            
            @media (min-width: @screen-sm-min) {
                width: auto;
            }
           
            @media (min-width: @screen-md-min) {
                font-size: 18px;
                line-height: 22px;
                width: auto;
            }
            a {
                display: table-cell;
                vertical-align: middle;
                transition-property: color;
                transition-duration: .4s;
            }
            &:after {
                right: -1px !important;
            }
        }
    }
    ul.initial {
        li {
            a {
                color: @color-white;
                transition-property: color;
                transition-duration: .4s;
            }
            a.inactive {
                color: #a0a0a0;
                transition-property: color;
                transition-duration: .4s;
            }
        }
    }
}

.finding-column {
    text-align: center;
    .make-md-column(10);
    .make-md-column-offset(1);
    padding-top: 100px;
    padding-bottom: 100px;
    h2 {
        .h1;
        padding: 0;
        @media (min-width: @screen-sm-min) {
            padding-left: 10%;
            padding-right: 10%;
        }
    }
    p {
        .paragraph;
        color: @bruin-donkerst;
    }
}