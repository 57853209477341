// Colors



@color-white: rgba(255,255,255,1);
@color-innovation: rgba(220, 221, 218, 1);

@color-purple: rgba(157,133,190,1);
@color-rubine: rgba(242,133,134,1);
@color-mustard: rgba(189,166,50,1);
@color-pink: rgba(246,163,190,1);
@color-green: rgba(0,175,116,1);
@color-yellow: rgba(255,194,14,1);
@color-brown: rgba(180,109,84,1);

@color-a: @color-purple;
@color-a-c: rgba(101,84,118,1);
@color-a-p: rgba(119,101,139,1);
@color-a-t: @color-a;
@color-a-i: @color-innovation;

@color-b: @color-rubine;
@color-b-c: rgba(152,69,81,1);
@color-b-p: rgba(180,84,97,1);
@color-b-t: @color-b;
@color-b-i: @color-innovation;

@color-c: @color-mustard;
@color-c-c: rgba(126,108,21,1);
@color-c-p: rgba(140,125,33,1);
@color-c-t: @color-c;
@color-c-i: @color-innovation;

@color-d: @color-pink;
@color-d-c: rgba(155,102,119,1);
@color-d-p: rgba(182,121,139,1);
@color-d-t: @color-d;
@color-d-i: @color-innovation;

@color-e: @color-green;
@color-e-c: rgba(0,114,73,1);
@color-e-p: rgba(0,133,87,1);
@color-e-t: @color-e;
@color-e-i: @color-innovation;

@color-f: @color-yellow;
@color-f-c: rgba(158,122,0,1);
@color-f-p: rgba(188,145,9,1);
@color-f-t: @color-f;
@color-f-i: @color-innovation;

@color-g: @color-brown;
@color-g-c: rgba(114,67,47,1);
@color-g-p: rgba(137,81,60,1);
@color-g-t: @color-g;
@color-g-i: @color-innovation;



@color-global: @color-blue;
@color-regional: rgba(255,255,0,1);
@color-ssea: @color-rubine;
@color-esa: #bf9b39;
@color-esa: rgba(255,195,15,1);
@color-esadark: rgba(195,155,0, 1);
@color-wca: #00af74;
@color-wcadark: #008557;
@color-la: #9c89ba;


.chart-figure {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {

        }
    }
}






.stacked-chart {
    li {
        margin-bottom: 5px;
    }
}

.chart {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        svg {
            cursor: pointer;
        }
        opacity: 1;
        transition-property: opacity;
        transition-duration: .5s;
        &:not(:last-child) {
            margin-bottom: 5px;
        }
        &:hover {
            .company-label {
                fill: @color-white;
            }

        }
    }
    .faded {
        opacity: 0.3;
        transition-property: opacity;
        transition-duration: 0s;

        &:hover, &:focus, &:active {
            opacity: 1 !important;
            transition-property: opacity;
            transition-duration: 0s;
            .bars {
                opacity: 0.3;
                transition-property: opacity;
                transition-duration: .0s;
            }
        }
    }
    .expanded {
        .company-label {
            fill: @color-white;
        }
        .sub-chart-content {
            opacity: 1;
            transition-property: opacity;
            transition-duration: .3s;
        }
    }
}

.company-measurement-areas {
    svg {
        cursor: default !important;
    }
    .company-label {
        fill: @bruin-donkerst !important;
    }
}


.sub-chart {
    display: block;
    width: 100%;
    max-height: 0;
    transition-property: max-height;
    transition-duration: .5s;
    overflow: hidden;

    span.sub-chart-content {
        opacity: 0;
        transition-property: opacity;
        transition-duration: .3s;
        position: relative;
        width: 100%;
        display: inline-block;
    }

    header.company-header {
        position: relative;
        display: block;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 35px;

        .company-ranking {
            margin-left: 20px;
            background-color: @color-blue;
            position: relative;
            width: 36px;
            height: 36px;
            padding: 4px;
            display: inline-block;
            span {
                background: @color-white !important;
                display: block;
                border-radius: 100%;
                width: 28px;
                height: 28px;
                text-align: center;
                position: relative;
                line-height: 28px;
                .sans-serif-heavy;
                font-weight: normal;
                letter-spacing: -1px;
                font-size: 18px;
                .font-smoothing;
            }
        }
        .company-ranking--global {
            background: @color-global;
        }
        .company-ranking--ssea {
            background: @color-ssea;
        }
        .company-ranking--esa {
            background: @color-esa;
        }
        .company-ranking--wca {
            background: @color-wca;
        }
        .company-ranking--home {
            background: #3c3c3c;
        }
        .company-ranking-regional {
            background: @color-regional;
        }
        h6 {
            .sans-serif-heavy;
            font-weight: normal;
            font-size: 18px;
            margin: 0;
            .font-smoothing;
            display: inline-block;
            margin-left: 10px;
            width: ~"calc(100% - 200px)";
        }
        a.collapse-button {
            position: absolute;
            right: 35px;
            top: 10px;
            &:hover, &:focus, &:active {
                rect {
                    fill: @color-white;
                }
            }
        }
        a.report-button {
            position: relative;
            svg {
                width: 20px;
                height: 20px;
                position: absolute;
                left: -20px;
                display: block;
                path {
                   fill: rgba(255,255,255,0.5);
                   transition-property: fill;
                   transition-duration: .3s;
                }
            }
            //width: 95px;
            float: right;
            height: 20px;
            font-size: 12px;
            line-height: 20px;
            margin-top: 8px;
            background: rgba(255,255,255,0.5);
            transition-property: background;
            transition-duration: .3s;
            display: block;
            .font-smoothing;
            .sans-serif-heavy;
            color: @color-white;
            padding-right: 10px;
            padding-left: 5px;
            text-decoration: none;
            &:hover, &:active, &:focus {
                background: @color-blue;
                transition-property: background, color;
                transition-duration: .3s;
                path {
                    fill: @color-blue;
                    transition-property: fill, color;
                    transition-duration: .3s;
                }
            }
        }
        a.report-button--global {
            &:hover, &:active, &:focus {
                background: @color-global;
                path {
                    fill: @color-global;
                }
            }
        }
        a.report-button--ssea {
            &:hover, &:active, &:focus {
                background: @color-ssea;
                path {
                    fill: @color-ssea;
                }
            }
        }
        a.report-button--esa {
            &:hover, &:active, &:focus {
                background: @color-esa;
                path {
                    fill: @color-esa;
                }
            }
        }
        a.report-button--wca {
            &:hover, &:active, &:focus {
                background: @color-wca;
                path {
                    fill: @color-wca;
                }
            }
        }
        a.report-button--home {
            color: white !important;
            background: fade(#3c3c3c, 80%) !important;
            transition-duration: 0s;
            svg, img {
                path {
                    fill: fade(#3c3c3c, 80%) !important;
                    transition-duration: 0s;
                }
            }
            &:hover, &:active, &:focus {
                text-decoration: none;
                color: white !important;
                background: #3c3c3c !important;
                transition-duration: 0s;
                svg, img {
                    path {
                        fill: #3c3c3c !important;
                        transition-duration: 0s;
                    }
                }
            }
        }
        a.report-button-regional {
            &:hover, &:active, &:focus {
                background: @color-regional;
                color: rgb(170,170,170,1);
                path {
                    fill: @color-regional;
                }
            }
        }
    }
}

.company-svg, .company-measurement-area-svg, .radial-svg {

    .company-label {
        line-height: 1;
        .font-smoothing;
    }
    .label-ranking {
        .sans-serif-heavy;
    }
    .label-name {
        .sans-serif-heavy;
    }
    .measurement-area-label {
        .sans-serif-book;
    }
    .label-country {
        .sans-serif-book;
    }
    .label-total {
        .sans-serif-heavy;
    }
    .label-ma_code {
        .sans-serif-heavy;
    }

    .donut-label {
        .sans-serif-heavy;
    }

    .donut-highest-score {
        .sans-serif-book;
    }

}


.company-measurement-areas {
    li {
        &:last-child {
            margin-bottom: 40px;
        }
    }
}


.label-axis {
    .sans-serif-book;
    line-height: 1;
    .font-smoothing;
}

.bar-area {
    transform: translate(0,0) scale(0,1);
}


// Total
.bar-total {
    fill: @color-white;
}

// A
.area-a {
    fill: @color-a;
    background: @color-a;
}
.area-a-cpti-c {
    fill: @color-a-c;
    background: @color-a-c;
}
.area-a-cpti-p {
    fill: @color-a-p;
    background: @color-a-p;
}
.area-a-cpti-t {
    fill: @color-a-t;
    background: @color-a-t;
}
.area-a-cpti-i {
    fill: @color-a-i;
    background: @color-a-i;
}
.area-a-max {
    fill: @color-a-p;
}

// B
.area-b {
    fill: @color-b;
    background: @color-b;
}
.area-b-cpti-c {
    fill: @color-b-c;
    background: @color-b-c;
}
.area-b-cpti-p {
    fill: @color-b-p;
    background: @color-b-p;
}
.area-b-cpti-t {
    fill: @color-b-t;
    background: @color-b-t;
}
.area-b-cpti-i {
    fill: @color-b-i;
    background: @color-b-i;
}
.area-b-max {
    fill: @color-b-p;
}

// C
.area-c {
    fill: @color-c;
    background: @color-c;
}
.area-c-cpti-c {
    fill: @color-c-c;
    background: @color-c-c;
}
.area-c-cpti-p {
    fill: @color-c-p;
    background: @color-c-p;
}
.area-c-cpti-t {
    fill: @color-c-t;
    background: @color-c-t;
}
.area-c-cpti-i {
    fill: @color-c-i;
    background: @color-c-i;
}
.area-c-max {
    fill: @color-c-p;
}

// D
.area-d {
    fill: @color-d;
    background: @color-d;
}
.area-d-cpti-c {
    fill: @color-d-c;
    background: @color-d-c;
}
.area-d-cpti-p {
    fill: @color-d-p;
    background: @color-d-p;
}
.area-d-cpti-t {
    fill: @color-d-t;
    background: @color-d-t;
}
.area-d-cpti-i {
    fill: @color-d-i;
    background: @color-d-i;
}
.area-d-max {
    fill: @color-d-p;
}

// E
.area-e {
    fill: @color-e;
    background: @color-e;
}
.area-e-cpti-c {
    fill: @color-e-c;
    background: @color-e-c;
}
.area-e-cpti-p {
    fill: @color-e-p;
    background: @color-e-p;
}
.area-e-cpti-t {
    fill: @color-e-t;
    background: @color-e-t;
}
.area-e-cpti-i {
    fill: @color-e-i;
    background: @color-e-i;
}
.area-e-max {
    fill: @color-e-p;
}

// F
.area-f {
    fill: @color-f;
    background: @color-f;
}
.area-f-cpti-c {
    fill: @color-f-c;
    background: @color-f-c;
}
.area-f-cpti-p {
    fill: @color-f-p;
    background: @color-f-p;
}
.area-f-cpti-t {
    fill: @color-f-t;
    background: @color-f-t;
}
.area-f-cpti-i {
    fill: @color-f-i;
    background: @color-f-i;
}
.area-f-max {
    fill: @color-f-p;
}

// G
.area-g {
    fill: @color-g;
    background: @color-g;
}
.area-g-cpti-c {
    fill: @color-g-c;
    background: @color-g-c;
}
.area-g-cpti-p {
    fill: @color-g-p;
    background: @color-g-p;
}
.area-g-cpti-t {
    fill: @color-g-t;
    background: @color-g-t;
}
.area-g-cpti-i {
    fill: @color-g-i;
    background: @color-g-i;
}
.area-g-max {
    fill: @color-g-p;
    background: @color-g-p;
}

//White
.area-white {
    fill: @color-white;
    background: @color-white;
}


// DONUTS
.graph--global {
    .donut-score {
        fill: @color-global;
    }
    .donut-highest {
        fill: rgba(0,138,173,1);
    }
}
.graph--ssea {
    .donut-score {
        fill: @color-ssea;
    }
    .donut-highest {
        fill: rgba(169,73,84,1);
    }
}
.graph--esa {
    .donut-score {
        fill: @color-esa;
    }
    .donut-highest {
        fill: @color-esadark;

    }
}
.graph--wca {
    .donut-score {
        fill: @color-wca;
    }
    .donut-highest {
        fill: @color-wcadark;
    }
}

.regional-graphs {
    .donut-score {
        fill: @color-regional;
    }
    .donut-highest {
        fill: rgba(192,180,0,1);
    }
}
.donut-figure {
    margin-bottom: 20px;
    @media (min-width: @screen-sm) {
        margin-bottom: 0px;
    }
    display: flex;
    flex-direction: column;
    .donuts {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        @media (max-width: 320px) {
            margin-left: -15px;
            margin-right: 5px;
        }
        @media (min-width: 360px) {
            margin-left: 0;
            margin-right: 20px;
        }
        @media (min-width: 420px) {
            justify-content: flex-end;
            padding-left: 0;
            padding-right: 20px;
            margin-right: 0;
            .radial-svg {
                margin-right: 15px;
            }
        }
        li {
            &:nth-child(4) {
                display: none;
            }
        }
    }

    .radial-svg {
        margin-bottom: 25px;
    }

    #svg-4 {
        margin-right: 0;
    }

}

.stacked-figure {
    @media (max-width: @scorecard-breakpoint) {
        max-width: 480px;
    }
}




