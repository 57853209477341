.header-frontpage {

	.longread__row--flexbox {
		flex-direction: column;
		@media (min-width: @screen-sm) {
			flex-direction: row;
		}
	}

	.container-longread {
		padding-top: 50px;
		padding-bottom: 50px;
	}

    .front-page__logo-column {
        text-align: center;
        display: flex;
        align-items: center;
        position: relative;

        @media (max-width: @screen-xs-max) {
            justify-content: center;
        }

        .make-sm-column(4);
        .make-md-column(6);

        @media(min-width: @screen-md-min) {
            width: 48%;
        }

        padding-left: 20px;
        padding-right: 20px;
        .logo {
            margin: 0;
            @media (min-width: @screen-sm) {
                position: absolute;
                object-fit: contain;
                font-family: 'object-fit: contain;';
                height: 100%;
                height: auto;
            }
        }
        @media (max-width: @screen-xs-max) {
            margin-bottom: 50px;
        }
    }

    .front-page__video-wba-column {
        .make-sm-column(8);
        .make-md-column(6);
        padding-left: 20px;
        padding-right: 20px;
        @media (min-width: @screen-md) {
            padding-left: 0;
            width: 52%;
        }
    }

}


.header-frontpage__video {

    @media (min-width: @screen-sm) {
        border-right: 1px solid white;
        margin-bottom: 0;
    }
}

.header-frontpage__wba {
    display: flex;
    flex-direction: column;
    .wba__image {
        @media (max-width: @screen-xs-max) {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid white;
        }
        padding-right: 20px;
        margin-bottom: 15px;
        img {
            width: auto;
            height: 50px;
        }
    }
    .wba__text {
        .sans-serif;
        // font-size: 11px;
        font-size: 13px;
        line-height: 1.4;
        margin: 0;
        @media (max-width: @screen-xs-max) {
            br {
                display: none;
            }
        }
    }
}

.embed-responsive {
	.js_play-button {
		width: 100%;
		height: auto;
		position: absolute;
		z-index: 999;
		cursor: pointer;
		transition: opacity .5s linear 0s;
	}

}
.playing {
	.js_play-button {
		opacity: 0;
		transition: opacity .5s linear 0s;
		pointer-events: none;
	}
}





.yellowsection__intro {
    font-size: 20px;
    @media (max-width: @screen-xs-max) {
        margin-bottom: 40px;
        font-size: 18px;
    }
    margin: 0;
    .font-smoothing;

}
.yellowsection-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: yellow;
    &:hover,&:active,&:focus {
        color: white;
        text-decoration: none;
    }
}
.yellowsection-button__text {
    .sans-serif-heavy;
    font-size: 20px;
    line-height: 1.1;
    margin-bottom: 0;
    margin-right: 20px;

}
.yellowsection-button__image {
    width: 100px;
    height: auto;
}


.homeheader {
    background: #3c3c3c;
    .longread__row {
        @media (max-width: @screen-xs-max) {
            flex-direction: column;
        }


        @media (min-width: @screen-sm) {
            display: flex;
        }
    }
    .homeheader__content {
        @media (min-width: @screen-sm) {
            position: relative;
            min-height: 280px;
            padding-right: 35px;
        }
    }
    .homeheader__image {
        @media (max-width: @screen-xs-max) {
            padding: 0;
        }
        img {
            width: 100%;
            height: auto;
        }
        @media (min-width: @screen-sm) {
            padding: 0;
            img, svg {
                width: 100%;
                height: 100%;
                object-fit: cover;
                font-family: 'object-fit: cover;';
                display: block;
                position: absolute;
                min-height: 280px;
            }
        }
    }
}


.homeheader__title {
    margin: 0;
    padding: 0;
    .sans-serif-heavy;
    text-align: left;
    font-size: 45px;
    @media (max-width: @screen-md) {
        font-size: 30px;
    }
    line-height: 1;
    color: white;
    margin-bottom: 30px;
    margin-top: 30px;
}

.homeheader__lead {
    .sans-serif;
    .font-smoothing;
    font-size: 15px;
    line-height: 1.4;
    margin-bottom: 30px;
    color: white;
    @media (min-width: @screen-md) {
        font-size: 20px;
        margin-bottom: 80px;
    }
    @media (max-width: @screen-md) {
        br {
            display: none !important;
        }
    }
}


.arrowbutton--homeheader {
    margin-top: 20px;
    color: white !important;
    background: fade(#3c3c3c, 80%) !important;
    svg, img {
        path {
            fill: fade(#3c3c3c, 80%) !important;
        }
    }
    &:hover,&:focus,&:active {
        text-decoration: none;
        color: white !important;
        background: #3c3c3c !important;
        svg, img {
            path {
                fill: #3c3c3c !important;
            }
        }
    }
    .homeheader & {
        color: #3c3c3c !important;
        background: fade(white, 80%) !important;
        svg, img {
            path {
                fill: fade(white, 80%) !important;
            }
        }
        @media (min-width: @screen-md) {
            display: none !important;
        }
        &:hover,&:focus,&:active {
            color: #3c3c3c !important;
            background: white !important;
            svg, img {
                path {
                    fill: white !important;
                }
            }
        }
    }
}

.homeheader__image {
    position: relative;
    .popper-button {
        position: absolute;
        bottom: 10px;
        left: 10px;
        z-index: 99;
        cursor: pointer;
        display: block;
        padding: 10px;
        width: 45px;
        height: 45px;
        svg {
            width: 25px;
            height: 25px;
            min-height: 25px;
            filter: drop-shadow( -1px -1px 4px #555 );
        }
        transition: opacity .5s linear 0s;
        &:hover, &:active,&:focus {
            opacity: 0.85;
            transition: opacity .5s linear 0s;
        }
    }
}

.tooltip {
    background: white;
    color: @color-text;
    padding: 10px;
    .sans-serif-heavy;
    .font-smoothing;
    font-size: 14px;
    line-height: 1.4;
    z-index: 9999;
    max-width: 300px;
}







.ranking-nav {
    &:after {
        content: '';
        @media (min-width: @screen-md) {
            content: 'flickity';
        }
        display: none;
    }
}


.ranking-nav__cell {
    cursor: pointer;

    &:before {
        content: '';
        height: 15px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.15) 100%);
        z-index: 50;
        transition: opacity .5s linear 0s;
        opacity: 0;
        .homeranking--open & {
            opacity: 1;
        }
    }
    &:after {
        content: '';
        width: 15px;
        height: 100%;
        position: absolute;
        bottom: 0;
        right: -15px;
        background: linear-gradient(to left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.15) 100%);
        z-index: 50;
        transition: opacity 1s linear 0s;
        opacity: 1;
    }
}


.ranking-nav__cell.is-selected {
    z-index: 999;
    .homeranking--open & {
        cursor: default;
    }
    &:before {
       opacity: 0;
       transition: opacity 0s linear 0s;
    }
    &:after {
        opacity: 1;

    }
}

.ranking-nav__cell {
    width: 25%;
    height: 100px;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ranking-nav__title {
    text-align: left;
    .sans-serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 1;
    color: #3c3c3c;

    align-self: center;
    justify-self: center;
}


.ranking-carousel {
    &:after {
        content: '';
        @media (min-width: @screen-md) {
            content: 'flickity';
        }
        display: none;
    }
    .flickity-viewport {
        min-height: 340px !important;
        //max-height: 340px !important;
    }

    .ranking-downarrow {
        .hidden-xs;
        .hidden-sm;
        svg {
            transform: rotate(90deg);
            width: 30px;
            height: 30px;
            margin: 0;
        }
        background: none !important;
        position: absolute;
        top: 260px;
        left: 40px;
        z-index: 9998;
        width: 30px;
        height: 30px;
        pointer-events: none;
    }


}

.homeranking--open {
    .ranking-carousel__cell {
        width: 100% !important;
    }
    .flickity-viewport {
        min-height: auto !important;
    }
    .ranking-downarrow {
        display: none;
    }
}



// .close-rankingcarousel {
//     position: absolute;
//     top: 20px;
//     right: 20px;
//     background: blue;
//     width: 40px;
//     height: 40px;
//     cursor: pointer;
// }




.ranking-carousel__cell--closed {
    @media (max-width: @screen-sm-max) {
        pointer-events: none;
        .ranking-cell__right {
            pointer-events: auto !important;
            padding-bottom: 0;
        }
    }

    @media (min-width: @screen-md) {
        cursor: pointer;
        .stacked-figure {
            transform: translate(-20px, 0);
        }
        .bars {
            transform: translate(150px, 0);
        }
        .label-total {
            transform: translate(-40px, 0);
        }
        &:before {
            content: '';
            width: 15px;
            height: 100%;
            position: absolute;
            bottom: 0;
            right: -15px;
            background: linear-gradient(to left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.15) 100%);
            z-index: 50;
            opacity: 1;
        }
    }

    .ranking-cell__inner {
        &:after {
            content: '';
            position: absolute;
            top: 10px;
            left: 0;
            width: 100%;
            height: 250px;
            @media (max-width: @screen-sm-max) {
                top: auto;
                bottom: 80px;
            }
        }
    }
}

.ranking-carousel__cell {
    width: 100%;
    @media (min-width: @screen-md) {
        width: 25%;
        .bars {
            transform: translate(190px, 0);
        }
        .label-total {
            transform: translate(0px, 0);
        }
    }
}

.slide--global {
    .stacked-figure {
        transition: transform .5s ease-in-out 0s;
    }
    .bars, .label-total {
        transition: transform .5s ease-in-out 0s;
    }
    &:before {
        transition: opacity .5s ease-in-out 0s;
    }
}

.ranking-cell__inner {
    display: flex;
    flex-direction: column;
    position: relative;
    @media (min-width: @screen-md) {
        min-width: 1024px;
        flex-direction: row;
    }
    .ranking-nav__title {
        padding: 40px;
        padding-bottom: 20px;
        font-size: 30px;
    }

    @media (max-width: @screen-sm-max) {
        &:after {
            content: '';
            position: absolute;
            bottom: 100px;
            left: 0;
            width: 100%;
            height: 250px;
            top: auto;
        }
    }
}

.ranking-cell__left {
    width: 100%;
    padding: 20px;
    overflow: hidden;
    max-height: auto;
    position: relative;
    @media (max-width: @screen-sm-max) {
        max-height: 240px;
        pointer-events: none;
    }
    @media (min-width: @screen-md) {
        width: 50%;
        padding: 40px 40px;
        padding-right: 0;
        padding-bottom: 50px;
    }
    .ranking-carousel__cell--closed & {
        @media (min-width: @screen-md) {
            max-height: 260px;
        }
    }


    .figure__footnote {
        .sans-serif;
        font-size: 11px;
        line-height: 16px;
        -webkit-font-smoothing: subpixel-antialiased;
        margin-top: 30px;
        padding-right: 35px;
        margin-bottom: 0;
        color: black;
        a {
            color: inherit;
            text-decoration: underline;
            &:hover,&:active,&:focus {
                text-decoration: none;
            }
        }
    }
}

.ranking-cell__right {
    width: 100%;
    padding: 20px;
    @media (min-width: @screen-md) {
        width: 50%;
        padding: 40px 40px;
        padding-bottom: 50px;
        padding-right: 80px;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.homeranking__lead {
    .serif;
    font-size: 17px;
    line-height: 1.4;
    margin-bottom: 30px;
    //color: white;
    @media (max-width: @screen-sm-max) {
        text-align: center;
    }
}

@color-ssea-home: #f27186;
@color-esa-home: #d3b020;
@color-wca-home: #0bb580;

.slide--global {
    background: @color-global;
    .ranking-cell__inner {
        &:after {
            background: linear-gradient(to bottom, fade(@color-global, 0) 0%, @color-global 100%);
        }
    }
}
.slide--ssea {
    background: @color-ssea-home;
    .ranking-cell__inner {
        &:after {
            background: linear-gradient(to bottom, fade(@color-ssea-home, 0) 0%, @color-ssea-home 100%);
        }
    }
}
.slide--esa {
    background: @color-esa-home;
    .ranking-cell__inner {
        &:after {
            background: linear-gradient(to bottom, fade(@color-esa-home, 0) 0%, @color-esa-home 100%);
        }
    }
}
.slide--wca {
    background: @color-wca-home;
    .ranking-cell__inner {
        &:after {
            background: linear-gradient(to bottom, fade(@color-wca-home, 0) 0%, @color-wca-home 100%);
        }
    }
}




// Companies

.homecompanies {
    background: #bcbcbc;
}

.homecompanies-indexlist {
    padding: 0;
    margin: 0;
    .make-xs-column(12);
    list-style: none;
    text-align: left;
    padding: 0;
    display: flex;
    justify-content: space-between;

    li {
        display: flex;
        align-items: center;
        @media (min-width: @scorecard-breakpoint) {
            justify-content: space-between;
            clear: none;
            margin-top: 30px;
            margin-bottom: 40px;
        }
        padding-bottom: 7px;
        padding-top: 6px;
        a {
            display: flex;
            align-items: center;
            color: rgba(255,255,255,0.5);
            &:hover, &:focus, &:active {
                color: rgba(255,255,255,1);
                text-decoration: none;
                .icon {
                    opacity: 1;
                    svg {
                        path, polygon, rect {
                            fill: @color-white;
                        }
                    }
                }
            }
        }
        .link--unreleased {
            pointer-events: none;
            .icon {
                opacity: 0.3;
            }
        }
        order: 1;
    }
    .company-divider {
        position: relative;
        width: 1px;
        background: #8b8b8b;
        height: 100%;
        min-height: 55px;
    }

    .filtered {
        a {
            color: @color-white;
            order: 0;
            .icon {
                opacity: 1;
                svg {
                    path, polygon, rect {
                        fill: @color-white;
                    }
                }
            }
            &:active {
                color: rgba(255, 255, 255, 0.5) !important;
            }
        }
    }
    .filtered.item--global {
        a {
            color: darken(@color-global, 10%) !important;
        }
    }
    .filtered.item--ssea {
        a {
            color: darken(@color-ssea-home, 10%) !important;
        }
    }
    .filtered.item--esa {
        a {
            color: darken(@color-esa-home, 10%) !important;
        }
    }
    .filtered.item--wca {
        a {
            color: darken(@color-wca-home, 10%) !important;
        }
    }
    .icon {
        width: 40px;
        height: 40px;
        float: left;
        margin: 0;
        margin-top: 0px;
        margin-right: 15px;
    }
    .sub-titles {
        float: left;
        .main-title {
            .sans-serif-small;
            .sans-serif-heavy;
            display: block;
            font-size: 12px;
        }
        .sub-title {
            .sans-serif-small;
            .sans-serif-book;
            display: block;
            font-size: 12px;
        }
    }
}

.homecompanies-scrollcontainer {
    height: 415px;
    width: 100%;

    margin-bottom: 30px;
    position: relative;
    &:before {
        content: '';
        width: 2px;
        background: #3c3c3c;
        height: 100%;
        position: absolute;
        top: 0;
        left: 40px;
        z-index: 90;
    }
    &:after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 150px;
        left: 0;
        bottom: 0;
        background: linear-gradient(to bottom, fade(#bcbcbc, 0%) 0%, fade(#bcbcbc, 100%) 100%);
        z-index: 99;
        pointer-events: none;
    }
}

.homecompanies-scrollcontainer__inner {
    overflow: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.homecompanies-companylist {
    padding: 0;
    margin: 0;
    list-style: none;
    .font-smoothing;
    position: relative;
    padding-bottom: 90px;
    li {
        display: block;
        width: 100%;
        .sans-serif-heavy;
        font-size: 22px;
        line-height: 1.4;
        padding-left: 70px;
        position: relative;
        a {
            color: #3c3c3c;
            &:hover,&:active,&:focus {
                color: white;
                text-decoration: none;
            }
        }
        &:before {
            pointer-events: none;
            content: attr(data-before);
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            .sans-serif-heavy;
            font-size: 22px;
            line-height: 1.4;
        }
    }
}





.homecompanies-companyinfo {
    position: relative;
    width: 100%;
    height: 380px;
    background: @color-global;
    padding: 20px;

    transition: background .5s ease 0s;
}

.companyinfo__name {
    .sans-serif-heavy;
    color: white;
    font-size: 30px;
    line-height: 1.1;
    text-align: left;
    margin-bottom: 20px;
}

.companyinfo__summary {
    .sans-serif;
    color: white;
    font-size: 18px;
    line-height: 1.4;
}

.companyinfo__link {

}

.companyinfo--global-seed-companies {
    background: @color-global;
}
.companyinfo--south-southeast-asia {
    background: @color-ssea-home;
}
.companyinfo--eastern-southern-africa {
    background: @color-esa-home;
}
.companyinfo--western-central-africa {
    background: @color-wca-home;
}






// Featured Posts
.magazinerow {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-bottom: 30px;
    @media (min-width: @screen-md) {
        padding-bottom: 50px;
    }

    @media (max-width: @screen-xs-max) {
        justify-content: center;
    }
}

.magazinerow--nopadding {
    padding: 0;
    border-bottom: 2px solid #d6d6d6;
}

.magazine-item {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    .font-smoothing;
    @media (max-width: @screen-xs-max) {
        width: 49.5% !important;
        &:nth-child(1) {
            border-bottom: 2px solid #d6d6d6;
        }
        &:nth-child(2) {
            border-bottom: 2px solid #d6d6d6;
        }
        &:nth-child(odd) {
            border-left: 0;
            border-right: 1px solid #d6d6d6;
        }
        &:nth-child(even) {
            border-left: 1px solid #d6d6d6;
            border-right: 0;
        }
    }
    &:nth-child(1) {
        border-left: 0px solid #d6d6d6;
    }
    &:nth-child(4) {
        border-right: 0px solid #d6d6d6;
    }
    border-left: 1px solid #d6d6d6;
    border-right: 1px solid #d6d6d6;
}

.magazine-item--half {
    width: 100% !important;
    min-height: 300px !important;
    @media (min-width: @screen-sm) {
        width: 50% !important;
    }
    border-right: 0 !important;
}

.magazine-item__header {
    display: block;
    padding: 10px 20px;
    color: white;
    .sans-serif-heavy;
    font-size: 14px;
    @media (min-width: @screen-md) {
        font-size: 18px;
    }
    line-height: 1.4;
    &:hover,&:active,&:focus {
        text-decoration: none;
        color: yellow;
    }
}

.magazine-item__link {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    color: white;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        position: absolute;
    }
    &:hover,&:active,&:focus {
        text-decoration: none;
        color: yellow;
    }
}

.magazine-item__image {
    position: relative;
    width: 100%;
    padding-bottom: 70%;
}

.magazine-item__text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.magazine-item__title {
    padding: 10px 20px;
    .sans-serif-heavy;
    font-size: 12px;
    @media (min-width: @screen-md) {
        font-size: 16px;
    }
    line-height: 1.4;
    text-align: center;
    margin: 0;
}














// Home lang


/*

.slider {
	height: 480px; //temp
	width: 100%;
	background: #828282; //temp
}

.about-index {
	/*
	h1 {
		color: #fff;
		margin-bottom: 30px;
		margin-top: 0;
		font-family:'AvenirLTStd-Medium';
		line-height: 1.5;
		font-size: 33px;
	}


	p {
		.paragraph;
	}

	strong {
		font-weight: bold;
	}
}

.container-about-index {
	max-width: 586px;
	width: 100%;
	margin: 0 auto;
	text-align: center;
	padding: 70px 0;
}


*/




// .header-frontpage {

// 	.longread__row--flexbox {
// 		flex-direction: column;
// 		@media (min-width: @screen-sm) {
// 			flex-direction: row;
// 		}
// 	}

// 	.container-longread {
// 		padding-top: 50px;
// 		padding-bottom: 50px;
// 	}
// 	.front-page__logo-column {
// 		text-align: center;
// 		display: flex;
// 		align-items: center;
// 		justify-content: center;
// 		position: relative;
// 		.logo {
// 			margin: 0;
// 			@media (min-width: @screen-sm) {
// 				position: absolute;
// 				object-fit: contain;
// 				font-family: 'object-fit: contain;';
// 				height: 100%;
// 				width: 100%;
// 			}
// 		}
// 		.flag-list {
// 			padding: 0;
// 			text-align: left;
// 			position: absolute;
// 			top: -40px;
// 			left: 10px;
// 			z-index: 999;
// 			@media (min-width: @screen-sm) {
// 				top: -10px;
// 				left: 20px;
// 			}
// 		}

// 		@media (max-width: @screen-xs-max) {
// 			margin-bottom: 50px;
// 		}
// 	}
// 	.front-page__video-column {
// 		@media (min-width: @screen-sm) {
// 			padding-left: 50px;
// 			padding-right: 50px;
// 		}

// 	}
// }

// .embed-responsive {
// 	.js_play-button {
// 		width: 100%;
// 		height: auto;
// 		position: absolute;
// 		z-index: 999;
// 		cursor: pointer;
// 		transition: opacity .5s linear 0s;
// 	}

// }
// .playing {
// 	.js_play-button {
// 		opacity: 0;
// 		transition: opacity .5s linear 0s;
// 		pointer-events: none;
// 	}
// }

.container-front-page {

	.main-column {
		text-align: center;
		.make-sm-column(10);
		.make-sm-column-push(1);
		.make-md-column(10);
		.make-md-column-push(1);
		padding-bottom: 40px;
	}
	h1 {
		margin: 0;
	}
	.home-button {
		transition-property: text-shadow;
		transition-duration: .4s;
		&:hover, &:focus, &:active {
			cursor: pointer;
			text-shadow: @shadow;
			transition-property: text-shadow;
			transition-duration: .4s;
		}
	}
	p {
		.paragraph;
	}

}


.home .swiper-container.swiper-container--header{
	height: 355px;
	.swiper-wrapper{
		position: relative;
	}
	@media (min-width: @screen-sm-min) {
		height: 300px;
	}
}

.home .swiper-container .swiper-slide .container--wider{
	height: 100%;
}

.home .swiper-container .swiper-slide .container--wider .row {
	.logo{
		margin: 0 auto;
		margin-top: 20px;
	}
	@media (min-width: @screen-sm-min) {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 50%;
		.logo{
			margin: 0;
		}
	}
}


.home {
    .home-langs {
        position: absolute;
        top: -40px;
        left: 10px;
    }
    .header__logo {
        @media (min-width: @screen-sm-min) {
            left: 50px;
        }
    }
}

.menu-home {
	overflow: hidden;
	text-align: center;
	background: url(../../img/noise_yellow.png) repeat;
	.menu-home__text{
		padding-bottom: 20px;
	}
	.menu-home__link-wrapper{
		padding-top: 20px;
	}
	a {
		margin: 0;
		margin-bottom: 40px;
	}
	.initial-height {
		overflow: hidden;
		display: block;
		padding-top: 20px;
	}

}

.home .menu-home{
	@media (min-width: @screen-sm-min) {
		.initial-height {
			padding-top: 0;
		}
	}
}


.menu-home.menu-realhome{
	.menu-home-wrapper .row{
		padding-left: 10px;
		padding-right: 10px;
	}
	.menu-home__text, .menu-home__link-wrapper{
		display: block;
		padding: 35px 15px;
		text-align: center;
	}
	.menu-home__text p{
		font-size: 12px;
		margin: 0;
	}
	a {
		margin: 0;
	}
	.shape, h3{
		display: inline-block;
		float: left;
	}
	h3{
		margin: 0;
		margin-left: 20px;
		font-size: 18px;
		line-height: 1.2;
		text-align: left;
		flex-grow: 2;
	}
	.initial-height {
		display: flex;
		align-items: center;
	}
	.excerpt-page{
		width: 70%;
	}
	@media (min-width: @screen-sm-min) {
		.menu-home-wrapper .row{
			display: flex;
		}
		.menu-home__text, .menu-home__link-wrapper{
			width: 50%;
			display: inline-block;
			float: left;
			padding: 25px;
			align-self: center;
		}
		.menu-home__text p{
			font-size: 16px;
		}
		.excerpt-page{
			width: 50%;
		}
		.initial-height {
			display: flex;
			align-items: center;
		}
	}
}



