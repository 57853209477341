.home-lang__columns{
	display: flex;
	flex-wrap: wrap;
}

.home-lang__column{
	width: 100%;
	@media (min-width: @screen-sm-min) {
		width: 50%;
		height: 760px;
		display: inline-block;
	}
}

.page-template-template-home_languages {
    .flag-list {
        padding-top: 0;
        padding-bottom: 20px;
    }
}


.home-lang__intro{
	text-align: center;
	padding: 20px;
	padding-bottom: 30px;
	position: relative;
    a {
        color: inherit;
        text-decoration: underline;
        &:hover,&:active,&:focus {
            text-decoration: none;
        }
    }
	p{
		font-weight: 300;
		font-size: 12px;
		line-height: 1.4;
	}
	.logo{
		margin: 0 auto 30px auto;
	}
	@media (min-width: @screen-sm-min) {
		padding: 50px;
		padding-bottom: 70px;
		height: 660px;
		p{
			font-size: 13px;
		}
		.logo{
			margin: 0 auto 40px auto;
		}
	}
}

.home-lang__faq{
	height: 80px;
	text-align: center;
	.excerpt-page{
		margin: 10px 12px 10px 12px;
		.shape{
			width: 30px;
			height: 30px;
			svg {
			    width: 100%;
			    height: 100%;
			}
		}
	}
	@media (min-width: @screen-sm-min) {
		height: 100px;
		.excerpt-page{
			margin: 18px 12px 18px 12px;
			.shape {
			    width: 46px;
			    height: 46px;
			}
		}
	}
}

.home-lang__header{
	font-family: @font-family-sans-serif;
	color: @color-white;
	font-size: 14px;
	letter-spacing: 0.5px;
	font-weight: 700;
	padding: 10px 20px;
	position: relative;
	a{
		color: @color-white;
	}
	@media (min-width: @screen-sm-min) {
		font-size: 17px;
		padding: 10px 30px;
	}
}



.home-lang__publications{
	padding-top: 40px;
	margin-bottom: 2px;
	.carousel-cell{
		padding-left: 20px;
		padding-right: 20px;
		.media-heading{
			font-size: 14px;
		}
		.publication p {
			font-size: 12px;
			line-height: 1.6;
		}
		.publication .media {
			display: table;
		}
		.publication .media-left {
			display: table-cell;
		    width: 170px;
		    padding-right: 50px;
		}
		.publication .media-body {
			display: table-cell;
		}
	}
	@media (min-width: @screen-sm-min) {
		height: 434px;
		.carousel-cell{
			padding-left: 30px;
			padding-right: 30px;
			.media-heading{
				font-size: 16px;
			}
			.publication p {
				font-size: 13px;
			}
			.publication .media-left {
			    width: 190px;
			    padding-right: 50px;
			}
		}
	}
}

.swiper-pagination.swiper-pagination--home-lang-pub {
    bottom: auto;
    width: auto;
    position: relative;
    display: inline-block;
    text-align: center;
    float: right;
	margin-right: 50%;
	transform: translateX(50%);
    .swiper-pagination-bullet {
	    margin: 0 5px;
	}
}


.carousel--pub{
	margin-top: 30px;
	height: 400px;
	@media (min-width: @screen-sm) {
		height: 250px;
	}
	.carousel-cell{
		width: 100%;
	}
}

.carousel--news{
	height: 208px;
	.carousel-cell{
		width: 50%;
		margin-right: 2px;
	}
	@media (min-width: @screen-sm) {
		height: 280px;
	}
}

.page-template-template-home_languages {

    // .carousel-cell{
    //     width: 100%;
    //     height: 100%;
    // }

    .flickity-page-dots {
        top: -60px;
        bottom: auto;
    }

    .flickity-page-dots .dot.is-selected {
        opacity: 1;
        background: @geel-licht;
    }

    .flickity-page-dots .dot {
        opacity: 1;
        background: #fff;
        width: 8px;
        height: 8px;
        margin: 0 5px;
    }


    .flickity-prev-next-button.previous, .flickity-prev-next-button.next {
        position: absolute;
        display: inline-block;
        top: -26px;
        bottom: auto;
        left: 50%;
        height: 12px;
        width: 20px;
        background-image: url(../img/showmorenewsinactief.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
        transition: color .3s ease;
        padding: 0;
        svg{
            display: none;
        }
        &:hover{
            background-image: url(../img/showmorenewsinactief.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-color: transparent;
        }
        @media (min-width: @screen-sm) {
            top: -28px;
        }
    }

    .flickity-prev-next-button.previous{
        transform: rotate(90deg);
        margin-left: -40px;
    }

    .flickity-prev-next-button.next{
        transform: rotate(-90deg) translateY(-47%);
        margin-left: 30px;
    }


    .home-lang__news{
        font-family: @font-family-sans-serif;
        color: @color-white;
        font-weight: 700;
    }




    .flickity-prev-next-button:disabled{
        opacity: 0;
    }

}