

.block {

	&.block-text {
		max-width: 586px;
		width: 100%;
		margin: 0 auto;
		text-align: center;

		p {
			.paragraph;
		}
	}

	// &.block-text-methodology {
	// 	margin-top: 90px;

	// 	&.block-text-methodology-intro {
	// 		margin-top: 50px;
	// 	}

	// 	h2 {
	// 		color: #fff;
	// 		margin-bottom: 30px;
	// 		margin-top: 0;
	// 		font-family:'AvenirLTStd-Medium';
	// 		line-height: 1.5;
	// 		font-size: 33px;
	// 	}
	// }

	// &.block-text-methmodal {
	// 	color: #fff;
	// }

	&.block-text-media-toolkit {
		color: #fff;
	}

	&.block-text-white {
		color: #fff;
	}

	&.block-text-about {
		p {
			margin-bottom: 0;
		}
	}

	&.block-resources {
		margin: 78px 0;
	}
}

@media (max-width: 586px) {
	.block.block-text {
		padding-left: 20px;
		padding-right: 20px;
	}
}


.disclaimer, .avertissement {
	h1 {
		font-size: 50px;
	}
	.block-text {
		color: @color-text;
		padding-top: 50px;
		padding-bottom: 100px;
		min-height: calc(~"100vh - 44px");
	}
}