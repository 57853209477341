// Header

.country-header {
    padding-top: 40px;
    @media (min-width: @scorecard-breakpoint) {
        padding-top: 60px;
    }
    padding-bottom: 40px;
}



.country-header__column--small {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
    @media (min-width: @scorecard-breakpoint) {
        width: 25%;
        margin-bottom: 0;
    }
}

.country-header__column--wide {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
    @media (min-width: @scorecard-breakpoint) {
        width: 50%;
        margin-bottom: 0;
    }
}

.country-header__column__title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    @media (min-width: @scorecard-breakpoint) {
        padding-left: 0;

    }
    h1.main-title {
        font-size: 30px;
        margin: 0;
        padding: 0;
        text-align: left;
    }
    .country-silhouette {
        flex: 1 0 auto;
        width: 100%;
        display: flex;
        @media (min-width: @scorecard-breakpoint) {
            justify-content: center;
        }
    }
    img {
        margin: 40px 0;
        margin-top: 20px;
        width: 100%;
        max-width: 200px;
        max-height: 200px;
        align-self: flex-start;
        justify-self: flex-start;
        opacity: 0.4;
    }
    .last-updated {
        .sans-serif-small;
        color: @color-text;
    }
}



.country-header__column__info {

}

.country-header__info {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        font-size: 12px;
        line-height: 1.4;
        .sans-serif;
        color: white;
        padding-top: 7px;
        padding-bottom: 7px;
        padding-right: 20px;
        strong {
            color: @color-text;
            font-weight: normal;
            .sans-serif-heavy;
            display: block;
        }
        border-bottom: 1px solid @color-text;
        &:last-child {
            border-bottom: 0;
        }
    }
}



.country-header__column__download {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 0;
    flex-direction: column;
    @media (min-width: @scorecard-breakpoint) {
        padding-right: 0;
    }
    .scorecard__lang {
        margin-top: 10px;
        @media (min-width: @scorecard-breakpoint) {
            margin-top: 0px;
            margin-left: 30px;
        }
    }
    .logowrapper {
        flex-grow: 1;
    }
    .logo {
        width: 100%;
        height: auto;
        margin-bottom: 30px;
        max-width: 200px;
    }
    .scorecard-download {
        align-self: flex-start;
        margin-top: 30px;
        @media (min-width: @scorecard-breakpoint) {
            margin-left: 30px;
        }
    }

}


.country-columns {
    .scorecard__container {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.country-columns__column {
    .font-smoothing();

    p {
        font-size: 13px;
        line-height: 1.4;
        margin-bottom: 20px;
    }
    padding-bottom: 20px;
    @media (min-width: @scorecard-breakpoint) {
        flex: 50% 0 0;
        &:nth-child(1) {
            padding-right: 40px;
        }
        &:nth-child(2) {
            padding-left: 40px;
            border-left: 2px solid @color-white;
        }
    }
}




// Country Tables

.countryprofile__tablesection {
    .scorecard__container {
        padding-top: 40px;
        border-top: 2px solid white;
        @media (max-width: @scorecard-breakpoint) {
            padding-bottom: 0;
        }
        ul.footnotes {
            margin: 0;
            padding: 0;
            list-style: none;
            margin-bottom: 40px;
            li {
                .sans-serif;
                font-size: 9px;
            }
        }

        .paragraph {
            .font-smoothing();
            font-size: 13px;
            line-height: 1.4;
            margin-bottom: 0px;
            max-width: 500px;
        }
    }

    .scorecard__column--overflow {
        border-bottom: 0;
        margin-bottom: 0;
    }
}

h5.countryprofile__section-title {
    .sans-serif-heavy;
    font-size: 20px;
    margin: 0;
    margin-bottom: 20px;
    & + .table-overflow-wrapper  {
        display: block;
        margin-top: 40px;
    }
    @media (max-width: @scorecard-breakpoint) {
        br {
            display: none;
        }
    }
    a {
        color: inherit;
        text-decoration: underline;
        &:hover,&:active,&:focus {
            text-decoration: none;
            color: inherit;
        }
    }
}

.table__country-companies {
    width: auto;
    margin-bottom: 30px;
    .table-name {
        width: 180px;
        padding-left: 0;
    }
    .dot {
        width: 70px;
    }
    .border-right, .table-name {
        border-right-width: 2px !important;
    }

    .section-heading-row {
        th {
            &:first-child {
                text-align: left;
            }
        }
    }

    tr {
        &:last-child {
            td {
                border-bottom-width: 0px !important;
            }
            td.spacer {
                border-bottom-width: 0px !important;
            }
        }
    }



}