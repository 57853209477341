.press-release {
	padding: 15px 0 15px 30px;
	position: relative;
	border-bottom: 2px solid rgb(170, 170, 170);

	a {
		color: @text-color;
		transition-property: text-shadow, color;
		transition-duration: .4s;
		
		&:hover, &:focus, &:active {
			text-decoration: none;
			text-shadow: @shadow;
			color: @color-white;
			transition-property: text-shadow, color;
			transition-duration: .4s;
		}
	}

	&:last-child {
		border-bottom: none;
	}

	.download-arrow;

	.published {
		.sans-serif;
		color: @color-white;
	}

	p {
		.paragraph;
		font-weight: 300;
		margin: 0;
	}
}