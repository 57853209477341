.menu-top {
	a {
		&:hover, &:focus, &:active {
			outline: 0 !important;
		}
	}
}

.a2a_mini {
	width: 132px !important;
	min-width: 132px !important;
}
.a2a_menu {
	border-radius: 0 !important;
	border: 0 !important;
	a {
		border: none !important;
		color: darken(@grijs-donker, 10%) !important;
		font-family: @font-family-sans-serif !important;
		.font-smoothing;
		font-size: 14px !important;
		line-height: 25px !important;
		width: 100%;
		clear: both;
	}
}
.a2apage_wide {
	display: none !important;

}



.navbar-collapse {
	@media (max-width: @screen-sm-max) {
		margin-top: 44px;
		display: block;
	}
}

.navbar-nav {
	line-height: 44px;
	@media (max-width: @screen-sm-max) {
		padding-bottom: 88px !important;
	}
}

.navbar-header {
	width: 100%;
	margin: 0 !important;
}

.toggle-menu {
	width: 100%;
	z-index: 0;
	display: block;
	position: absolute;
	//height: 44px;
}

.navbar-toggle {
	float: none;
	display: none;
	@media (max-width: @screen-sm-max) {
		display: block;
	}
	margin-left: auto;
	margin-right: auto;
	border: 0;
	margin-top: 8px;
	margin-bottom: 4px;
	border-radius: 0;
	.icon-bar {
		border-radius: 0;
		background: rgba(140,140,140,1) !important;
	}
	&:hover, &:focus, &:active {
		background: none !important;
		.icon-bar {
			background: @grijs-donkerst !important;
		}
	}
}


.menu-top {
	.noshow {
		display: none !important;
	}
	position: fixed;
	//height: 44px;
	width: 100%;
	background: #fff;
	top: 0;
	left: 0;
	z-index: 1000;
	line-height: 50px;
	color: rgb(140,140,140);
	font-family:'AvenirLTStd-Book';
	@media (max-width: @screen-sm-max) {
		overflow: hidden;
	}
	border: none;

	.menu {
		list-style: none;
		padding: 0;
		margin: 0;
		text-align: center;
		li {
			display: block;
		}

		a {
			display: inline-block;
			color: rgb(140,140,140) !important;
			padding: 0 5px;

			&:hover, &:active, &:focus {
				text-decoration: none;
				color: #3C3C3C !important;
			}
		}

		.icon, .a2a_kit {
			height: 50px;
			width: 16px;
			display: block;
			.text-hide();
			padding: 17px 0px;
			margin-left: 5px;
			margin-right: 5px;
			a {
				.text-hide();
				width: 100%;
				height: 100%;
				top: 0;
				padding: 0;
				left: 0;
			}
		}

		.current-menu-item, .current-page-ancestor {
			color: #3C3C3C !important;

			a {
				color: #3C3C3C !important;
			}
		}
	}

	.menu.menu-left, .menu.menu-right {
		height: 44px;
		position: relative;
		z-index: 999;
	}

	.menu.menu-left {
		text-align: left;
		@media (max-width: @screen-sm-max) {
			overflow: hidden;
		}
		float: left;
		margin-left: 5px;

		li {
			float: left;
		}

		.item-home {
			a, span {
				cursor: pointer;
                svg {
                    path, polygon {
                        fill: rgba(140,140,140,1);
                    }
                }
				&:hover {
					svg {
						path, polygon {
							fill: @bruin-donkerst;
						}
					}
				}
			}
		}
	}

	.menu.menu-right {
		text-align: left;
		@media (max-width: @screen-sm-max) {
			overflow: hidden;
		}
		float: right;
		margin-right: 5px;

		li {
			float: left;
			a {
				&:hover, &:active, &:focus {
					text-decoration: none;
					color: #3C3C3C !important;
				}
			}
		}

		.item-lang {
			@media (max-width: @screen-sm-max) {
				display: none;
			}
			margin-right: 5px;
			a {
				padding-top: 15px;
				display: block;
			}
			svg {
				display: block;
				height: 18px;
				width: auto;
			}
		}

		.item-search {
			a, span {
                svg {
                    path, polygon {
                        fill: rgba(140,140,140,1);
                    }
                }
				cursor: pointer;
				&:hover {
					svg {
						path, polygon {
							fill: @bruin-donkerst;
						}
					}
				}
			}
		}

		.item-share {
			a, span {
				cursor: pointer;
                svg {
                    path, polygon {
                        fill: rgba(140,140,140,1);
                    }
                }
			}
			&:hover {
				a, span {
					svg {
						path {
							fill: @bruin-donkerst;
						}
					}
				}
			}
		}

		.item-share .a2a_kit {
			width: 26px;
			padding: 0;
			margin: 0;
			color: transparent !important;
			position: absolute;
			top: 0;
			span {
				display: none;
			}
			a {
				color: transparent !important;
				&:before {
					display: none !important;
				}
			}
			&:hover {
				color: transparent !important;
			}

		}
	}

	.menu-right {
		right: 0;
	}
}

@media (min-width: @grid-float-breakpoint) {
	.menu-top {
		.menu {
			float: none;

			> li {
				float: none;
				display: inline;
				height: 44px;
				line-height: 50px;
			}
		}

		.menu.menu-left, .menu.menu-right {
			position: absolute;
			float: none;
			top: 0;
		}

		.menu.menu-right {
			right: 15px;
		}

		.menu.menu-left {
			left: 15px;
		}
	}
}



.navbar-fixed-top,
.navbar-fixed-bottom {
  .navbar-collapse {
    max-height: @navbar-collapse-max-height;

    @media (max-device-width: @screen-xs-min) and (orientation: landscape) {
      max-height: 0px;
    }
  }
}
