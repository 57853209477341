.wrapper-scope {
    .container-fluid;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    padding-top: 40px;
}

.container-scope {
    .container-fluid;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1024px !important;
    position: relative;
    @media (min-width: @screen-lg) {
        .container;
        padding-left: 20px;
        padding-right: 20px;
        width: 1024px !important;
    }
}

.scope__row {
    .make-row();
    margin-left: -20px;
    margin-right: -20px;
}


.scope__col--full {
    .make-xs-column(12);
    padding-left: 20px;
    padding-right: 20px;
}

.scope__col--mid {
    .make-xs-column(12);
    .make-sm-column(9);
    .make-sm-column-push(1.5);
    padding-left: 20px;
    padding-right: 20px;
}

.scope__col--midsmall {
    .make-xs-column(12);
    .make-sm-column(8);
    .make-sm-column-push(2);
    padding-left: 20px;
    padding-right: 20px;
}

.scope__col--wider {
    .make-xs-column(12);
    .make-sm-column(7);
    padding-left: 20px;
    padding-right: 20px;
}

.scope__col--smaller {
    .make-xs-column(12);
    .make-sm-column(5);
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    @media (min-width: @screen-sm) {
        padding-bottom: 0px;
    }
}




.scope__header {
    padding-top: 50px;
    .header__logo {
        width: 100%;
        max-width: 220px;
        margin-bottom: 20px;
    }
    .scope__header__title {
        margin: 0;
        padding: 0;
        .sans-serif-heavy;
        color: white;
        margin-bottom: 30px;
        font-size: 30px;
        line-height: 1.1;
        @media (min-width: @screen-sm) {
            font-size: 50px;
            text-align: left;
        }
    }
    .scope__header__text {
        p {
            color: white;
            margin: 0;
            .sans-serif-heavy;
            font-size: 20px;
            line-height: 1.2;
            text-align: center;
            @media (max-width: @screen-xs-max) {
                br {
                    display: none;
                }
            }
            @media (min-width: @screen-sm) {
                font-size: 25px;
                text-align: left;
            }
        }
    }

    .scope__introduction {
        text-align: center;
        font-size: 15px;
        @media (min-width: @screen-sm) {
            font-size: 20px;
        }
        line-height: 1.4;
        margin-bottom: 10px;
        p {
            margin-bottom: 20px;
            @media (min-width: @screen-sm) {
                margin-bottom: 25px;
            }
            &:last-child {
                margin: 0;
            }
        }
    }


}


.scope__header-image {
    padding-top: 0;
    padding-bottom: 0;
    padding-bottom: 40px;
    img, svg {
        width: 100%;
        height: auto;
    }
}

.scope__image {
    margin-top: 20px;
    figure {
        margin-bottom: 40px;
        display: block;
        position: relative;
    }
    img, svg {
        width: 100%;
        height: auto;
    }
    figcaption {
        .sans-serif-book;
        font-size: 13px;
        line-height: 18px;
        margin-top: 20px;
        text-align: center;
    }
    .caption--truncated {
        // height: 36px; // Use multiples of the line-height
        // overflow: hidden;
        // line-height: 18px;

        position: relative;
        cursor: pointer;

        .image__title {
            display: block;
            margin-bottom: 20px;
            &:before {
                content: '\25bc';
                position: absolute;
                top: 0;
                bottom: 9px;
                right: 20px;
                @media (min-width: @screen-sm) {
                    right: 0;
                }
                z-index: 99;
            }
            transition: opacity .5s linear 0s;
            &:hover,&:active,&:focus {
                opacity: 0.5;
                transition: opacity .5s linear 0s;
            }
        }
        .image__caption {
            display: none;
        }

        // &:after {
        //     content: "";
        //     position: absolute;
        //     bottom: 0;
        //     right: 20px;
        //     width: 200px;
        //     height: 18px;
        //     background: url(../../img/noise-fade.png);
        //     background-size: cover;
        // }
        // &:hover {
        //     &:before {
        //         content: '\25bc';
        //         position: absolute;
        //         bottom: 9px;
        //         right: 0px;
        //         z-index: 99;
        //     }
        // }
        // @supports (-webkit-line-clamp: 2) {
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        //     display: -webkit-box;
        //     -webkit-line-clamp: 2;
        //     -webkit-box-orient: vertical;
        // }
    }

    .caption--collapsed {
        // height: auto !important;
        // -webkit-line-clamp: 999;
        // &:after, &:before {
        //     display: none;
        // }
        .image__title {
            &:before {
                content: '\25b2';
            }
        }
        .image__caption {
            display: block;
        }
    }

}

.scope__wysiwyg {

    .scope__title {
        margin: 0;
        padding: 0;
        .sans-serif-heavy;
        font-size: 40px;
        line-height: 1.2;
        text-align: center;
        color: white;
        margin-bottom: 30px;
        margin-top: 20px;
    }
    p {
        .serif;
        font-size: 15px;
        line-height: 1.4;
        text-align: center;
        margin-bottom: 20px;
    }
    .criteria__title {
        .scope__title;
        font-size: 25px;
    }
    .criteria__image {
        width: 100%;
        height: auto;
        margin-bottom: 30px;
    }

    a {
        text-decoration: none;
        &:hover,&:focus,&:active&:visited {
            color: @color-blue;
            text-decoration: none;;
        }
    }
}


.region {
    overflow: hidden;
    padding-top: 40px;
    &:first-child {
        padding-top: 0;
    }
    padding-bottom: 40px;
    position: relative;
    &:after {
        content: '';
        height: 2px;
        display: block;
        position: absolute;
        left: 20px;
        right: 20px;
        background: white;
        bottom: 0;
    }
    &:last-child {
        &:after {
            display: none;
        }
    }

}

.region__left {
    .region__title {
        margin: 0;
        .sans-serif-heavy;
        font-size: 25px;
        line-height: 1.4;
        text-align: left;
        color: @color-text;
        margin-bottom: 20px;
    }
    p {
        .serif;
        font-size: 15px;
        line-height: 1.4;
        margin: 0;
        @media (max-width: @screen-xs-max) {
            margin-bottom: 30px;
        }
    }
}

.region__right {
    .sans-serif-heavy;
    color: white;
    font-size: 18px;
    line-height: 1.4;
    display: block;
    padding-bottom: 0;
    @media (min-width: @screen-sm) {
        margin-top: 53px;
    }
}