.team-main {
	text-align: center;
	.paragraph;
}

.team-list {
	.container-fluid;
	margin-top: 40px;
	margin-bottom: 60px;
	.row {
		.make-row();
		overflow: hidden;
		.team-column {
			.make-xs-column(12);
			.make-sm-column(12);
			.make-sm-column-push(0);
			.make-md-column(10);
			.make-md-column-push(1);
			.make-lg-column(8);
			.make-lg-column-push(2);
		}
		.member {
			.make-xs-column(6);
			.make-sm-column(3);
			a {
				color: inherit;
				&:hover, &:focus, &:active {
					text-decoration: none !important;
				}
			}
			img {
				width: 100%;
				height: auto;
			}
			dl {
				.sans-serif;
				text-align: center;
				margin-top: 5px;
				font-weight: normal;
				dt {
					.sans-serif-book;
					font-weight: normal !important;
				}
			}
			cursor: pointer;
			&:hover, &:active, &:focus {
				dl {
					color: @color-white;
					text-shadow: @shadow;
				}
			}
		}
	}

}


.tax-department {
	.wrapper-full-height.bg-noise-gray {
		@media (min-width: @screen-sm-min) {
			position: relative;
			overflow: visible;
			// &:after {
			// 	background: url(../../img/noise_gray.png) repeat !important;
			// 	content: '';
			// 	bottom: -100px;
			// 	left: 0;
			// 	display: block;
			// 	width: 100%;
			// 	position: absolute;
			// 	height: 100px;
			// }
		}
	}
}


.sticky-gov-header {
    @media (min-width: @screen-sm-min) {
        position: sticky;
    }
    bottom: 0;
    margin-left: -10px;
    margin-right: -10px;
    h2 {
		text-align: center;
		font-size: 18px;
		line-height: 1.11;
		padding-bottom: 43px;
		padding-top: 43px;
		color: @geel-licht;
		cursor: pointer;
		margin: 0;
		transition-property: color;
		transition-duration: .5s;
		&:hover {
			color: @color-white;
			transition-property: color;
			transition-duration: .5s;
		}
	}
}