.footer-site {
	background: @bruin-donker;
	color: @grijs-licht;
	padding: 53px 0;
	@media (min-width: @screen-sm-min) {
		padding: 50px 0;
		padding-bottom: 0;
	}
	position: relative;
	padding-bottom: 0;

	// @media (min-width: @screen-sm-min) {
	// 	bottom: 0;
	// 	position: fixed;
	// 	z-index: -99;
	// }

}

.container-footer-site {
	//max-width: 808px;
	max-width: 788px;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;

	// > div {
	// 	float: left;
	// 	width: 50%;
	// }

	.container-footer-site-logo {
		float: left;
		max-width: 283px;
		width: 100%;
		img{
			height: 125px;
		}
	}

	.container-footer-site-text {
		float: right;
		max-width: 404px;
		width: 100%;
		padding-bottom: 53px;
	}

	p {
		font-size: 11px;
		font-family: 'Merriweather', @font-family-sans-serif;
		line-height: 1.45;
		font-weight: 300;
	}

	.description-company {
		font-size: 11px;
		font-family: 'Merriweather', @font-family-sans-serif;
		line-height: 1.45;
		font-weight: 300;

		p {
			margin-bottom: floor((11 * 1.45));
		}
	}

	.logo {
		margin: 0 auto;
	}

	.social-media {
		list-style: none;
		padding: 0;
		margin: 0;
		overflow: hidden;

		li {
			float: left;
			margin-right: 10px;
			.square(30px);
		}

		a {
			display: block;
			.square(30px);
			.text-hide();
			&:hover {
				svg {
					path {
						fill: @color-white;
					}
				}
			}
		}

		.facebook a {
			//background: url(../img/ASF_fb_button_gray.svg);

			&:hover {
				//background: url(../img/ASF_fb_button_white.svg);
			}
		}

		.twitter a {
			//background: url(../img/ASF_tw_button_gray.svg);

			&:hover {
				//background: url(../img/ASF_tw_button_white.svg);
			}
		}

		.linkedin a {
			//background: url(../img/ASF_in_button_gray.svg);

			&:hover {
				//background: url(../img/ASF_in_button_white.svg);
			}
		}
	}
}

.container-footer-site-logo {
	text-align: center;
	position: relative;
	.tagline {
		font-family:'AvenirLTStd-Book';
		max-width: 220px;
		width: 100%;
		margin: 30px auto 0 auto;
		text-align: center;
		font-size: 13px;
		line-height: 1.21;
	}

	&:after {
		content: '';
		position: absolute;
		width: 2px;
		height: 500px;
		background: @geel-licht;
		display: block;
		left: 50%;
		margin-left: -1px;
		margin-top: 20px;
		@media (max-width: @screen-xs-max) {
			display: none;
		}
	}
}

.newsletter-footer {
	margin: 25px 0 25px 0;


	h3 {
		.sans-serif-heavy;
		font-size: 14px;
		margin-bottom: 5px;
		margin-top: 0;
		color: @color-white;
		text-align: left;
		@media (max-width: @screen-xs-max) {
			text-align: center;
		}
	}

	p {
		font-size: 11px;
		font-family: 'Merriweather', @font-family-sans-serif;
		line-height: 1.45;
		font-weight: 300;
	}

	.form-inline {
		max-width: 324px;
		width: 100%;
		font-family:'AvenirLTStd-Book';
		font-size: 11px;
		border-top: 1px solid @grijs-licht;
		border-bottom: 1px solid @grijs-licht;
	}

	.form-control {
		border-radius: 0;
		background: @bruin-donker;
		font-size: 11px;
		padding-top: 0;
		padding-bottom: 0;
		padding-left: 0;
		height: 22px;
		margin: 4px 0;
		border: none;
		border-right: 1px solid @grijs-licht;
		.placeholder(@grijs-licht);
		outline: none;
		box-shadow: none !important;
		color: @grijs-licht;
		width: 252px;
	}

	.btn {
		border-radius: 0;
		background: @bruin-donker;
		color: @grijs-licht;
		font-size: 11px;
		padding-left: 9px;
		padding-right: 9px;
		&:focus, &:active {
			outline: 0;
		}
	}
}

.template-contact {
	.footer-site {
		background: @bruin-donkerst;
	}

	.newsletter-footer {
		.form-control {
			background: @bruin-donkerst;
		}

		.btn {
			background: @bruin-donkerst;
		}
	}
}

@media (max-width: @screen-xs-max) {
	.container-footer-site {
		.container-footer-site-logo {
			float: none;
			max-width: 100%;
			padding: 0 15px;
		}

		.container-footer-site-text {
			float: none;
			max-width: 100%;
			margin-top: 30px;
			padding: 0 15px;
			padding-bottom: 53px;
			text-align: center;
		}

		.description-company {
			text-align: center;
			max-width: 304px;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
		}

		.form-inline .form-group {
			display: inline-block;
			margin-bottom: 0;
			vertical-align: middle;
		}

		.social-media {
			li {
				display: inline;
				float: none;
			}

			a {
				display: inline-block;
			}
		}
	}

	.newsletter-footer {
		max-width: 304px;
		width: 100%;
		margin: 40px auto 25px auto;

		.form-inline {
			//max-width: 304px;
			width: 100%;
		}

		.form-control {
			width: 200px;
		}
	}
}

@media (min-width: @screen-sm-min) {

	body.footer-margin {
		&:after {
			content: '';
			height: 380px;
			display: block;
			background: none;
			position: relative;
			pointer-events: none;
			width: 100%;
			position: absolute;
		}
	}
	.container-footer-site {
		.container-footer-site-logo {
			img{
				height: 167px;
			}
		}
	}
	.container-footer-site-logo {
		.tagline {
			font-size: 14px;
		}
	}
}

.support-logos{
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.footer-logo{
	width: 33%;
	height: auto;
	max-height: 40px;
	padding-right: 20px;
	// margin-right: 20px;
}