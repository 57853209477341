.nav-page {
	.reset-list;
	
	.h3;
	
	padding: 40px 0;
	position: relative;

	li {
		display: block;
		br {
			display: none;
		}
		padding: 10px 30px;
		@media (min-width: @screen-sm-min) {
			display: inline-block;
			br {
				display: inline;
			}
			padding: 6px 30px;
		}
		
		
		
		
		position: relative;

		&.current_menu_item a, &.current_page_item a, &.active a, a:hover, a:focus, a:active {
			color: @color-white;
			text-decoration: none;
		}
		
		&:after {
			content: '';
			height: 2px;
			width: 25%;
			bottom: 0;
			right: auto;
			left: 50%;
			margin-left: -12.5%;
			@media (min-width: @screen-sm-min) {
				width: 2px;
				height: 100%;
				right: -4px;
				top: 0;
				margin-left: 0;
				left: auto;
			}
			
			display: block;
			background: rgb(160,160,160);
			.publications, .press-releases, .videos, .media-toolkit & {
				background: rgb(170,170,170);
			}
			position: absolute;
			
			
			
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
		
		
	}

	a {
		text-align: center;
		display: block;
		color: rgb(160, 160, 160);
		.publications, .press-releases, .videos, .media-toolkit & {
			color: rgb(170,170,170);
		}
	}

	&.nav-page-methmodal {
		padding-top: 0;
	}
}




.container-ats {
	&:last-of-type {
		display: none;
	}
}