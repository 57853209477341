.top-half, .bottom-half {
	width: 100%;
	display: table;
	@media (max-width: @screen-xs-max) {
		display: block;
		width: auto;
	}
}

.container-methodology {

	display: table-cell;
	vertical-align: middle;
	padding-bottom: 30px;
	@media (max-width: @screen-xs-max) {
		display: block;
	}

	@media (min-width: @screen-sm-min) {
		padding-left: 0 !important;
	}

	.heading-methodology-wrapper{
		.make-xs-column(12);
		@media (min-width: @screen-sm-min) {
			padding-left: 0;
		}
	}

	h1 {
		padding-top: 30px;
		padding-bottom: 30px;
		@media (min-width: @screen-sm-min) {
			font-size: 50px;
			line-height: 1.2;
			padding-top: 140px;
			padding-bottom: 0;
		}
		text-align: left;
		margin: 0;
	}

	.main-column {
		.make-xs-column(12);
		.make-sm-column(6);
		.make-md-column(7);
		padding-bottom: 20px;
		display: table;
		@media (min-width: @screen-sm-min) {
			padding-left: 0;
			padding-bottom: 40px;
		}



		p {
			.paragraph;
			//margin-bottom: 10px;
			display: table-cell;
			vertical-align: bottom;
			padding-bottom: 20px;
		}



	}
	.sub-column {
		.make-xs-column(12);
		.make-sm-column(6);
		.make-md-column(5);

		@media (min-width: @screen-sm-min) {
			padding-right: 0 !important;
		}

		.row {
			.make-row();
			overflow: hidden;
		}

		.image {
			.make-xs-column(6);
			display: table;
			padding-bottom: 50px;
			a {
				display: table-cell;
				vertical-align: bottom;
			}
			img {
				width: 100%;
				height: auto;
				.image-shadow;
			}
		}
		.text-row {
			.make-xs-column(6);
			.sans-serif;
			color: @color-white;
			padding-right: 0 !important;
			display: table;
			padding-bottom: 50px;
			span {
				display: table-cell;
				vertical-align: bottom;
				padding-bottom: 10px;
			}
			a {
				color: #ffff00;

				position: relative;
				.sans-serif;
				.download-arrow-yellow;
                &:hover, &:focus, &:active {
                    color: #666666;
					text-decoration: none;
					filter: drop-shadow(@shadow);
                    .download-arrow-grey;
				}
				padding-left: 20px !important;
			}
		}
	}
}


.scope-nav {
	padding-top: 0;
	padding-bottom: 10px;
	li {
		&:after {
			background: @geel-licht;
		}
	}
}



.weighting-scoring {
	.block-text-methodology {
		&:last-of-type {
			margin-bottom: 100px !important;
		}
	}
}



.methodology-sub-container {
	h2 {
		.h1;
	}
	.main-column {
		.make-sm-column(10);
		.make-sm-column-push(1);
		text-align: center;
		p {
			.paragraph;
			@media (max-width: @screen-xs-max) {
				br {
					display: none;
				}
			}
		}
		img {
			width: 100%;
			height: auto;
		}
		margin-bottom: 60px;
	}
}





.swiper-graph {
	position: relative;
	.swiper-wrapper {
		top: 0;
		left: 0;
		position: relative;
	}
}

.methodology-slider, .modal-table {

	.swiper-slide {
		position: relative;
		text-align: center;
		background: url('../../img/noise_gray_dark.png') repeat;
		img {
			cursor: pointer;
			display: inline-block;
			position: relative;
			max-width: 100%;
			height: auto;
			@media (min-width: @screen-sm-min) {
				min-height: 300px;

			}
			max-height: 700px;
			height: 100%;
		}
	}

	.swiper-pagination {
		width: 100%;
		position: relative;
		margin-top: 20px;
		margin-bottom: 20px;
		.swiper-pagination-bullet {
			margin-left: 4px;
			margin-right: 4px;
			background: rgba(60,60,60,.25);
		}
		.swiper-pagination-bullet-active {
			background: @color-white !important ;
		}
	}

}



.how-it-works-graph, .roadmap {
	.swiper-slide {
		background: url('../../img/noise_gray.png') repeat !important;
	}
	.swiper-pagination {
		bottom: 0;
		margin-top: 40px;
	}
}


.timeline-column {
	.make-sm-column(10);
	.make-sm-column-push(1);
}

.timeline {

	.fade {
		width: 100px;
		height: 100%;
		position: absolute;
		top: 0;
		opacity: 1;
		z-index: 99;
	}
	.fade-left {
		left: 0;
		background: url(../../img/noise-fade.png);
		transform: rotate(180deg);
	}
	.fade-right {
		right: 0;

		background: url(../../img/noise-fade.png);
	}
	.swiper-slide {
		background: none;
		min-height: auto;
		max-height: auto;
		width: auto;
		height: auto;
		img {
			width: auto;
			height: auto;
			min-height: auto;
			min-width: auto;
			height: 400px;
			margin-bottom: 20px;
		}
		.swiper-caption {
			display: none;
		}
	}

}

.caption-column {
	.make-sm-column(7);
	.make-sm-column-push(2.5);
	min-height: 500px;
	article.caption {
		padding-left: 5px;
		width: 100%;
		text-align: left;
		h3 {
			.h3;
			margin-bottom: 10px;
			text-align: left;
			@media (min-width: @screen-sm-min) {
				font-size: 24px;
				line-height: 1.1;
			}
		}
		p {
			.sans-serif;
		}
	}
}


.roadmap {
	.swiper-slide {
		width: 100%;
		height: 100%;
		img {
			width: 100%;
			height: auto;
		}
	}
}

.roadmap-caption {
	.caption {
		text-align: center !important;
		h3 {
			text-align: center !important;
			@media (min-width: @screen-sm-min) {
				font-size: 24px;
				line-height: 1.1;
			}
		}
	}

}






.menu-methodology {
	.container;
	display: table-cell;
	vertical-align: middle;
	border-top: 2px solid #fff;

	//margin: 90px auto 0 auto;
	.clearfix();

	.row {
		padding: 50px 0;
	}

	.excerpt-page {
		.make-xs-column(12);
		.make-sm-column(3);

		padding-left: 0px;
		padding-right: 0px;



		h3 {
			font-size: 18px;
			line-height: 22px;
			padding-top: 10px;
		}
		p {
			font-size: 12px;
			font-family: 'Merriweather', @font-family-sans-serif;
			line-height: 18px;
			font-weight: 300;
		}

		margin: 0;
		margin-bottom: 40px;
		@media (min-width: @screen-sm-min) {
			margin-bottom: 0;
		}

		display: block;
		float: left;
		color: @bruin-donker;
		opacity: 1;
		transform: translateZ(0);
		transition-property: opacity, transform, color;
		transition-duration: .4s;
		&.faded {
			opacity: 1;
			color: @grijs-donkerst;
			transform: translateZ(0);
			transition-property: opacity, transform, color;
			transition-duration: .4s;
			.shape {

				.inactive {

					opacity: 1;
				}
				.active {
					opacity: 0;
				}

			}
		}



		.shape {
			.square(82px);
			position: relative;

			p {
				font-size: 11px;
				font-family: 'Merriweather', @font-family-sans-serif;
				line-height: 1.45;
				font-weight: 300;
			}
			img {
				transform: translateZ(0);
				transition-property: opacity, transform;
				transition-duration: .4s;
			}
			.inactive {
				display: block;
				opacity: 0;
			}
			.active {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 1;
			}


		}

		&:hover {
			color: @color-white;
			.shape {
				img {
					transform: translateZ(0);
					transition-property: opacity, transform;
					transition-duration: .4s;
				}
				.active {
					opacity: 1;
				}
				.inactive {
					opacity: 0;
				}
			}
		}


	}

}

.menu-methodology--2{
	.excerpt-page {
		.make-sm-column(6);
	}
}

.menu-methodology--3{
	.excerpt-page {
		.make-sm-column(4);
	}
}

.menu-methodology--4{
	.excerpt-page {
		.make-sm-column(3);
	}
}

.menu-methodology--5{
	.excerpt-page {
		.make-sm-column(2.4);
	}
}
