.gov {
	max-width: 828px;
	width: 100%;
	margin: 0 auto;
	color: #fff;

	.column {
		float: left;
		width: 33.3%;
		padding: 0 20px;
		margin-bottom: 60px;
	}



	.active {
		color: @color-white;
		transition-property: color;
		transition-duration: .5s;
	}

	h4 {
		font-size: 14px;
		margin-top: 0;
		margin-bottom: 0;
		line-height: 18px;
	}

	p {
		color: @bruin-donker;
		.serif;
		font-size: 11px;
		line-height: 1.45;

	}
}

@media (max-width: @screen-xs-max) {
	.gov {
		.column {
			float: none;
			width: 100%;
		}
	}
}