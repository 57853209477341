.companies-header {
    //position: fixed;
    //top: 44px;
    width: 100%;
    z-index: 99;
    @media (max-width: @screen-xs-max) {
        position: relative;
        top: 0px;
    }

    .container {
        .row {
            @media (min-width: @screen-sm-min) {
                display: flex;
            }
        }
    }

    header {
        padding-left: 10px;
        padding-right: 10px;
        @media (max-width: @screen-xs-max) {
            text-align: center;
        }
        @media (min-width: @screen-sm-min) {
            flex: 45% 0 0;
        }
        .main-title {
            padding-top: 30px;
            padding-bottom: 30px;
            @media (min-width: @screen-sm-min) {
                font-size: 50px;
                line-height: 22px;
                padding-top: 60px;
                padding-bottom: 45px;
            }
            text-align: left;
            margin: 0;
            @media (max-width: @screen-xs-max) {
                text-align: center;
            }
        }

        .nav-page {
            .sans-serif-heavy;
            font-size: 12px;
            line-height: 1.2;
            text-align: left;
            padding-top: 0;
            padding-bottom: 30px;
            display: flex;
            flex-direction: column;
            @media (min-width: @screen-sm-min) {
                flex-direction: row;
            }
            @media (max-width: @screen-xs-max) {
                padding-bottom: 0;
            }
            .page_item {
                a {
                    &:hover, &:active, &:focus {
                        .icon {
                            svg {
                                path, polygon, rect {
                                    fill: @color-white;
                                }
                            }
                        }
                    }
                }
                .icon {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    display: block;
                    position: relative;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 7px;
                }
                @media (min-width: @screen-sm-min) {
                    padding-left: 10px;
                    padding-right: 10px;
                    @media (min-width: @screen-sm-min) {
                        min-width: 110px;
                        max-width: 120px;
                    }
                    &:nth-child(1) {
                        padding-left: 0;
                    }
                    &:nth-child(4) {
                        padding-right: 0;
                    }
                }
            }
            .page_item--unreleased {
                pointer-events: none;
                .icon {
                    opacity: 0.3;
                }
            }
            .filtered {
                a {
                    color: @color-white;
                    .icon {
                        svg {
                            path, polygon, rect {
                                fill: @color-white;
                            }
                        }
                    }
                }
            }
        }
    }

    .content {
        padding-top: 40px;
        padding-bottom: 30px;
        @media (max-width: @screen-xs-max) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
        @media (min-width: @screen-sm-min) {
            flex: 50%;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }
        article {
            padding-left: 20px;
            padding-right: 20px;
            @media (min-width: @screen-sm-min) {
                padding-left: 20px;
                padding-right: 10px;
                flex-grow: 1;
            }
            p {
                .paragraph;
                color: @color-white;
                margin-bottom: 0;
                @media (max-width: @screen-xs-max) {
                    text-align: center;
                }
            }
        }
    }
}

.companies-content {

    .row {
        .container {
            padding-left: 20px;
            padding-right: 20px;
            @media (min-width: @screen-sm-min) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }

    ul.company-list {
        margin: 0;
        padding: 0;
        .make-row();
        list-style: none;
        padding-top: 20px;
        // .highlight {
        //     a.js-company-height {
        //         background: rgba(215,215,215,1);
        //         box-shadow: 1px 3px 3px 0px rgba(0,0,0,0.1);
        //         transition-property: background, box-shadow;
        //         transition-duration: .4s;
        //         h3 {
        //             color: @bruin-donkerst;
        //             transition-property: color;
        //             transition-duration: .4s;
        //         }
        //         .company__summary {
        //             color: @bruin-donkerst;
        //             transition-property: color;
        //             transition-duration: .4s;
        //         }

        //     }
        // }
        li.company {
            .make-xs-column(12);
            .make-sm-column(4);
            margin-bottom: 20px;


            .company__card {
                .make-xs-column(12);
                padding: 10px 20px;
                padding-bottom: 45px;
                text-decoration: none;
                //background: rgba(160,160,160,1);
                transition-property: background, box-shadow;
                transition-duration: .4s;
                h3 {
                    .index-small-title;
                    line-height: 1.2;
                    color: @color-white;
                    transition-property: color;
                    transition-duration: .4s;
                }
            }
            .company--global-seed-companies {
                background: @color-global;
            }
            .company--south-southeast-asia {
                background: @color-ssea-home;
            }
            .company--eastern-southern-africa {
                background: @color-esa-home;
            }
            .company--western-central-africa {
                background: @color-wca-home;
            }
            .company--latin-america {
                background: @color-la;
            }
            .company__summary {
                color: white;
                .sans-serif;
                font-size: 12px;
                line-height: 1.4;
                margin-top: 10px;
                transition-property: color;
                transition-duration: .4s;
                margin-bottom: 0;
            }
            .companyinfo__link {
                position: absolute;
                bottom: 15px;
            }
            &:hover, &:focus, &:active {
                color: @bruin-donkerst;
            }

            .relevant-datasets {
                list-style: none;
                padding: 0;
                margin: 0;
                display: block;
                position: absolute;
                right: 6px;
                bottom: 3px;
                .datadot {
                    display: inline-block;
                    position: relative;
                    padding: 2px 2px;
                    span {
                        width: 6px;
                        height: 6px;
                        border-radius: 100%;
                        display: block;
                        background: rgba(140,140,140,1);
                    }
                    .global {
                        background: @color-blue;
                    }
                    .regional {
                        background: #ffff00;
                    }
                    .wca {
                        background: @color-wca;
                    }
                    .esa {
                        background: @color-esa;
                    }
                    .ssea {
                        background: @color-ssea;
                    }
                    .la {
                        background: @color-la;
                    }

                    &:after {
                        content: '';

                        width: 1px;
                        height: 100%;
                        right: -2px;
                        top: 0;
                        margin-left: 0;
                        left: auto;

                        display: block;
                        background: rgb(140,140,140);

                        position: absolute;

                    }
                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }
            }


        }

    }



}



.companies-countries__lang {
    display: flex;
    align-items: flex-end;

    a {
        color: white !important;
        &:hover,&:active,&:focus {
            color: black !important;
        }
    }

    justify-content: center;
    @media (min-width: @screen-sm-min) {
        justify-content: flex-end;
        margin-top: 10px !important;
        padding-right: 10px;
        padding-bottom: 6px;
        align-self: flex-end !important;
    }
}