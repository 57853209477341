/* Typography */

.font-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.serif {
    font-family: @font-family-serif;
    .font-smoothing;
    
    font-size: 14px;
    line-height: 1.3;
}



.serif-small {
    .serif;
    font-size: 12px;
    line-height: 16px;
}



.sans-serif {
    font-family: @font-family-sans-serif;
    .font-smoothing;
   
    font-size: 13px;
    line-height: 16px;
   
}

.sans-serif-big {
    font-family: @font-family-sans-serif;
    .font-smoothing;
   
    font-size: 20px;
    line-height: 22px;
}

.sans-serif-small {
    font-family: @font-family-sans-serif;
    .font-smoothing;
   
    font-size: 11px;
    line-height: 13px;
}


.sans-serif-book {
    font-family:'AvenirLTStd-Book';
    font-weight: 400;
}

.sans-serif-medium {
    font-family:'AvenirLTStd-Medium';
    font-weight: 600;
}

.sans-serif-heavy {
    font-family:'AvenirLTStd-Heavy';
    font-weight: 600;
}

.index-small-title {
    .sans-serif-heavy;
    font-weight: normal;
    font-size: 16px;
    .font-smoothing;
    margin: 0;
    text-align: left;
    color: inherit;
}


.paragraph {
    .serif;
    
    font-size: 15px;
    line-height: 22px;
    
    &:not(:last-child) {
        margin-bottom: 22px;  
    }
    
   
    strong {
        font-weight: 700;
    }
    
    a {
        text-decoration: underline;
        color: @color-white;
        &:hover, &:active, &:focus {
            color: @color-white;
            text-decoration: none;
        }
    }
    
    
    
}








.h1 {
    .sans-serif;
    .sans-serif-heavy;
    color: @color-white;
    
    font-size: 33px;
    line-height: 40px;
    text-align: center;
    
	margin-bottom: 30px;
	margin-top: 0;
    padding: 30px 0px;
}

.h2 {

}

.h3 {
    .sans-serif;
    .sans-serif-heavy;
    color: @color-white;
    
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    
	margin: 0;
}


h1 {
    .h1;
}

h2 {
    .h2;
}

h3 {
    .h3;
}

h4.wysiwyg-h4{
    .paragraph;
    color: @bruin-donker;
    margin: 0;
    margin-bottom: 0;
    font-weight: 600;
    &:not(:last-child) {
        margin-bottom: 0;
    }
}