.wrapper-indicator {
    .container-fluid;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    padding-top: 40px;

    .backbutton__wrapper {
        margin-top: 80px;
    }
}

.container-indicator {
    .container-fluid;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1024px !important;
    position: relative;
    @media (min-width: @screen-lg) {
        .container;
        padding-left: 20px;
        padding-right: 20px;
        width: 1024px !important;
    }
}

.indicator__row {
    .make-row();
    margin-left: -20px;
    margin-right: -20px;
}

.indicator__col--full {
    .make-xs-column(12);
    padding-left: 20px;
    padding-right: 20px;
}

.indicator__col--mid {
    .make-xs-column(12);
    .make-sm-column(9);
    .make-sm-column-push(1.5);
    padding-left: 20px;
    padding-right: 20px;
}

.indicator__col--midsmall {
    .make-xs-column(12);
    .make-sm-column(8);
    .make-sm-column-push(2);
    padding-left: 20px;
    padding-right: 20px;
}

.indicator__col--wider {
    .make-xs-column(12);
    .make-sm-column(7);
    padding-left: 20px;
    padding-right: 20px;
}

.indicator__col--smaller {
    .make-xs-column(12);
    .make-sm-column(5);
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    @media (min-width: @screen-sm) {
        padding-bottom: 0px;
    }
}

.button--purple {
    background: @color-purple;
    &:hover,&:active,&:focus {
        background: darken(@color-purple, 10%);
    }
}
.button--rubine {
    background: @color-rubine;
    &:hover,&:active,&:focus {
        background: darken(@color-rubine, 10%);
    }
}
.button--yellow {
    background: @color-mustard;
    &:hover,&:active,&:focus {
        background: darken(@color-mustard, 10%);
    }
}
.button--pink {
    background: @color-pink;
    &:hover,&:active,&:focus {
        background: darken(@color-pink, 10%);
    }
}
.button--green {
    background: @color-green;
    &:hover,&:active,&:focus {
        background: darken(@color-green, 10%);
    }
}
.button--orange {
    background: @color-orange;
    &:hover,&:active,&:focus {
        background: darken(@color-orange, 10%);
    }
}
.button--brown {
    background: @color-brown;
    &:hover,&:active,&:focus {
        background: darken(@color-brown, 10%);
    }
}


.indicator__header {
    padding-top: 60px;
    .container-indicator {
        position: relative;
    }
    .header__logo {
        width: 100%;
        max-width: 220px;
        margin-bottom: 20px;
    }
    .indicator__header__title {
        margin: 0;
        padding: 0;
        .sans-serif-heavy;
        color: white;
        margin-bottom: 10px;
        font-size: 30px;
        line-height: 1.1;
        text-align: center;
        @media (min-width: @screen-sm) {
            font-size: 50px;
            text-align: center;
        }

    }
    .indicator__letter {
        left: 20px;
        top: 0;
        margin: 0;
        padding: 0;
        .sans-serif-heavy;
        color: @color-text;
        margin-bottom: 10px;
        font-size: 30px;
        line-height: 1.1;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        display: block;
        @media (min-width: @screen-sm) {
            position: absolute;
            font-size: 50px;
        }
    }

    .indicator__introduction {
        text-align: center;
        font-size: 15px;
        @media (min-width: @screen-sm) {
            font-size: 20px;
        }
        line-height: 1.4;
        margin-bottom: 10px;
        p {
            margin-bottom: 20px;
            @media (min-width: @screen-sm) {
                margin-bottom: 25px;
            }
            &:last-child {
                margin: 0;
            }
        }
    }
}


.indicator__wysiwyg {
    padding-bottom: 20px;
    strong, b, h3 {
        margin: 0;
        .sans-serif-heavy;
        font-size: 18px;
        line-height: 1.2;
        text-align: left;
        color: @color-text;
        display: block;
        margin-bottom: 3px;
    }
    .serif;
    font-size: 15px;
    line-height: 1.4;
    p {
        margin-bottom: 20px;
    }

    @media (min-width: @screen-sm) {
        padding-right: 30px;
    }

}

@media (min-width: @screen-sm) {
    .indicator__article-column {
        padding-left: 30px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: 100%;
            background: white;
        }
    }
}


.indicator__article {
    margin-bottom: 20px;
    .serif;
    font-size: 15px;
    line-height: 1.4;
    h5, h6 {
        margin: 0;
        .sans-serif-heavy;
        font-size: 18px;
        line-height: 1.2;
        text-align: left;
        color: @color-text;
        display: block;
        margin-bottom: 3px;
    }
    h6 {
        color: white;
    }

    p {
        margin-bottom: 3px;
    }

    @media (min-width: @screen-sm) {
        h5, h6, p {
            position: relative;
            padding-left: 80px;
        }
        span {
            position: absolute;
            left: 0;
        }
    }
}