
h1.search-header {
    padding-top: 30px;
    padding-bottom: 30px;
    @media (min-width: @screen-sm-min) {
        font-size: 50px;
        line-height: 22px;
        padding-top: 75px;
        padding-bottom: 100px;
    }
    text-align: center;
    margin: 0;
}


form.search-input-holder {
    background: @color-white;
    
    .sans-serif-book;
    display: inline-block;
    position: relative;
    padding: 10px 0px;
    text-align: left;
    padding-left: 15px;
    line-height: 1.5;
    input {
        border: none;
        padding: 0;
        border-radius: 0px;
        color: @bruin-donkerst;
        text-align: left;
        width: 175px;
        margin-right: 5px;
        appearance: textfield;
        
        &:focus, &:active {
            outline: 0;
        }
        
    }
    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color:    @bruin-donkerst;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
       color:    @bruin-donkerst;
       opacity:  1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
       color:    @bruin-donkerst;
       opacity:  1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
       color:    @bruin-donkerst;
    }
    :placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
      color:    @bruin-donkerst;
    }
    
    button {
        float: right;
        display: block;
        background: none;
        border: none;
        border-radius: 0px;
        border-left: 2px solid rgba(160,160,160,1);
        color: rgba(170,170,170,1);
        padding: 0px 20px;
        &:hover,&:focus,&:active {
            color: @bruin-donkerst !important;
            outline: 0;
        }
    }
}

.search-input-column {
    .make-xs-column(12);
    text-align: center;
    padding-top: 0px;
    form.search-input-holder {
        input {
            @media (min-width: @screen-sm-min) {
                width: 300px;
            }
        }
    }
}



h2.results-heading {
    .sans-serif-book;
    .make-xs-column(12);
    padding: 0;
    font-size: 14px;
    span {
        .sans-serif-heavy;
    }
}

h2.results-heading.no-results {
    text-align: center;
}


.search-results-column {
    .make-xs-column(12);
    padding-top: 50px;   
    padding-bottom: 200px;
}


.search-result {
    .make-xs-column(12);
    border-top: 2px solid @color-white;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    .font-smoothing;
    .row {
        .make-row();
        overflow: hidden;
    }
    a {
        color: inherit;
        text-decoration: none;
        transition-property: color;
        transition-duration: .4s;
        &:hover, &:focus, &:active {
            color: @color-white !important;
            transition-property: color;
            transition-duration: .4s;
        }
    }
    a.page-link {
        h4 {
            transition-property: color;
            transition-duration: .4s;
        }
        &:hover, &:focus, &:active {
            h4 {
                color: @bruin-donkerst !important;
                transition-property: color;
                transition-duration: .4s;
            }
        }
    }
    aside {
        .make-xs-column(12);
        .make-sm-column(4);
        padding-left: 0;
        h4 {
            color: @color-white;
            .sans-serif-heavy;
            .font-smoothing;
            padding: 0;
            margin: 0;
            line-height: 1.2;
            margin-bottom: 10px;
        }
    }
    main {
        .make-xs-column(12);
        .make-sm-column(8);
        text-align: left;
        padding-left: 0;
        h3 {
            color: inherit;
            text-align: left;
            line-height: 1.2;
            margin-bottom: 3px;
        }
    }
}

// Search Dropdown

.search-input-wrapper {
    display: block;
    z-index: 9999;
    form.search-input-holder {
        position: fixed;
        z-index: 9999;
        top: 50px;
        width: 300px;
        right: 10px;
        @media (min-width: @screen-sm-min) {
            right: 80px;
        }
        display: none;
    }
    form.bar-visible {
        display: block;
    }
}
