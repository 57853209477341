.title-blog {
	.h1;
	margin: 0;
	padding: 40px 0px;
}


.sticker {
	cursor: pointer;
	color: rgba(170,170,170,1);
	transition-property: color;
	transition-duration: .4s;
	&:hover, &:focus, &:active {
		color: @color-white;
		transition-property: color;
		transition-duration: .4s;
	}
	
}

.active {
	color: @color-white !important;
	transition-property: color;
	transition-duration: .4s;
}

.article-thumb {
	
	margin-bottom: 40px;
	
	.article-link {
		color: inherit;
		display: inline-block;
		&:hover, &:focus, &:active {
			color: inherit;
			text-decoration: none;
			
			.article-title, .published, p {
				color: @color-white !important;
				transition-property: color;
				transition-duration: .4s;
			}
		}
	}
	img {
		width: 100%;
		height: auto;
		margin-bottom: 15px;
	}
	.article-title {
		text-align: left;
		color: @bruin-donker;
		transition-property: color;
		transition-duration: .4s;
		.serif;
		font-size: 20px;
		font-weight: normal;
		margin-bottom: 5px;
	}
	.published {
		.sans-serif;
		display: block;
		margin-bottom: 10px;
		color: @grijs-donkerst;
		transition-property: color;
		transition-duration: .4s;
	}
	p {
		.serif-small;
		transition-property: color;
		transition-duration: .4s;
	}
	.read-more {
		.sans-serif;
		color: @color-white;
	}
	
}

.news-article {
	.make-xs-column(12);
	.make-sm-column(4);
}

.media-article {
	.make-xs-column(6);
	.make-sm-column(3);
	
	.article-title {
		.serif;
	}
	
	.published {
		.sans-serif;
		display: block;
		margin-bottom: 0px;
		color: rgba(170,170,170,1) !important;
	}
	
}


.show-more {
	.h2;
	display: block;
	text-align: center;
    font-size: 18px;
    line-height: 1.11;
    padding-bottom: 43px;
    padding-top: 0px;
	color: rgba(170,170,170,1);
	transition-property: color;
	transition-duration: .4s;
	&:hover, &:focus, &:active {
		color: @color-white;
		text-decoration: none;
	}
}

.category {
	.news-wrapper {
		.container {
			padding-bottom: 100px;
		}
	}
}


.blog {
	.news-wrapper.bg-noise-gray {
		@media (min-width: @screen-sm-min) {
			position: relative;
			overflow: visible;
			&:after {
				background: url(../img/noise_gray.png) repeat !important;
				content: '';
				bottom: -100px;
				left: 0;
				display: block;
				width: 100%;
				position: absolute;
				height: 100px;
			}
		}
	}
}

/*
.title-in-the-media {
	font-size: 18px;
	color: #fff;
	line-height: 1.2;
}

.wrapper-blog {
	width: 100%;
	display: table;
	table-layout: fixed;
	//height: 100%;
	//min-height: 100%;

	.left, .right, .middle {
		display: table-cell;
		background-repeat: repeat;
	}

	.left {
		background-image: url(../img/noise_gray.png);
		background-position: right center;
	}

	.right {
		background-image: url(../img/noise_gray_dark.png);
		background-position: left center;
	}

	.middle {
		width: 1024px;
		height: 100%;
		background: url(../img/noise_gray.png) repeat;
		overflow: hidden;
	}

	.container-middle {
		display: table;
	}

	.wrapper-news, .wrapper-media {
		//float: left;
		height: 100%;
		display: table-cell;
	}

	.wrapper-news {
		width: 704px;
		padding-left: 118px;
		padding-right: 40px;
	}

	.wrapper-media {
		width: 320px;
		padding-left: 20px;
		background-image: url(../img/noise_gray_dark.png);
		background-position: left center;
		background-repeat: repeat;
	}
}

@media (max-width: 1024px) {
	.wrapper-blog {
		.middle {
			width: 100%;
		}

		.container-middle {
			display: block;
		}

		.wrapper-news {
			padding: 0;
			width: 100%;
			display: block;
			padding-left: 15px;
			padding-right: 15px;
		}

		.wrapper-media {
			padding: 0;
			width: 100%;
			display: block;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 10px;
		}
	}
}

*/






.news-left {
    .make-sm-column(8);
    .article-thumb {
        .make-sm-column(6);
    }
}

.blog-right {
    .make-sm-column(4);
    padding-right: 0;
    .article-thumb {
        .make-xs-column(12);
        .make-sm-column(9.4);
        .make-sm-column-push(2.6);
        margin-bottom: 20px;
        .article-line {
            width: 100%;
            height: 2px;
            position: relative;
            background: white;
            display: block;
            margin-top: 20px;
        }
        &:last-child {
            .article-line {
                display: none;
            }
        }
        .published {
            color: #8c8c8c !important;
        }
        
    }
    .js-news-article-height {
        margin-bottom: 20px;
    }
    
    
    
    
}