.leaders {
	width: 100%;
	max-width: 788px;
	margin: 0 auto;
}

.leader-quote {
	overflow: hidden;
	margin-bottom: 40px;

	.image, .text {
		float: left;
		width: 50%;
	}

	.text {
		padding-left: 10px;
		text-align: center;
		display: table;
	}

	.image {
		padding-right: 30px;
		
	}

	blockquote {
		display: table-cell;
		vertical-align: middle;
		padding: 0;
		margin: 8px 0 0 0;
		border: none;
		text-align: center;
		font-size: 15px;
		color: @color-white;
		//padding-top: 20px;

		p {
			.paragraph;
			font-size: 15px;
			line-height: 1.33;
		}

		footer {
			.sans-serif-small;
			margin: 0;
			color: @bruin-donker;
			padding: 0px 20px;
			width: 100%;
			//max-width: 230px;

			&:before {
				display: none;
			}
		}
	}
}

@media (max-width: 788px) {
	.leaders {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (max-width: @screen-xs-max) {
	.leader-quote {
		.image, .text {
			float: none;
			width: 100%;
		}

		.image {
			padding: 0;
		}

		.text {
			padding: 0;
		}

		img {
			width: 100%;
		}
	}
}