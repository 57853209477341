.publication {
	overflow: hidden;
	border-bottom: 2px solid rgb(170, 170, 170);
	padding: 20px 0;

	a {
		color: @text-color;
		transition-property: color;
		transition-duration: .4s;
		&:hover, &:focus, &:active {
			text-decoration: none;
			color: @color-white;
			transition-property: color;
			transition-duration: .4s;
			.media-object {
				box-shadow: 5px 4px 10px 1px rgba(0,0,0,0.3);
				margin-bottom: 10px;
				transition-property: box-shadow;
				transition-duration: .4s;
			}
			.media-body {
				text-shadow: @shadow;
				transition-property: text-shadow;
				transition-duration: .4s;
			}
		}
	}

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		border-bottom: none;
	}

	.media {
		display: table;
		width: 100%;
		table-layout: fixed;
	}

	.media-left {
		width: 208px;
		padding: 0;
		padding-right: 70px;
		position: relative;
		img {
			max-width: 100%;
			width: 100%;
			height: auto;
		}
	}

	.media-object { 
		box-shadow: 5px 4px 10px 1px rgba(0,0,0,0.15);
		margin-bottom: 10px;
		transition-property: box-shadow;
		transition-duration: .4s;
	}

	.media-body {
		overflow: visible;
		position: relative;
		width: auto;
		transition-property: text-shadow;
		transition-duration: .4s;
		&:before {
			content: url(../../img/arrow_list_white.svg);
			position: absolute;
			left: -30px;
			top: 0px;
		}
	}

	.media-heading {
		.h3;
		text-align: left;
	}

	.published {
		.sans-serif;
		color: @color-white;
	}

	p {
		.paragraph;
		font-weight: 300;
	}

	&.publication-image {

	}

	&.publication-methodology {
		.media-left {
			width: 141px;
		}

		.media-body {
			padding-right: 98px;

			&:before {
				display: none;
			}
		}

		p {
			.paragraph;
			color: @color-white;
		}
	}
}

@media (max-width: 486px) {
	.publication {
		.media {
			display: block;
		}

		.media-left {
			display: block;
			width: auto;
		}

		.media-object {
			padding-right: 0;
			.img-responsive;
			width: 100%;
			margin-bottom: 10px;
		}

		.media-body {
			display: block;
		}
	}
}