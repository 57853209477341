/* Mixins */




@shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);

.image-shadow {
    box-shadow: 5px 4px 10px 1px rgba(0,0,0,0.3);
    margin-bottom: 10px;
    transition-property: box-shadow;
    transition-duration: .4s;
}


.logo {
	margin: 0 auto 104px auto;
    filter: drop-shadow(@shadow);
}

.download-arrow {
    padding: 15px 0 15px 30px;
	position: relative;
    &:after {
		content: url(../img/arrow_list_white.svg);
		position: absolute;
		left: 0;
		top: 15px;
	}
}


.download-arrow-yellow {
    padding: 15px 0 15px 30px;
	position: relative;
    &:after {
		content: url(../img/arrow_list_yellow.svg);
		position: absolute;
		left: 0;
		top: 15px;
	}
}



.download-arrow-grey {
    padding: 15px 0 15px 30px;
	position: relative;
    &:after {
		content: url(../img/arrow_list_grey.svg);
		position: absolute;
		left: 0;
		top: 15px;
	}
}



.img--objectfit{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    font-family: 'object-fit: cover;';
}

.img--objectfit--contain{
	object-fit: contain;
	object-position: center center;
}