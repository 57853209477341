


.contact-wrapper {
	.row {
		h1 {
			margin-top: 20px;
			margin-bottom: 0px;
		}
		.container {
			
			.row {
				
				.column-1 {
					.make-sm-column(7);
					.paragraph;
					color: @color-white-dark;
					font-weight: 300;
					
					padding-top: 30px;
					margin-bottom: 120px;
					
					a, strong {
						color: @color-white;
					}
					
				}
				.column-2 {
					.make-sm-column(5);
					text-align: center;
					.logo-container {
						display: inline-block;
						float: right;
						position: relative;
						@media (max-width: @screen-xs-max) {
							text-align: center;
							float: none;
							margin-bottom: 100px;
						}
						img {

							position: relative;
							margin: 0;
							margin-bottom: 20px;
							
						}
						&:after {
							content: '';
							position: absolute;
							width: 2px;
							height: 1000px;
							bottom: -1000px;
							background: @geel-licht;
							display: block;
							left: 50%;
							margin-left: -1px;
							margin-top: 20px;
							@media (max-width: @screen-xs-max) {
								//display: none;
							}
						}
					}
				}
			}
		}
	}
}




.contact-sub-wrapper {
	.section-title {
		.h1;
		padding-top: 50px;
	}
	.container {
		padding-bottom: 70px;
		padding-top: 20px;
	}
	
	h3 {
		.sans-serif;
		.sans-serif-heavy;
		text-align: left;
	}
	.half-column {
		.sans-serif;
	}
}



#map {
	height:320px;
	width:100%;
}



.credits-row {
	.section-title {
		margin: 0;
	}
	.sans-serif;
	a {
		color: inherit;
		text-decoration: none;
		&:hover,&:focus,&:active {
			color: @color-white;
			text-decoration: none;
		}
	}
}