.modal-outer {
    background-color: fade(#000, 60%);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    opacity: 1;
    pointer-events: all;
    transition: opacity 300ms;
    cursor: pointer;
    @media (min-width: @screen-md-min) {
        align-items: center;
    }


    pointer-events: none;
    opacity: 0;
    transition: opacity .3s linear;
}

.body--wba-popup {
    .modal-outer {
        display: flex;
        pointer-events: auto;
        opacity: 1;
    }
}

.modal-outer.close {
    opacity: 0;
    pointer-events: none;
}

.modal-inner {
    width: 100%;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
    background-color: white;
    display: flex;
    cursor: default;
    position: relative;
    margin: 0 20px 50px;
    @media (min-width: @screen-md-min) {
        width: 840px;
        height: 532px;
        margin: 0;
    }
}


.btn-modal {
    border: none;
    display: inline-block;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9999;
}

.btn-modal__inner {
	position: relative;
	padding: 10px;
	width: 35px;
    height: 35px;
    .horizontal {
        position: absolute;
        background-color: #0D2745;
        width: 15px;
        height: 1px;
        left: 10px;
        top: 18px;
        transform: rotate(45deg);
    }
    .vertical {
        position: absolute;
        background-color: #0D2745;
        width: 1px;
        height: 15px;
        left: 17px;
        top: 11px;
        transform: rotate(45deg);
    }
}

.modal-inner__img {
    display: none;
    @media (min-width: @screen-md-min) {
        display: block;
        width: 50%;
        height: 100%;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.modal-inner__content {
    width: 100%;
    height: 100%;
    padding: 50px 20px 35px;
    color: #0D2745;
    font-family: @font-family-sans-serif;
    @media (min-width: @screen-md-min) {
        width: 50%;
        padding: 75px 50px 50px;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    h2 {
        font-size: 28px;
        margin-bottom: 25px;
    }
    p {
        font-size: 16px;
        margin-bottom: 50px;
    }
}

.button--popup {
    font-size: 16px;
    background: #0d2745;
    color: white;
    display: flex;
    align-items: center;
    border: 0;
    line-height: 1.1;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    appearance: none;
    font-family: @font-family-sans-serif;
    font-weight: 400;
    display: inline-block;
    padding: 19px 30px;
    line-height: 1;
    border-radius: 27px;
    transition: transform .2s,background .2s,opacity .2s;
    margin-bottom: 25px;
    &:hover {
        opacity: 0.9;
        text-decoration: none;
        color: white;
    }
    svg {
        width: 14px;
        height: 10px;
        margin-left: 10px;
    }
}

.link--popup {
    display: flex;
    align-items: center;
    font-family: @font-family-sans-serif;
    font-weight: 600;
    color: #1A1919;
    font-size: 16px;
    svg {
        width: 14px;
        height: 10px;
        margin-left: 10px;
    }
    &:hover {
        opacity: 0.9;
        text-decoration: none;
        svg {
            opacity: 0.9;
        }
    }
}