.raster{
	font-family: @font-family-sans-serif;
	color: @color-white;
	font-weight: 700;
}

.raster--categories{
	.raster__row{
		margin-bottom: 25px;
	}
	.raster__row--media{
		margin-bottom: 0;
	}
	.raster__column{
		border-bottom: 0;
		border-left: 0;
		border-right: 0;
	}
	.raster__column {
		&:nth-child(4n+1){
			border-right: 1px solid @grijs-inactive;
			border-bottom: 1px solid @grijs-inactive;
		}
		&:nth-child(4n+2){
			border-left: 1px solid @grijs-inactive;
			border-bottom: 1px solid @grijs-inactive;
		}
		&:nth-child(4n+3){
			border-right: 1px solid @grijs-inactive;
			border-top: 1px solid @grijs-inactive;
		}
		&:nth-child(4n+4){
			border-left: 1px solid @grijs-inactive;
			border-top: 1px solid @grijs-inactive;
		}
	}
	span.show-all{
		color: @grijs-licht;
	}
	.raster__header a{
		&:hover, &:focus, &:active {
			color: #fff;
		}
	}
	@media (min-width: @screen-sm-min) {
		.raster__row{
			margin-bottom: 25px;
		}
		.raster__row--media{
			margin-bottom: 0;
		}
		.raster__column {
			&:nth-child(4n+1){
				border-bottom: 0;
			}
			&:nth-child(4n+2){
				border-right: 1px solid @grijs-inactive;
				border-bottom: 0;
			}
			&:nth-child(4n+3){
				border-left: 1px solid @grijs-inactive;
				border-top: 0;
			}
			&:nth-child(4n+4){
				border-top: 0;
			}
		}
	}
}

.raster--archive{
	.raster__row{
		margin-bottom: 0;
	}
	
	span.show-all svg{
		transform: rotate(90deg);
		margin-top: -3px;
	}
	@media (min-width: @screen-sm-min) {
		.raster__column {
			margin-bottom: 10px;
			&:nth-last-child(-n+4){
				margin-bottom: 0;
			}
		}
	}
}

.raster__row, .raster__columswrapper{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	&:last-child{
		.raster__column:last-child{
			border-bottom: 0;
		}
	}
	@media (min-width: @screen-sm-min) {
		&:last-child{
			.raster__column{
				border-bottom: 0;
			}
		}
	}
}

.raster__header.raster__header--media, .category-in-the-media .raster__header{
	.bg-noise-blue;
	// background-color: @color-blue-dark;
	span.show-all{
		color: @grijs-licht;
	}
}


.raster__row--media{
	.bg-noise-blue;
	background-color: @color-blue-dark;
	padding-bottom: 30px;
	.raster__column{
		width: 50%;
		margin-bottom: 0;
		padding: 5px 30px;
		border: 0!important;
	}
	.raster__header{
		.bg-noise-blue;
		background-color: @color-blue-dark;
	}
	.raster__img-wrapper{
		height: 35px;
	}
	span.show-all{
		color: @grijs-licht;
	}
	@media (min-width: @screen-sm-min) {
		.raster__column{
			width: 25%;
			padding: 0px 40px;
			// border: 0!important;
		}
		.raster__img-wrapper{
			height: 80px;
		}
	}
}

.raster__column--dummy{
	.raster__column;
	.raster__column--sm;
	border: 0!important;
}

.raster__column{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	border-bottom: 2px solid @grijs-inactive;
	.raster__header--sm, .raster__column-inner--sm{
		&:nth-child(3n+2){
			border-right: 1px solid @grijs-inactive;
		}
		&:nth-child(3n+3){
			border-left: 1px solid @grijs-inactive;
		}
	}
	@media (min-width: @screen-sm-min) {
		width: 50%;
		&:first-child{
			border-right: 1px solid @grijs-inactive;
		}
		&:last-child{
			border-left: 1px solid @grijs-inactive;
		}
	}
}



.raster__column--sm{
	width: 50%;
	@media (min-width: @screen-sm-min) {
		width: 25%;
	}
}

.raster__headers{
	width: 100%;
	.raster__header{
		&:first-child{
			border-right: 1px solid @grijs-inactive;
		}
		&:last-child{
			border-left: 1px solid @grijs-inactive;
		}
	}
}

.raster__header{
	.bg-noise-gray-dark;
	background-color: @grijs-donkerst;
	padding: 8px 10px;
	font-size: 14px;
	width: 100%;
	a{
		display: inline-block;
	}
	@media (min-width: @screen-sm-min) {
		padding: 10px 15px;
		font-size: 18px;
	}
}

.raster__header.raster__header--sm{
	width: 50%;
	display: inline-block;
	float: left;
}

.raster__column, .raster__column-inner, .raster__header{
	a{
		color: @color-white;
		height: 100%;
		width: 100%;
		transition-property: color;
		transition-duration: .4s;
		&:hover, &:focus, &:active {
			text-decoration: none;
			color: @geel-licht;
		}
	}
}

.raster__header .link-more{
	span.show-all{
		color: @grijs-donker;
		transition-property: color, fill;
		transition-duration: .4s;
		float: right;
		min-width: 150px;
		text-align: right;
		svg{
			margin-right: 5px;
			vertical-align: middle;
			height: auto;
			width: 14px;
		}
		polygon{
			transition-property: color, fill;
			transition-duration: .4s;
		}
	}
	&:hover, &:focus, &:active{
		span.show-all{
			color: #fff;
			text-decoration: none;
			polygon{
				fill: #fff;
			}
		}
	}
	@media (min-width: @screen-sm-min) {
		span.show-all{
			svg{
				margin-right: 8px;
				width: 18px;
			}
		}
	}
}

.raster__column, .raster__column-inner, .raster__header.raster__header--swiper{
	a{
		width: auto;
	}
}

.raster__column-inner, .raster__header{
	width: 100%;
}

.raster__column-inner--sm{
	width: 50%;
}

.raster__img-wrapper{
	text-align: center;
	width: 100%;
	height: 133px;
	@media (min-width: @screen-sm-min) {
		height: 180px;
	}
}

// .category-in-the-media .raster__img-wrapper{
// 	// height: 133px;
// 	.bg-noise-blue;
// 	background-color: @color-blue-dark;
// 	img{
// 		.img--objectfit;
// 		.img--objectfit--contain;
// 		padding: 15px;
// 	}
// 	@media (min-width: @screen-sm-min) {
// 		height: 110px;
// 	}
// }

.raster__img-wrapper.raster__img-wrapper--lg{
	height: 207px;
	@media (min-width: @screen-sm-min) {
		height: 280px;
	}
}

.raster__title-wrapper{
	.bg-noise-gray-dark;
	text-align: center;
	height: 75px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
	font-size: 11px;
	line-height: 1.3;
	// letter-spacing: 0.8px;
	background-color: @grijs-donkerst;

	overflow: hidden;
	@media (min-width: @screen-sm-min) {
		height: 100px;
		padding: 20px;
		font-size: 16px;
	}
}


