.excerpt-news {
	padding: 22px 0;
	border-bottom: 1px solid rgb(160, 160, 160);

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		border-bottom: none;
	}

	.media {
		display: table;
		width: 100%;
		table-layout: fixed;
	}

	.media-left {
		width: 303px;
		padding: 0;
	}

	.media-body {
		width: auto;
	}

	.media-heading {
		font-family: @font-family-sans-serif;
		font-size: 20px;
		line-height: 1.2;
		margin-bottom: 0px;
		font-weight: 400;
	}

	.published {
		font-family: 'AvenirLTStd-Book', @font-family-sans-serif;
		font-size: 13px;
		line-height: 1.69;
		color: @grijs-donkerst;
		margin-bottom: 8px;
		display: inline-block;
	}

	p {
		font-family: @font-family-sans-serif;
		font-size: 12px;
		line-height: 1.41;
		color: @bruin-donker;
		margin-bottom: 8px;
	}

	.more {
		color: #fff;
		font-family: 'AvenirLTStd-Book', @font-family-sans-serif;
		font-size: 13px;
		line-height: 1.69;
	}
}

@media (max-width: 520px) {
	.excerpt-news {
		.media-left, .media-body {
			display: block;
		}

		.media-object {
			.img-responsive;
			width: 100%;
		}

		.media-left {
			width: 100%;
			margin-bottom: 10px;
		}

		img {
			.img-responsive;
			width: 100%;
		}
	}

	.excerpt-news.excerpt-media {
		max-width: 100%;
		padding: 10px 0;
		margin-bottom: 10px;
	}
}