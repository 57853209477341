// Mixins
.arrowbutton {
    height: 20px;
    background: #d6d6d6;
    display: inline-flex;
    position: relative;
    white-space: nowrap;
    margin-left: 20px;
    .sans-serif-heavy;
    font-size: 12px;
    line-height: 20px;
    color: white;
    align-self: flex-start;
    padding-left: 5px;
    padding-right: 10px;
    .font-smoothing;
    svg, img {
        height: 20px;
        width: auto;
        position: absolute;
        left: -20px;
        top: 0;
        path {
            fill: #d6d6d6;
        }
    }
    // &:hover,&:focus,&:active {
    //     color: white;
    //     text-decoration: none;
    //     background: @color-blue;
    //     svg, img {
    //         path {
    //             fill: @color-blue;
    //         }
    //     }
    // }
}

.arrowbutton--ssea {
    &:hover,&:focus,&:active {
        color: white;
        text-decoration: none;
        background: @color-ssea;
        svg, img {
            path {
                fill: @color-ssea;
            }
        }
    }
}
.arrowbutton--global {
    &:hover,&:focus,&:active {
        color: white;
        text-decoration: none;
        background: @color-global;
        svg, img {
            path {
                fill: @color-global;
            }
        }
    }
}
.arrowbutton--esa {
    &:hover,&:focus,&:active {
        color: white;
        text-decoration: none;
        background: @color-esa;
        svg, img {
            path {
                fill: @color-esa;
            }
        }
    }
}
.arrowbutton--wca {
    &:hover,&:focus,&:active {
        color: white;
        text-decoration: none;
        background: @color-wca;
        svg, img {
            path {
                fill: @color-wca;
            }
        }
    }
}


// Intro Paragraph
.index-template-index-longread {
    .longread__header--paragraph {
        background: rgb(230,230,230);
    }

}



// Ranking
.longread__ranking {
    .longread__row--flexbox {
        display: flex;
        flex-direction: column;
        @media (min-width: @screen-sm) {
            flex-direction: row;
        }
    }

    .ranking__figure {
        @media (max-width: @screen-xs-max) {
            border-bottom: 2px solid @color-ssea;
        }
    }
}

.ranking__figure {
    .make-xs-column(12);
    padding: 0;
    @media (min-width: @screen-sm) {
        flex: 0 0 500px;
    }
    .stacked-figure {
        width: 100%;
    }
    @media (max-width: @screen-xs-max) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        .content {
            padding-left: 20px;
            padding-right: 5px;
            padding-bottom: 40px;
            overflow: auto;
        }
        .stacked-figure {
            //min-width: 480px;
        }
    }

    .figure__footnote {
        .sans-serif;
        font-size: 11px;
        line-height: 16px;
        -webkit-font-smoothing: subpixel-antialiased;
        // @media (min-width: @scorecard-breakpoint) {
        //     margin-left: 115px;
        // }
        margin-top: 30px;
        padding-right: 35px;
        margin-bottom: 0;
        color: black;
        a {
            color: inherit;
            text-decoration: underline;
            &:hover,&:active,&:focus {
                text-decoration: none;
            }
        }
    }
}

.ranking__aside {
    .make-xs-column(12);
    @media (min-width: @screen-sm) {
        flex-basis: auto;
        width: auto;
        padding-left: 0;
    }
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    .ranking__text {
        @media (min-width: @screen-sm) {
            padding-left: 40px;
            border-left: 2px solid @color-ssea;
        }
        .serif;
        font-size: 15px;
        p {
            margin-bottom: 20px;
        }
    }
}



// Key Findings

.keyfinding__row {
    display: flex;
    flex-direction: column;
    margin: 0;
    @media (min-width: @screen-sm) {
        flex-direction: row;
    }
    padding: 30px 0px;
    border-top: 2px solid white;
    &:first-child {
        border-top: 0;
    }
}

.keyfinding__col {
    width: 100%;
    .serif;
    h4 {
        .sans-serif-heavy;
        color: white;
        font-size: 22px;
        line-height: 1.2;
        margin: 0;
    }
    h5 {
        .sans-serif-book;
        color: white;
        font-size: 22px;
        line-height: 1.2;
        margin: 0;
        margin-bottom: 10px;
    }
    .lead {
        .sans-serif-heavy;
        color: #b1656d;
        font-size: 22px;
        line-height: 1.2;
        margin-bottom: 20px;
    }
    line-height: 1.4;
    font-size: 15px;
    p {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.keyfinding__col--ssea {
    .lead {
        color: #b1656d;
    }
}
.keyfinding__col--global {
    .lead {
        color: darken(@color-global, 10%);
    }
}
.keyfinding__col--esa {
    .lead {
        color: @color-esadark;
    }
}
.keyfinding__col--wca {
    .lead {
        color: darken(@color-wca, 10%);
    }
}



.keyfinding__col--small {
    margin-bottom: 30px;
    @media (min-width: @screen-sm) {
        width: 35%;
        margin-bottom: 0;
    }

}

.keyfinding__col--wide {
    @media (min-width: @screen-sm) {
        width: 65%;
        padding-left: 40px;
    }
    img {
        width: 100%;
        height: auto;
    }
}


// Measurement Areas
.measurement-area--index {
    .measurement-area__header {
        @media (min-width: @screen-sm) {
            .longread__col--wide {
                width: calc(~"50% + 90px");
                padding-right: 35px;
            }
            .longread__col--small {
                width: calc(~"50% - 90px");
                padding-left: 0;
            }
        }
        @media (min-width: @screen-md) {
            .longread__col--wide {
                width: calc(~"50% + 110px");
                padding-right: 35px;
            }
            .longread__col--small {
                width: calc(~"50% - 110px");
            }
        }
    }
}

.index__figure-sidebar {
    .container-longread--figure {
        padding-bottom: 50px;
        .index-template-index-ranking & {
            padding-top: 20px;
        }
    }
    .measurement-area__image--mobile {
        margin-top: 0;
    }
    .longread__row {
        display: flex;
        flex-direction: column;
        @media (min-width: @screen-sm) {
            flex-direction: row;
        }
    }
    .longread__col--figure {
        @media (max-width: @screen-xs-max) {
            padding: 0;
        }
        @media (min-width: @screen-sm) {
            width: calc(~"50% + 35px");
            padding-right: 0;
        }
        @media (min-width: @screen-md) {
            width: calc(~"50% + 35px");
        }
    }
    .longread__col--figureaside {
        @media (max-width: @screen-xs-max) {
            padding-top: 40px;
        }
        @media (min-width: @screen-sm) {
            width: calc(~"50% - 35px");
            padding-left: 55px;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                width: 2px;
                height: 100%;
                left: 15px;
                background: @color-white;
                display: block;
                top: 0;
            }
        }
        @media (min-width: @screen-md) {
            width: calc(~"50% - 35px");
            padding-left: 75px;
        }
        .measurement-area__column__title {
            color: @color-text;
            font-size: 18px;
        }
        .measurement-area__column__text {
            font-size: 15px;
            margin-bottom: 30px;
            p {
                font-size: 15px;
            }
        }
    }
    .column--ssea {
        &:before {
            background: @color-ssea;
        }
    }
    .column--global {
        &:before {
            background: @color-global;
        }
    }
    .column--a {
        &:before {
            background: @color-purple;
        }
    }
    .column--b {
        &:before {
            background: @color-rubine;
        }
    }
    .column--c {
        &:before {
            background: @color-mustard;
        }
    }
    .column--d {
        &:before {
            background: @color-pink;
        }
    }
    .column--e {
        &:before {
            background: @color-green;
        }
    }
    .column--f {
        &:before {
            background: @color-orange;
        }
    }
    .column--g {
        &:before {
            background: @color-brown;
        }
    }
}

// Tooltip
.ma_image {
    position: relative;
    .popper-button {
        position: absolute;
        bottom: 10px;
        left: 10px;
        z-index: 99;
        cursor: pointer;
        display: block;
        padding: 10px;
        width: 45px;
        height: 45px;
        svg {
            width: 25px;
            height: 25px;
            min-height: 25px;
            filter: drop-shadow( -1px -1px 4px #555 );
        }
        transition: opacity .5s linear 0s;
        &:hover, &:active,&:focus {
            opacity: 0.85;
            transition: opacity .5s linear 0s;
        }
    }
}

.tooltip {
    background: white;
    color: @color-text;
    padding: 10px;
    .sans-serif-heavy;
    .font-smoothing;
    font-size: 14px;
    line-height: 1.4;
    z-index: 9999;
    max-width: 300px;
}




// Company Scorecards Archive
.scorecards-archive {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 40px;
}

.scorecard {
    flex-basis: 100%;
    @media (min-width: @screen-sm) {
        flex-basis: 50%;
        padding-right: 30px;
    }
    @media (min-width: @screen-md) {
        flex-basis: 33.3333%;
    }
    border-top: 2px solid white;
    padding-top: 20px;
    padding-bottom: 20px;
    &:nth-child(1) {
        border-top: 0;
    }
    @media (min-width: @screen-sm) {
        &:nth-child(2) {
            border-top: 0;
        }
    }
     @media (min-width: @screen-md) {
        &:nth-child(3) {
            border-top: 0;
        }
    }
}

.scorecard__header {
    display: flex;
    margin-bottom: 15px;
}

.scorecard__ranking {
    background-color: @color-blue;
    position: relative;
    width: 36px;
    height: 36px;
    padding: 4px;
    display: inline-block;
    span {
        background: @color-white !important;
        display: block;
        border-radius: 100%;
        width: 28px;
        height: 28px;
        text-align: center;
        position: relative;
        line-height: 28px;
        .sans-serif-heavy;
        font-weight: normal;
        letter-spacing: -1px;
        font-size: 18px;
        .font-smoothing;
    }
}
.scorecard__ranking--ssea {
    background-color: @color-ssea;
}

.scorecard__ranking--esa {
    background-color: @color-esa;
}
.scorecard__ranking--wca {
    background-color: @color-wca;
}

.scorecard__name {
    .sans-serif-heavy;
    font-size: 18px;
    margin: 0;
    margin-left: 15px;
}

.scorecard__summary {
    color: white;
    .sans-serif-heavy;
    font-size: 14px;
    margin-bottom: 25px;
    .font-smoothing;
}


// Country Profiles
.longread__country-profiles {
    background-color: #bcbcbc;
    .home & {
        @media (max-width: @screen-sm-max) {
            display: none;
        }
    }
    .longread__row--flexbox {
        padding-top: 40px;
        padding-bottom: 80px;
        .home & {
            padding-bottom: 40px;
        }
    }
    .longread__col--small {
        width: 100%;
        flex: 100% 0 0;
        @media (min-width: @screen-md-min) {
            .country-profiles--ssea & {
                width: 200px;
                flex: 200px 0 0;
                border-right: 2px solid @color-text;
            }
            .country-profiles--esa & {
                width: 400px;
                display: block;
                border-right: 2px solid @color-text;
            }
            .country-profiles--wca & {
                width: 400px;
                display: block;
                border-right: 2px solid @color-text;
            }
        }
    }
    .longread__col--wide {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .country__description {
            position: absolute;
            left: 0px;
            bottom: 0;
            width: 40%;
            opacity: 0;
            padding-bottom: 60px;
        }
        .country__description--active {
            opacity: 1;
        }
        @media (max-width: @screen-md-min) {
            display: none;
        }
    }

    .india-button {
        border-radius: 100%;
        position: absolute;
        width: 120px;
        height: 120px;
        top: 10px;
        left: 113px;
        background: white;
        color: @color-ssea;
        .sans-serif-heavy;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        line-height: 1.3;
        .font-smoothing;
    }

}


.country-profiles--ssea & {
     .longread__col--small {
        @media (min-width: @screen-md-min) {
            width: 200px;
            flex: 200px 0 0;
            border-right: 2px solid @color-text;
        }
    }
}

.country-profiles--esa & {
     .longread__col--small {
        @media (min-width: @screen-md-min) {
            width: 320px;
            flex: 320px 0 0;
            border-right: 2px solid @color-text;
            padding-right: 0;
        }
    }
}
.country-profiles--wca & {
     .longread__col--small {
        @media (min-width: @screen-md-min) {
            width: 420px;
            flex: 420px 0 0;
            border-right: 2px solid @color-text;
            padding-right: 0;
        }
    }
}


.india-button {
    .country-profiles--global & {
        display: none;
    }
    .country-profiles--esa & {
        display: none;
    }
    .country-profiles--wca & {
        display: none;
    }
}

.country__descriptions {
    .country-profiles--ssea & {
        position: absolute;
        left: 30px;
        right: 30px;
        bottom: 0;
    }
    .country-profiles--esa & {
        position: absolute;
        left: 20px;
        right: 0px;
        top: 0;
        .country__description {
            top: 0;
            width: 50%;
            bottom: auto;
            padding-bottom: 0;
            left: 0px;
        }
    }
    .country-profiles--wca & {
        position: absolute;
        left: 20px;
        right: 0px;
        bottom: 0;
        .country__description {
            bottom: 0;
            width: 50%;
            top: auto;
            padding-bottom: 0;
            left: 0px;
        }
    }
}

.country__description {
    h6 {
        .sans-serif-heavy;
        font-size: 18px;
        line-height: 1.4;
        margin: 0;
    }
    p {
        .sans-serif-heavy;
        font-size: 14px;
        line-height: 1.4;
        margin-bottom: 20px;
        color: white;
        .font-smoothing;
        @media (min-width: @screen-md-min) {
            .sans-serif;
            font-weight: normal;
            font-size: 18px;
            line-height: 1.4;
            margin-bottom: 0;
            color: @color-text;
            &:last-child {
                margin: 0;
            }
        }

    }
}

.country-profiles__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    @media (min-width: @screen-sm-min) {
        flex-direction: row;
        flex-wrap: wrap;
    }
    @media (min-width: @screen-md-min) {
        flex-direction: column;
        .country-profiles--global & {
            flex-direction: row;
            display: block;
            column-count: 4;
            column-gap: 20px;
            li {
                break-inside: avoid-column;
                page-break-inside:avoid;
            }
        }
        .country-profiles--esa & {
            flex-direction: row;
            column-count: 2;
            display: block;
            column-gap: 0px;
            li {
                break-inside: avoid-column;
                page-break-inside:avoid;
            }
        }
        .country-profiles--wca & {
            flex-direction: row;
            column-count: 3;
            display: block;
            column-gap: 0px;
            li {
                break-inside: avoid-column;
                page-break-inside:avoid;
                padding-right: 20px;
            }
        }
    }
    margin: 0;
    padding: 0;
    width: 100%;
}


.countrylist__item {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid white;
    @media (min-width: @screen-sm-min) {
        width: 50%;
        flex: 50% 0 0;
        padding-right: 30px;
    }
    @media (max-width: @screen-xs-max) {
        &:first-child {
            padding-top: 0;
        }
    }
    &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
    }
    @media (min-width: @screen-md-min) {
        width: 100%;
        flex: 100% 0 0;
        padding: 0;
        margin-bottom: 10px;
        border: 0;
    }
    span {
        display: block;
        .sans-serif-heavy;
        .font-smoothing;
        font-size: 18px;
        margin-bottom: 15px;
        color: @color-text;
        @media (min-width: @screen-md-min) {
            margin: 0;
            margin-bottom: 5px;
            line-height: 1.1;
        }
        .country-profiles--ssea & {
            color: @color-ssea;
        }
        .country-profiles--esa & {
            color: @color-esadark;
        }
        .country-profiles--wca & {
            color: @color-wca;
        }
        .country-profiles__list--ssea & {
            color: @color-ssea;
        }
        .country-profiles__list--esa & {
            color: @color-esadark;
        }
        .country-profiles__list--wca & {
            color: @color-wca;
        }
        .country-profiles__list--la & {
            color: @color-la;
        }
    }
    .country__description {
        @media (min-width: @screen-md-min) {
            display: none;
        }
    }
    a {
        display: inline-block;
    }
}

.countrylist__item--active {
    span {
        color: @color-text;
    }
    a {
        color: white;
        text-decoration: none;
        .country-profiles--ssea & {
            background: @color-ssea;
            svg, img {
                path {
                    fill: @color-ssea;
                }
            }
        }
        .country-profiles--esa & {
            background: @color-esa;
            svg, img {
                path {
                    fill: @color-esa;
                }
            }
        }
        .country-profiles--wca & {
            background: @color-wca;
            svg, img {
                path {
                    fill: @color-wca;
                }
            }
        }
        .country-profiles__list--ssea & {
            background: @color-ssea;
            svg, img {
                path {
                    fill: @color-ssea;
                }
            }
        }
        .country-profiles__list--esa & {
            background: @color-esa;
            svg, img {
                path {
                    fill: @color-esa;
                }
            }
        }
        .country-profiles__list--wca & {
            background: @color-wca;
            svg, img {
                path {
                    fill: @color-wca;
                }
            }
        }
        .country-profiles__list--la & {
            background: @color-la;
            svg, img {
                path {
                    fill: @color-la;
                }
            }
        }
    }
}




.country-profiles--global {
    .longread__row--flexbox {
        flex-direction: row;
        flex-wrap: wrap;
        @media (min-width: @scorecard-breakpoint) {
            padding-top: 0;
        }
    }
    .longread__col--wide {
        padding: 0;
        width: 100%;
        order: 1;
        @media (min-width: @scorecard-breakpoint) {
            margin-bottom: 20px;
        }
    }
    .longread__col--descriptions {
        order: 3;
        width: 40%;
        flex: 40% 0 0;
        .country__descriptions {
            position: relative;
        }
        .country__description {
            position: absolute;
            opacity: 0;
        }
        .country__description--active {
            opacity: 1;
        }
    }
    .longread__col--list {
        order: 2;
        width: 60%;
        flex: 60% 0 0;
        border-right: 2px solid @color-text;
        padding: 0;
    }


    .countrylist__item {
        @media (min-width: @screen-md-min) {
            width: 100%;
            flex: 100% 0 0;
        }
    }

}



.countries-carousel {
    .flickity-prev-next-button {
        background: none;
        top: 0;
        transform: translateY(-25%);
    }
    .countrylist__letter {
        color: @color-text;
        .sans-serif-heavy;
        text-align: center;
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 20px;
    }
    .carousel-cell {
        width: 100%;
        padding: 0 20px;
    }
}

.global-profiles--desktop {
    @media (max-width: @screen-sm-max) {
        display: none;
    }
}

.global-profiles--mobile {
    @media (min-width: @screen-md-min) {
        display: none;
    }
    .carousel-cell {
        &:nth-child(1) {
            .countrylist__letter {
                margin-top: 20px;
            }
        }
     }
    .countrylist__letter {
        .sans-serif-heavy;
        color: @color-text;
        @media (min-width: @screen-sm-min) {
            text-align: center;
        }
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 20px;
        margin-top: 40px;
    }
}







// Disclaimer
.disclaimer {
    background: @bruin-donker;
	color: @grijs-licht;
}

.disclaimer__title {
    .sans-serif-heavy;
    font-size: 18px;
    padding: 50px 0px;
    color: @grijs-licht;
}

.disclaimer__row {
    display: flex;
    flex-direction: column;
    @media (min-width: @screen-sm) {
        flex-direction: row;
        padding-bottom: 40px;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            height: 2px;
            background: @grijs-licht;
            left: 20px;
            right: 20px;
            display: block;
            bottom: 0;
        }
    }
}

.disclaimer__column {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    @media (min-width: @screen-sm) {
        width: 33.333%;
    }
    .sans-serif;
    strong {
        .sans-serif-heavy;
    }
    a {
        color: @grijs-licht;
        text-decoration: underline;
        &:hover,&:active,&:focus {
            color: @grijs-licht;
            text-decoration: none;
        }
    }
}










// Singles

.ranking-paragraph {
    display: inline-block;
    position: relative;
    &:before {
        content: '';
        background: white;
        width: 12px;
        height: 12px;
        display: inline-block;
        margin-right: 25px;
    }
    &:last-child {
        margin-bottom: 0 !important;
    }
}

.index-single__section {
    .container-longread {
        .index-single__title {
            border-top: 2px solid white;
        }
    }
}

.index-template-index-keyfinding {
    h1.longread-section__title {
        border-top: 2px solid @color-global;
        padding-top: 30px;
        @media (min-width: @screen-sm) {
            font-size: 70px;
        }
    }
    h1.longread-section__title--esa {
        border-top: 2px solid @color-esa;
    }
    h1.longread-section__title--wca {
        border-top: 2px solid @color-wca;
    }
    .index-single__section {
        .noborders {
            border-top: 0;
        }
        .nopaddingtop {
            padding-top: 0 !important;
        }
    }
}

.index-single__row {
    display: flex;
    flex-direction: column;
    @media (min-width: @screen-sm) {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.index-single__col {
    img {
        width: 100%;
        height: auto;
    }
    p {
        .serif;
        font-size: 15px;
        line-height: 1.4;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.index-single__col--mid {
    width: 100%;
    @media (min-width: @screen-sm) {
        width: 60%;
        margin-left: 20%;
    }
    margin-bottom: 40px;
}

.index-single__col--full {
    width: 100%;
    margin-bottom: 40px;
}

.index-single__col--half {
    width: 100%;
    @media (min-width: @screen-sm) {
        width: 50%;
        &:nth-child(odd) {
            padding-right: 20px;
        }
        &:nth-child(even) {
            padding-left: 20px;
        }
    }
    margin-bottom: 40px;
}

.index-single__title {
    width: 100%;
    .sans-serif-heavy;
    text-align: center;
    color: @color-text;
    font-size: 25px;
    @media (max-width: @screen-xs-max) {
        br {
            display: none;
        }
    }
    @media (min-width: @screen-sm) {
        font-size: 35px;
    }

    @media (min-width: @screen-md) {
        padding-left: 150px;
        padding-right: 150px;
    }
    line-height: 1.2;
    text-align: center;
    margin: 0;
    padding: 40px 20px;
    span {
        color: white;
        display: block;
    }
}


.index-single__steps {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0;
    margin-bottom: 40px;
    @media (min-width: @screen-sm) {
        flex-direction: row;
    }
    list-style: none;
    counter-reset: step-counter;
    li {
        width: 100%;
        padding: 0px;
        padding-top: 20px;
        padding-bottom: 40px;
        border-top: 2px solid white;
        &:first-child {
            border-top: 0;
        }
        @media (min-width: @screen-sm) {
            width: 50%;
            &:nth-child(2) {
                border-top: 0;
            }
            &:nth-child(odd) {
                padding-left: 40px;
                padding-top: 40px;
                padding-right: 50px;
                padding-bottom: 50px;
                border-right: 2px solid white;
            }
            &:nth-child(even) {
                padding-left: 50px;
                padding-top: 40px;
                padding-right: 40px;
                padding-bottom: 50px;
            }
        }
        img {
            width: 100%;
            height: auto;
            margin-bottom: 20px;
        }
        p {
            text-align: center;
            .serif;
            font-size: 15px;
            line-height: 1.4;
            margin-bottom: 0;
            @media (max-width: @screen-xs-max) {
                br {
                    display: none;
                }
            }
        }
        counter-increment: step-counter;
        &:before {
            content: counter(step-counter);
            font-size: 35px;
            color: white;
            .sans-serif-heavy;
            .font-smoothing();
            text-align: center;
            display: block;
            line-height: 1;
            margin-bottom: 20px;
        }
    }
}


.index-single__backbutton {

    .longread__col--mid {
        text-align: center;
    }

    .index_backbutton {
        .longread__button;
        white-space: nowrap;
        margin-top: 50px;
        margin-bottom: 50px;
        &:before {
            transform: rotate(180deg);
        }
    }

    .button--purple, .button--a {
        background: @color-purple;
        &:hover,&:active,&:focus {
            background: darken(@color-purple, 10%);
        }
    }
    .button--la {
        background: @color-la;
        &:hover,&:active,&:focus {
            background: darken(@color-la, 10%);
        }
    }
    .button--esa {
        background: @color-esa;
        &:hover,&:active,&:focus {
            background: darken(@color-esa, 10%);
        }
    }
    .button--wca {
        background: @color-wca;
        &:hover,&:active,&:focus {
            background: darken(@color-wca, 10%);
        }
    }
    .button--rubine, .button--b, .button--ssea {
        background: @color-rubine;
        &:hover,&:active,&:focus {
            background: darken(@color-rubine, 10%);
        }
    }
    .button--yellow, .button--c {
        background: @color-mustard;
        &:hover,&:active,&:focus {
            background: darken(@color-mustard, 10%);
        }
    }
    .button--pink, .button--d {
        background: @color-pink;
        &:hover,&:active,&:focus {
            background: darken(@color-pink, 10%);
        }
    }
    .button--green, .button--e {
        background: @color-green;
        &:hover,&:active,&:focus {
            background: darken(@color-green, 10%);
        }
    }
    .button--orange, .button--f {
        background: @color-orange;
        &:hover,&:active,&:focus {
            background: darken(@color-orange, 10%);
        }
    }
    .button--brown, .button--g {
        background: @color-brown;
        &:hover,&:active,&:focus {
            background: darken(@color-brown, 10%);
        }
    }

}






