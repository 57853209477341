

.case-studies-container {
    
    .row {
        overflow: hidden;
        height: 100%;
        .case-wrapper {
            .make-xs-column(12);
            min-height: 600px;
            height: 85vh;
        }
    }
    
    .case {
        position: relative;
        span.shades {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: block;
            z-index: 10;
            @media (min-width: @screen-sm-min) {
                background: rgba(0,0,0,0.3);
            }
            pointer-events: none;
            transition-property: background, transform;
            transition-duration: .4s;
            transform: translateZ(1000);
        }
    }
    
    .visible {
        span.shades {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: block;
            z-index: 10;
            background: rgba(0,0,0,0);
            transition-property: background, transform;
            transition-duration: .4s;
            transform: translateZ(1000);
        }
    }
    
    .background-image {
        z-index: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        display: block;
        background-size: cover;
        background-position: center center;
        @media (min-width: @screen-lg-min) {
            background-attachment: fixed;
        }
    }
    
    .container-fluid {

        position: relative;
        z-index: 99;
        height: 100%;
        background-image: linear-gradient(to right,rgba(0,0,0,0.3),rgba(0,0,0,0.1),rgba(0,0,0,0.0));
        .content-column {
            .make-xs-column(12);
            .make-sm-column(6);
            .make-sm-column-push(1);
            .make-md-column(5);
            .make-md-column-push(1);
            .make-lg-column(4);
            height: 100%;
            padding-bottom: 75px;
            padding-top: 75px;
            
            span.table {
                display: table;
                position: relative;
                height: 100%;
                .table-cell {
                    span.border {
                        border-left: 2px solid @color-regional;
                        padding:  15px 20px;
                        display: inline-block;
                        position: relative;
                    }
                    h2 {
                        .sans-serif-heavy;
                        margin: 0;
                        font-size: 18px;
                    }
                    display: table-cell;
                    position: relative;
                    vertical-align: bottom;
                    .paragraph;
                    font-size: 13px;
                    line-height: 1.3;
                    color: @color-white;
                    text-shadow: @shadow;
                }
            }
        }
        
    }
    
    
    
}