.faq {

}

.faq-heading {
	.h1;

	margin-bottom: 10px;
	margin-top: 20px;
}

.panel-group.panel-group-faq {
	margin-bottom: 50px;
}

.panel.panel-faq {
	text-align: center;
	border-radius: 0;
	border: none;
	border-bottom: 2px solid #fff;
	background: transparent;
	box-shadow: none;

	&:last-child {
		border-bottom: none;
	}

	.panel-heading {
		font-size: 18px;
		color: #fff;
		background: transparent;
		padding: 8px 20px;
		border-radius: 0;
		border: none;
        a {
            color: white;
        }

		&.active {
			a {
				color: @bruin-donker;
			}
		}

	}

	.panel-title {
		font-size: 18px;
		color: #fff;

		> a {
			&:hover, &:active, &:focus {
				text-decoration: none;
			}

			&:hover {
				color: @bruin-donker;
			}
		}
	}

	.panel-body {
		border: none !important;
		padding: 28px 0;
		color: @bruin-donkerst !important;
		font-size: 15px;
		line-height: 1.46;
	}

	> .panel-heading + .panel-collapse > .panel-body {

	}
}

.flag-list {
	padding: 0;
	padding-top: 20px;
	li {
		width: auto !important;
		padding-left: 10px;
		padding-right: 10px;
		display: inline-block !important;
		&:after {
			display: none;
		}
		svg {
			opacity: 0.25;
			transition-property: opacity;
			transition-duration: .4s;
		}
		a {
			&:hover, &:focus, &:active {
				svg {
					opacity: 1;
					transition-property: opacity;
					transition-duration: .4s;
				}
			}
		}
	}
	li.active {
		svg {
			opacity: 1;
			transition-property: opacity;
			transition-duration: .4s;
		}
	}
}