@scorecard-breakpoint: 870px;

.scorecard-download {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media (min-width: @scorecard-breakpoint) {
        align-self: flex-start;
        margin-left: 70px;
    }
    svg {
        width: 37px;
        height: 52px;
        path {
            fill: @color-text;
        }
        margin-right: 10px;
    }
    span {
        color: @color-text;
        .sans-serif-heavy;
        font-size: 15px;
        line-height: 19px;
        margin-top: 2px;
    }
    &:hover,&:focus,&:active {
        text-decoration: none;
        .font-smoothing;
        svg {
            path {
                fill: @color-white;
            }
        }
        span {
            color: @color-white;
        }
    }
}


.company-single-subheader {

    .icon {
        height: 80px;
        width: 80px;
        display: block;
        position: relative;
        float: left;
        margin-right: 10px;
        opacity: 0.5;
        @media (max-width: @scorecard-breakpoint) {
            float: none;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 10px;
        }
    }

    .links {
        padding: 0;
        margin: 0;
        .make-xs-column(12);
        list-style: none;
        text-align: left;
        padding: 0;
        @media (max-width: @scorecard-breakpoint) {
            margin-top: 20px;
            margin-bottom: 20px;
            float: none;
            //display: inline-block;
            display: flex;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
            padding-left: 20px;
            padding-right: 20px;
        }
        display: flex;
        justify-content: flex-start;
        li {
            display: flex;
            flex-basis: 25%;
            align-items: center;
            @media (min-width: @scorecard-breakpoint) {
                justify-content: center;
                clear: none;
                margin-top: 20px;
                margin-bottom: 20px;
            }
            &:not(.active-parent) {
                border-left: 0px;
                border-top: 2px solid rgba(255,255,255,0.5);
                @media (min-width: @scorecard-breakpoint) {
                    border-top: 0px;
                    border-left: 2px solid rgba(255,255,255,0.5);
                }
            }
            padding-bottom: 7px;
            padding-top: 6px;
            a {
                display: flex;
                align-items: center;
                color: rgba(255,255,255,0.5);
                &:hover, &:focus, &:active {
                    color: rgba(255,255,255,1);
                    text-decoration: none;
                    .icon {
                        opacity: 1;
                        svg {
                            path, polygon, rect {
                                fill: @color-white;
                            }
                        }
                    }
                }
            }
            .link--unreleased {
                pointer-events: none;
                .icon {
                    opacity: 0.3;
                }
            }
            order: 1;
        }

        .active-parent {
            color: @color-white;
            order: 0;
            .icon {
                opacity: 1;
                svg {
                    path, polygon, rect {
                        fill: @color-white;
                    }
                }
            }
        }

        .icon {
            width: 36px;
            height: 36px;
            float: left;
            margin: 0;
            margin-top: 0px;
            margin-right: 10px;
        }
        .sub-titles {
            float: left;
            .main-title {
                .sans-serif-small;
                .sans-serif-heavy;
                display: block;
            }
            .sub-title {
                .sans-serif-small;
                .sans-serif-book;
                display: block;
            }
        }
    }

    .download {
        .make-xs-column(4);
        .make-sm-column(2);
        margin: 0;
        padding: 0 !important;
        margin-top: 15px;
        text-align: center;
        border-left: 0px !important;
        .sans-serif;
        .sans-serif-heavy;
        color: @color-white;
        a {
            text-decoration: none;
            color: rgba(255,255,255,0.5);
            &:hover, &:focus, &:active {
                color: rgba(255,255,255,1);
            }
        }
    }

}



.scorecard__lang {
    align-self: flex-start;

    color: black;
    .sans-serif;
    font-size: 11px;
    line-height: 1.4;
    text-align: center;
    margin-top: 30px;
    text-align: left;
    a {
        color: black;
        display: flex;
        flex-direction: row;
        &:hover,&:active,&:focus {
            text-decoration: none;
            color: white;
        }
        .svg {
            width: 37px;
            height: auto;
            align-self: flex-start;
            margin-right: 10px;
            margin-top: 3px;
        }
        span {
            display: block;
        }
    }
}


.scorecard__section {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.scorecard__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (min-width: @scorecard-breakpoint) {
        flex-direction: row;
        max-width: 830px;
    }
}

.scorecard__container--underlined {
    border-bottom: 2px solid @color-white;
}

.scorecard__column {
    padding-left: 20px;
    padding-right: 20px;
    @media (min-width: @scorecard-breakpoint) {
        padding-left: 0;
        padding-right: 0;
    }
}


// Section Header

.scorecard__section--header {

    .header-content {
        flex-basis: 100%;
        @media (min-width: @scorecard-breakpoint) {
            flex-basis: 66.666%;
            order: 1;
        }
        padding-bottom: 30px;
        @media (min-width: @scorecard-breakpoint) {
            padding-top: 30px;
        }
        .main-title {
            .make-xs-column(12);
            text-align: left;
            margin: 0;
        }

        .company-info-aside {
            .make-xs-column(12);
            .make-sm-column(3);
            .sans-serif-small;
            color: @color-white;
            padding-bottom: 15px;
            @media (min-width: @scorecard-breakpoint) {
                padding-top: 3px;
                padding-bottom: 0;
            }

            h6 {
                font-size: inherit;
                .sans-serif-heavy;
                margin: 0;
                padding: 0;
            }

            .aside-link {
                color: inherit;
                text-decoration: none;
                &:hover,&:active,&:focus {
                    color: @color-text;
                }
            }

            .last-updated {
                color: @color-text;
            }
        }

        .company-info {
            .make-xs-column(12);
            .make-sm-column(9);
            .sans-serif;
            .sans-serif-heavy;
            color: @color-white;
            span.footnote {
                .sans-serif-small;
                .font-smoothing;
                display: block;
                font-weight: normal;
            }
            .scorecard__lang {
                font-weight: normal;
                @media (min-width: @scorecard-breakpoint) {
                    display: none;
                }
            }
            .scorecard-download {
                margin-top: 30px;
                @media (min-width: @scorecard-breakpoint) {
                    display: none;
                }
            }
        }
    }

    .header-logo {
        flex-basis: 100%;
        .scorecard__lang {
            margin-left: 70px;
            margin-bottom: 30px;
            margin-top: 10px;
            @media (max-width: @scorecard-breakpoint) {
                display: none;
            }
        }
        .scorecard-download {
            display: none;
        }
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        @media (min-width: @scorecard-breakpoint) {
            flex-basis: 33.333%;
            order: 2;
            padding-bottom: 30px;
            .scorecard-download {
                display: flex;
            }
        }
        .logowrapper {
            flex-grow: 1;
        }
        img {
            margin-bottom: 0;
            padding-bottom: 30px;
            @media (min-width: @scorecard-breakpoint) {
                max-width: 90%;
            }
        }
    }
}


// Section Score

.scorecard__section--score {
    .scorecard__container {
        padding-top: 40px;
        padding-bottom: 0px;
        @media (min-width: @scorecard-breakpoint) {
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }
}

.scorecard__column--ranking {
    width: 100%;
    @media (min-width: @scorecard-breakpoint) {
        width: calc(~"100% - 480px");
        padding-right: 20px;
    }
    .sans-serif-small;
    padding-bottom: 20px;
    .ranking {
        padding: 15px;
        margin-right: 20px;
        margin-bottom: 16px;
        text-align: center;
        float: left;
        .rank {
            .sans-serif-heavy;
            font-size: 50px;
            display: block;
            width: 70px;
            height: 70px;
            line-height: 70px;
            background: @color-white;
            border-radius: 100%;
            margin-bottom: 15px;
        }
        strong {
            font-weight: normal;
            .sans-serif-heavy;
            display: block;
        }
        .score {
            .sans-serif-book;
            display: block;

        }
    }
    .ranking--ssea {
        background: @color-ssea;
    }
    .ranking--global {
        background: @color-global;
    }
    .ranking--esa {
        background: @color-esa;
    }
    .ranking--wca {
        background: @color-wca;
    }

    h2 {
        .index-small-title;
        font-size: 17px;
        margin-bottom: 22px;
    }
    h4 {
        .sans-serif;
        .sans-serif-heavy;
        margin: 0;
        font-size: 14px;
        line-height: 1.4;
    }
    p {
        .serif-small;
        font-size: 13px;
        line-height: 1.4;

    }
}

.scorecard__column--graph {
    flex: 0 0 480px;
    @media (max-width: @scorecard-breakpoint) {
        padding: 0;
        .content {
            padding-left: 20px;
            padding-right: 0px;
            overflow: auto;
            padding-bottom: 20px;
            -webkit-overflow-scrolling: touch;
        }
    }
    .graph__footnote {
        p {
            .sans-serif;
            font-size: 11px;
            line-height: 16px;
            -webkit-font-smoothing: subpixel-antialiased;
            @media (min-width: @scorecard-breakpoint) {
                margin-left: 115px;
            }
            margin-top: 20px;
            margin-bottom: 0;
            color: black;
            a {
                color: inherit;
                text-decoration: underline;
                &:hover,&:active,&:focus {
                    text-decoration: none;
                }
            }
        }
    }

}




// Section Map

.scorecard__column--map {
    .make-xs-column(12);
    padding: 0;
    @media (max-width: @scorecard-breakpoint) {
        padding-left: 0px !important;
        padding-right: 0px;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        .mobile-overflowwrapper {
            //min-width: 780px;
            min-width: 830px;
            display: block;
        }
        .map-legend {
            left: 20px;
        }
    }
    h5 {
        .index-small-title;
        margin-bottom: 20px;
        position: absolute;
        top: 20px;
        left: 20px;
        @media (min-width: @scorecard-breakpoint) {
            top: -35px;
            left: 0px;
        }
        z-index: 99;
    }
}


// Section Tables

.scorecard__section--table {
    .scorecard__container {
        padding-top: 20px;
        padding-bottom: 50px;
        flex-wrap: wrap;
    }
}

.scorecard__column--table {
    h5 {
        .index-small-title;
        .sans-serif-heavy;
        margin-bottom: 20px;
    }
    width: 100%;
    flex-basis: 0 0 100%;
    &:nth-child(1) {
        margin-bottom: 30px;
    }
    .table-overflow-wrapper {
        @media (max-width: @scorecard-breakpoint) {
            display: block;
            min-width: 800px;
            padding-right: 20px;
        }
    }
}
.scorecard__column--overflow {
    @media (max-width: @scorecard-breakpoint) {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 40px;
        border-bottom: 2px solid @color-white;
    }
}

@media (min-width: @scorecard-breakpoint) {
    .scorecard__column--table-global-left {
        width: 500px;
        margin-right: 40px;
    }
    .scorecard__column--table-global-right {
        width: 290px;
    }
}


// Section Findings

.scorecard__column--findings {
    flex: 0 0 100%;
    @media (min-width: @scorecard-breakpoint) {
        flex: 0 0 50%;
    }
    h5 {
        .index-small-title;
        .sans-serif-heavy;
        margin-bottom: 20px;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            margin-bottom: 15px;
            p {
                display: inline;
            }
            &:last-child {
                margin-bottom: 0;
            }

        }
    }
    .sans-serif;
    ul.ma_legend-blocks {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-right: 5px;
        display: inline-block;
        transform: translateY(1px);
        li {
            display: inline;
            span.ma_color-block {
                width: 14px;
                height: 12px;
                display: block;
                position: relative;
                margin: 0;
                padding: 0;
                float: left;
            }
        }
    }
    padding-top: 30px;
    padding-bottom: 40px;
    &:nth-child(2) {
        border-top: 2px solid @color-white;
    }
    @media (min-width: @scorecard-breakpoint) {
        &:nth-child(1) {
            padding-right: 20px;
        }
        &:nth-child(2) {
            padding-left: 20px;
            border-left: 2px solid @color-white;
            border-top: 0;
        }
    }
}

.scorecard__column--cols {
    @media (min-width: @scorecard-breakpoint) {
        flex: 0 0 100%;
        column-count: 2;
        column-gap: 42px;
        padding-right: 0 !important;
        li {
            break-inside: avoid-column;
            page-break-inside: avoid;
        }
    }
}



// Section Footer

.scorecard__section--footer {
    padding: 50px 0;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    @media (min-width: @scorecard-breakpoint) {
        flex-direction: row;
        align-items: flex-end;
    }
    .scorecard__column--disclaimer {
        flex: 0 0 66.666%;
        .sans-serif;
        color: @color-text;
        display: flex;
        align-items: flex-end;
        p {
            margin: 0;
        }
        @media (max-width: @scorecard-breakpoint) {
            text-align: center;
            margin-bottom: 30px;
        }
        a {
            text-decoration: underline;
            color: inherit;
            &:hover,&:active,&:focus {
                text-decoration: none;
                color: inherit;
            }
        }
    }
    .scorecard__column--download {
        flex: 0 0 33.333%;
        display: flex;
        justify-content: flex-start;
        @media (min-width: @scorecard-breakpoint) {
            //justify-content: flex-end;
            justify-content: center;
        }
    }
}