.portfolio-table {
    width: 50%;

    .sans-serif-small;
    font-size: 9px;
    line-height: 10px;

    .table-header {
        .sans-serif-heavy;
        text-align: center;
        font-size: 12px;
        padding-bottom: 10px;
    }

    .table-subheader {
        .sans-serif-book;
        text-align: center;
        font-weight: normal;
    }

    .table-name {
        .sans-serif-heavy;
        text-align: left;
    }

    .table-dot {
        display: block;
        position: relative;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        width: 10px;
        height: 10px;
        border-radius: 100%;
    }

    .dot {
        span {
            .table-dot;
        }
    }

    .dot.regional {
        span {
           background: yellow;
        }
    }

    .dot.global {
        span {
            background: @color-blue;
        }
    }

    .breeding-icon {
        background: @bruin-donkerst;
        position: relative;
        background: url('../../img/breeding-icon.svg');
        width: 14px;
        height: 14px;
        padding-top: 1px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .dot.breeding {
        padding: 0;
        span {
            .breeding-icon;
        }
    }


    tr {
        td {
            padding-top: 3px;
            padding-bottom: 3px;
            text-align: center;
            height: 16px;
            border-bottom: 1px solid @color-white;

            &:nth-child(1) {
                margin-right: 2px;
            }
            &:nth-child(2) {
                border-left: 2px solid @color-white;
            }
            &:nth-child(24) {
                border-right: 2px solid @color-white;
            }
        }
        &:last-child {
            td {
                border-bottom-width: 0px;
            }
        }
        td.spacer {
            border-width: 0px !important;
            width: 2px !important;
        }
    }

    .headrow {
        td {
            border-width: 0px !important;
        }
    }

}

.index-in-portfolio.full-width {
    .table-name {
        width: 20%;
    }
}


.section-heading-row {
    .section-heading {
        color: @color-white;
        padding-bottom: 5px;
        padding-top: 15px;
        font-size: 12px;
        .sans-serif-heavy;
    }
}



.table-wrapper {

    .crops {
        @media (max-width: @screen-xs-max) {
            width: 75%;
        }
        width: 50%;
        .table-name {
            width: 100%;
        }
    }

    .global-table.crops-sales-breeding {
        width: 100%;
        .dot {
            width: 9%;
            &:nth-child(8) {
                border-right: 2px solid @color-white;
            }
        }
    }

    .regional-table.crops-breeding {
        @media (max-width: @screen-xs-max) {
            width: 100%;
        }
        width: 75%;
        .table-name {
            width: 50%;
        }
    }

    .table-overflow-wrapper {
        @media (max-width: @screen-xs-max) {
            display: block;
            min-width: 790px;
            padding-right: 10px;
        }
    }

    .regional-table.crops-sales-breeding {
        width: 100%;
        .table-name {
            width: 20%;
        }
        .breeding {
            width: 6%;
        }

    }

    .footnote {
        padding-top: 10px;
        text-align: right;
        display: block;
        width: 100%;
    }

}







.table__index-crops {

    .sans-serif-small;
    font-size: 9px;
    line-height: 10px;

    //width: 100%;
    .column--crops {
        width: 110px;
    }
    .column--country {
        width: 35px;
    }
    .column--seedtype {
        width: 100px;
    }
    .column--source {
        width: 150px;
    }


    .table-header {
        .sans-serif-heavy;
        text-align: center;
        font-size: 13px;
        padding-bottom: 10px;
    }

    .table-subheader {
        .sans-serif-book;
        text-align: center;
        font-weight: normal;
    }

    .table-subheader--uppercase {
        text-transform: uppercase;
    }

    .table-name {
        .sans-serif-heavy;
        text-align: left;
        padding-left: 3px;
    }

    .table-dot {
        display: inline-block;
        position: relative;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        .sans-serif-heavy;
    }

    .dot {
        span {
            .table-dot;
        }
    }

    tr {
        td {
            padding-top: 3px;
            padding-bottom: 3px;
            text-align: center;
            height: 16px;
            border-bottom: 1px solid @color-white;
        }
        td, th {
            &:last-child {
                border-right: 0 !important;
            }
        }
        .table-name, .border-right {
            border-right: 1px solid @color-white;
        }
        .highlight {
            background: @color-white;
        }
        .highlight-maincrops {
            padding: 0;
            .highlight {
                padding-top: 3px;
                padding-bottom: 3px;
                padding-left: 3px;
                width: 100%;
                display: block;
                border-top: 1px solid @color-white;
                border-bottom: 1px solid @color-white;
            }
        }
        &:last-child {
            td {
                border-bottom-width: 1px;
            }
            td.spacer {
                border-bottom-width: 1px !important;
            }
        }
        td.spacer {
            border-width: 0px !important;
            width: 2px !important;
        }
    }
    .tr--no-bottom-borders {
        td, th {
            padding-top: 20px;
            border-bottom-width: 0px !important;
        }
    }

    .headrow {
        td {
            border-bottom-width: 0px !important;
            height: 30px;
        }
    }
}


.table--global {
    .dot {
        position: relative;
        span {
            background: @color-global;
        }
        .asterisk {
            background: none;
            position: absolute;
        }
    }
}
.table--ssea {
    .dot {
        position: relative;
        span {
            background: @color-ssea;
        }
        .asterisk {
            background: none;
            position: absolute;
        }
    }
}
.table--esa {
    .dot {
        position: relative;
        span {
            background: @color-esa;
        }
        .asterisk {
            background: none;
            position: absolute;
        }
    }
}
.table--wca {
    .dot {
        position: relative;
        span {
            background: @color-wca;
        }
        .asterisk {
            background: none;
            position: absolute;
        }
    }
}
.table--la {
    .dot {
        position: relative;
        span {
            background: @color-la;
        }
        .asterisk {
            background: none;
            position: absolute;
        }
    }
}






.table__local-crops {
    list-style: none;
    margin: 0;
    padding: 0;
    .sans-serif-heavy;
    font-size: 9px;
    line-height: 10px;
    display: flex;
    flex-wrap: wrap;
    li {
        border-right: 1px solid @color-white;
        line-height: 28px;
        padding: 0 10px;
        &:last-child {
            border-right: 0;
        }
        @media (max-width: @screen-xs-max) {
            padding-left: 0;
            line-height: 24px;
            width: 80%;
            border-bottom: 1px solid @color-white;
            border-right: 0;
        }
    }
}