.company-scorecard {
    background: #cccccc;
    .graph-column {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .chart-figure {
        width: 445px;
    }
    .map {
        width: 780px;
    }
}