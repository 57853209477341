.video-resource {
	border-bottom: 2px solid rgb(170, 170, 170);
	padding: 20px 0;

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		border-bottom: none;
	}
	
	.media {
		.make-row();
		a {
			color: @text-color;
			transition-property: text-shadow, color;
			transition-duration: .4s;
			&:hover, &:focus, &:active {
				text-decoration: none;
				text-shadow: @shadow;
				color: @color-white;
				transition-property: text-shadow, color;
				transition-duration: .4s;
			}
		}
	}

	.media-left {
		padding-right: 40px;
		.make-xs-column(12);
		.make-sm-column(6);
		img {
			width: 100%;
			height: auto;
		}
	}

	.media-object {
		
	}

	.media-body {
		overflow: visible;
		.make-xs-column(12);
		.make-sm-column(6);
	}

	.media-heading {
		.sans-serif;
		.sans-serif-medium;
		color: @color-white;
		margin-bottom: 3px;
	}

	.published, .cat {
		.sans-serif;
		.sans-serif-small;
		color: @grijs-donker;
		display: block;
	}

	p {
		.serif;
		.serif-small;
		font-weight: 300;
		font-size: 11px !important;
		line-height: 16px !important;
		margin-top: 15px;
	}
}

@media (max-width: @screen-xs-max) {
	.video-resource {
		.media-left {
			display: block;
			margin-bottom: 20px;
		}

		.media-object {
			.img-responsive;
			width: 100%;
			margin-bottom: 10px;
		}

		.media-body {
			overflow: visible;
			display: block;
			width: auto;
		}
	}
}