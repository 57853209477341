.list-gov {
	.serif;
	font-size: 11px;
	.reset-list;
	margin: 20px 0;

	li {
		margin: 6px 0;
	}

	a {
		color: @bruin-donker;
		display: inline-block;
		position: relative;
		padding-left: 24px;
		&:hover {
			text-decoration: none;
			color: @color-white;
			&:before {
				content: url(../../img/arrow_list_white.svg);
			}
		}

		&:before {
			content: url(../../img/arrow_list_yellow.svg);
			position: absolute;
			left: 0;
			top: -1px;
		}
	}
}