.guiding-principles__header {
    margin: 0;
}

.principle-row {
    border-top: 2px solid #fff;
    padding-top: 40px;
    &:nth-child(2) {
            border-top: 0;
    }
    h2 {
        .h1;
        padding-bottom: 20px;
        padding-top: 0px;
        font-size: 28px;
        line-height: 1.2;
        margin: 0;
    }
}

