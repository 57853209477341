.wrapper-learn-more {
    .container-fluid;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.container-learn-more {
    .container-fluid;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1024px !important;
    position: relative;
    @media (min-width: @screen-lg) {
        .container;
        padding-left: 20px;
        padding-right: 20px;
        width: 1024px !important;
    }
}

.learn-more__row {
    .make-row();
    margin-left: -20px;
    margin-right: -20px;
}

.learn-more__col--wider {
    .make-xs-column(12);
    .make-sm-column(7);
    padding-left: 20px;
    padding-right: 20px;
}

.learn-more__col--smaller {
    .make-xs-column(12);
    .make-sm-column(5);
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    @media (min-width: @screen-sm) {
        padding-bottom: 0px;
    }
}

.learn-more__col--half {
    .make-xs-column(12);
    .make-sm-column(6);
    padding-left: 20px;
    padding-right: 20px;
}

.learn-more__header {
    padding-top: 50px;
    .header__logo {
        width: 100%;
        max-width: 220px;
        margin-bottom: 20px;
    }
    .learn-more__header__title {
        margin: 0;
        padding: 0;
        .sans-serif-heavy;
        color: white;
        margin-bottom: 30px;
        font-size: 30px;
        line-height: 1.1;
        @media (min-width: @screen-sm) {
            font-size: 50px;
            text-align: left;
        }
    }
    .learn-more__header__text {
        p {
            margin: 0;
            .sans-serif-heavy;
            font-size: 20px;
            line-height: 1.2;
            text-align: center;
            @media (min-width: @screen-sm) {
                font-size: 25px;
                text-align: left;
            }
        }
    }
}

.learn-more__lead {
    .sans-serif-heavy;
    color: white;
    font-size: 18px;
    line-height: 1.4;
}

.learn-more__wysiwyg {
    padding-bottom: 20px;

    strong, b, h3 {
        margin: 0;
        .sans-serif-heavy;
        font-size: 18px;
        line-height: 1.2;
        text-align: left;
        color: @color-text;
        display: block;
        margin-bottom: 3px;
    }
    .serif;
    font-size: 15px;
    line-height: 1.4;
    p {
        margin-bottom: 20px;
    }

    img {
        width: 100%;
        height: auto;
        margin: 20px 0px;
    }

    .gallery {
        .make-row();
        overflow: hidden;
        .gallery-item {
            .make-xs-column(12);
            .make-sm-column(6);
            img {
                margin-bottom: 0;
            }
            a {
                padding: 0;
                &:before, &:after {
                    display: none;
                }
            }
        }
        margin-bottom: 40px;


        .wp-caption-text {
            display: none;
            .sans-serif-book;
            color: @color-text;
            font-size: 13px;
            line-height: 1.3;
            padding-top: 20px;
        }

    }

    a {
        .learn-more__button;
        padding: 8px 0 8px 30px;
        &:after {
            top: 8px;
        }
        &:hover,&:focus,&:active {
            padding: 8px 0 8px 30px;
            &:after {
                top: 8px;
            }
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 40px;
        li {
            @media (min-width: @screen-sm) {
                width: 50%;
                float: left;
            }
            margin: 0;
            padding: 0;
            a {
                .learn-more__button;
                padding: 8px 0 8px 30px;
                &:after {
                    top: 8px;
                }
                &:hover,&:focus,&:active {
                    padding: 8px 0 8px 30px;
                    &:after {
                        top: 8px;
                    }
                }
            }
        }
    }
}

.learn-more__left-column__wysiwyg {

    strong, b {
        .sans-serif-heavy;
        color: white;
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 40px;
        display: block;
    }
    .serif;
    font-size: 15px;
    line-height: 1.4;

    a {
        .learn-more__button;
        padding: 8px 0 8px 30px;
        &:after {
            top: 8px;
        }
        &:hover,&:focus,&:active {
            padding: 8px 0 8px 30px;
            &:after {
                top: 8px;
            }
        }
    }

    p {
        margin-bottom: 20px;
    }

    img {
        width: 100%;
        height: auto;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 40px;
        li {
            margin: 0;
            padding: 0;
            a {
                .learn-more__button;
                padding: 8px 0 8px 30px;
                &:after {
                    top: 8px;
                }
                &:hover,&:focus,&:active {
                    padding: 8px 0 8px 30px;
                    &:after {
                        top: 8px;
                    }
                }
            }
        }
    }
    @media (min-width: @screen-sm) {
        padding-right: 40px;
    }

}

.learn-more__button {
    .sans-serif-book;
    font-size: 13px;
    line-height: 1.3;
    color: white;
    display: inline-block;

    padding: 15px 0 15px 30px;
    position: relative;
    .download-arrow;

    &:hover,&:active,&:focus {
        color: @color-text;
        text-decoration: none;
        .download-arrow-grey;
    }
}

.learn-more__quotes {
    .container-learn-more {
        position: relative;
        padding-top: 50px;
        padding-bottom: 40px;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            display: block;
            left: 20px;
            right: 20px;
            height: 2px;
            background: white;
        }
    }

    .leader-quote {
        .image {
            margin-bottom: 20px;
            @media (min-width: @screen-sm) {
                margin-bottom: 0;
            }
        }
        @media (min-width: @screen-sm) {
            display: flex;
            align-items: center;
        }
    }

    @media (min-width: @screen-sm) {
        .text {
            blockquote {
                p {
                    font-size: 18px;
                    line-height: 1.4;
                }
                footer {
                    font-size: 13px;
                    line-height: 1.4;
                }
            }
        }
    }

    .learn-more__quotes__button {
        text-align: center;
    }

}




.learn-more__behind__row {
    padding-bottom: 100px;
    position: relative;
    overflow: hidden;
    &:after {
        content: '';
        left: 20px;
        right: 20px;
        height: 2px;
        background: white;
        position: absolute;
        display: block;
        bottom: 50px;
    }
    &:last-child {
        padding-bottom: 10px;
        &:after {
            display: none;
        }
    }
}