.about {
    .swiper-slide--desktop{
        display: none;
        @media (min-width: @screen-sm-min) {
            display: block;
        }
    }
    .swiper-slide--mobile{
        display: block;
        @media (min-width: @screen-sm-min) {
            display: none;
        }
    }
    .swiper-container{
        padding: 0;
        .swiper-slide {
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            .container {
                height: 100%;
                position: relative;
                .row {
                    height: 100%;
                    .content-column {
                        display: table;
                        height: 100%;
                    }

                    .front-page-column {
                        .make-xs-column(12);
                        .make-sm-column(6);
                        .make-md-column(6);
                        .make-lg-column(6);

                        text-align: center;
                    }

                    .about-column {
                        .make-xs-column(12);
                        .make-sm-column(10);
                        .make-sm-column-push(1);

                        text-align: center;

                        p {
                            .paragraph;
                            margin-bottom: 0;
                        }
                    }

                    h2 {
                        display: table-row;
                        width: 100%;

                        height: auto;
                        padding: 0;
                        margin: 0;

                        text-align: center;
                    }
                    img {
                        text-align: center;
                        position: relative;
                        display: inline-block;
                        margin: 0;
                        margin-top: 36px;
                        margin-bottom: 20px;
                    }
                    .text-row {
                        display: table-row;
                        width: 100%;
                        height: 100%;
                        text-shadow: @shadow;
                        color: @color-white;
                        a {
                            color: @color-white;
                            &:hover, &:focus, &:active {
                                color: #ffff00;
                                text-decoration: none;
                            }
                        }
                        span {
                            display: table-cell;
                            vertical-align: bottom;
                            padding-bottom: 40px;
                        }
                    }

                    .carousel-internal-link {
                        .serif;
                        text-align: center;
                        color: @color-white;
                        footer {
                            .sans-serif;
                        }
                        a {
                            &:hover, &:focus, &:active {
                                color: #ffff00;
                            }
                        }
                        .footer_internallink{
                            display: block;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            .sans-serif-big;
                            .sans-serif-heavy;
                            max-width: 200px;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }

                    .carousel-download-link {
                        text-align: center;
                        h4 {
                            .sans-serif-big;
                            .sans-serif-heavy;
                            margin: 0;
                        }
                        p {
                            .sans-serif-big;
                        }
                        a {
                            position: relative;
                            .sans-serif;
                            .download-arrow;
                            padding-left: 20px;
                            &:after {
                                filter: drop-shadow(@shadow);
                            }
                        }
                        &:hover, &:focus, &:active {
                            color: #ffff00;
                        }
                    }

                }
            }
        }
    }

}

.swiper-container--header{
    .swiper-slide {
        background-size: contain;
    }
}


.home{
    .swiper-pagination.swiper-pagination--home {
        bottom: auto;
        width: auto;
        display: inline-block;
        text-align: center;

        position: absolute;
        right: 20px;
        top: 12px;
    }
    .swiper-pagination--home .swiper-pagination-bullet {
        margin: 0 5px;
    }
    .swiper-container--home {
        height: 100%;
        width: 100%;
        .swiper-slide {
            img {
                position: absolute;
                object-fit: cover;
                font-family: 'object-fit: cover;';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
            }
            .swiper-slide__inner{
                height: 100%;
                display: flex;
                align-items: flex-end;
                flex-flow: row wrap;
                padding: 10px 15px;
                z-index: 99;
                position: relative;
                p, footer{
                    .serif;
                    text-shadow: @shadow;
                    color: @color-white;
                }
                p{
                    width: 50%;
                    margin-left: 20px;
                    margin: 0;
                    margin-bottom: -50px;
                    font-size: 11px;
                }
                footer{
                    font-size: 8px;
                    text-align: left;
                    width: 100%;
                }
            }
        }
        .swiper-pagination-bullet {
            width: 6px;
            height: 6px;
        }
        @media (min-width: @screen-sm-min) {
            .swiper-slide {
                .swiper-slide__inner{
                    p{
                        .serif;
                    }
                }
            }
            .swiper-pagination-bullet {
                width: 8px;
                height: 8px;
            }
        }
    }
}

