.methmenu {
	text-align: center;
	display: table;
	margin: 0px auto 90px auto;
	position: relative;
	z-index: 10;
	background: transparent;
	max-width: 1024px;

	&:before {
		pointer-events: none;
		content: '';
		position: absolute;
		top: -12px;
		left: -11px;
		right: -11px;
		bottom: auto;
		height: 26px;
		z-index: -1;
		background: rgb(224, 145, 0);
	}
	
	&:hover {
		&:before {
			display: none;
		}
	}

	.methmenu-row {
		text-align: center;
		display: table;
		background: transparent;
		margin: 0 auto;
	}

	a {
		width: 202px;
		height: 96px;
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		padding: 0 10px;
		border-color: blue;
		border-style: solid;
		border-top-width: 2px;
		border-left-width: 1px;
		border-right-width: 1px;
		border-bottom-width: 0;
		position: relative;
		color: @color-white;
		border-color: rgb(255, 176, 0);

		&:first-child {
			border-left-width: 2px;
		}

		&:last-child {
			border-right-width: 2px;
		}

		&:focus {
			text-decoration: none;
		}

		&:hover, &:active {
			text-decoration: none;
			color: @bruin-donker !important;

			&:after {
				content:'';
				position: absolute;
				display: block;
				top: -14px;
				right: -13px;
				bottom: -14px;
				left: -13px;
				min-height: 122px;
				background: rgb(224, 145, 0);
				z-index: -1;
				box-shadow: 1px 1px 8px rgba(0, 0, 0, .2);
			}
			
			.main-text {
				color: @bruin-donker !important;
			}
			
		}
		
	}
	
	.text-block {
		display: table;
		width: 100%;
		margin: 0;
		span {
			display: table-cell !important;
			vertical-align: middle;
			width: 100%;
		}
	}
	
	.main-text {
		.h3;
		span {
			p {
				margin: 0;
			}
		}
	}

	.alt-text {
		position: relative;
		display: block;
		z-index: 20;
		bottom: -5px;
		padding-bottom: 20px;
		padding-top: 20px;
	}

	&.methmenu-alt-text {
		
		span {
			display: block;
		}
		
		
		.alt-text {
			display: none;
			.serif-small;
			bottom: auto;
			padding-bottom: 10px;
			padding-top: 10px;
			span {
				width: 100%;
				padding: 10px;
			}
		}

		a:hover {
			@media (min-width: @screen-md-min) {
				.main-text {
					display: none;
				}
	
				.alt-text {
					display: table;
				}
			}
		}
	}

	&.methmenu-green {
		&:before {
			background: rgb(0, 130, 69);
		}

		
		&:hover {
			cursor: default;
			&:before {
				display: none;
			}
		}
		

		a {
			border-color: rgb(0, 161, 99);

			&:hover, &:active {
				&:after {
					background: rgb(0, 130, 69);
				}
			}
		}
	}

	&.scope {
		&:before {
			background: rgb(0, 140, 224);
		}

		&:hover {
			&:before {
				display: none;
			}
		}

		a {
			border-color: rgb(0, 171, 255);

			&:hover, &:active {
				&:after {
					background: rgb(0, 140, 224);
				}
			}
		}
	}
}

@media (max-width: 1024px) {
	.methmenu {
		display: block;
		max-width: auto;
		padding-left: 15px;
		padding-right: 15px;

		.methmenu-row {
			display: block;
		}

		

		a {
			width: 100%;
			height: auto;
			display: block;
			padding: 20px 0;
			border-top-width: 2px;
			border-left-width: 2px;
			border-right-width: 2px;
		}

		span, .alt-text {
			bottom: auto;

		}

		&.methmenu-alt-text {
			.alt-text {
				//margin-top: 10px;
			}

			a {
				span, .alt-text {
					display: block;
				}
			}

			a:hover {
				span {
					display: block;
				}

				.alt-text {
					display: block;
				}
			}
		}
	}
}