.methtable {
	margin-top: 80px;
	text-align: center;

	h2 {
		font-family: 'AvenirLTStd-Heavy', @font-family-sans-serif;
		font-size: 18px;
		color: #fff;
		line-height: 1.22;
		margin-bottom: 20px;
		margin-top: 0;
		text-align: center;
	}

	img {
		.img-responsive;
		margin-left: auto;
		margin-right: auto;
		padding-left: 15px;
		padding-right: 15px;
	}
}