.definitions-wrapper {
    min-height: calc(~"100vh - 44px");
    padding-top: 50px;
    padding-bottom: 100px;
}


.definitions__list {
    column-count: 1;
    @media (min-width: @screen-sm) {
        column-count: 2;
    }
    @media (min-width: @screen-md) {
        column-count: 3;
    }
    column-gap: 20px;

    margin: 0;
    padding: 0;
    list-style: none;

    h3, .lead {
        color: @color-text;
        text-align: left;
        margin: 0;
        padding: 0;
        .sans-serif-heavy;
        font-size: 17px;
    }
    .lead {
        p {
            border: 0;
            margin-bottom: 40px;
        }
    }
    li {
        break-inside: avoid;
        .serif;
        font-size: 15px;
        line-height: 1.4;
        p {
            margin-bottom: 15px;
            padding-bottom: 10px;
            border-bottom: 2px solid white;
        }
        &:last-child {
            p {
                border: 0;
            }
        }
    }
}


.definitions-references {
    margin-top: 60px;
    border-top: 2px solid white;
    padding-top: 15px;
    line-height: 1.4;
    .font-smoothing;
    strong {
        .sans-serif-heavy;
        font-size: 17px;

    }
    font-size: 15px;
    a {
        color: @color-text;
        opacity: 0.5;
    }
}