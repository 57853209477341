

figure.map {
    padding: 0;
    width: 100%;
    position: relative;

}





.map-location-label {
    .sans-serif-heavy;
    .font-smoothing;
    fill: @color-white;
    font-size: 11px;
    pointer-events: none;
}


.hoverinfo {
    border-width: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    .sans-serif-heavy;
    box-shadow: none !important;
}


.map-legend {
    position: absolute;
    bottom: 20px;
    list-style: none;
    padding: 0;
    margin: 0;
    .sans-serif-book;
    font-size: 11px;
    color: @color-white;
    .font-smoothing;
    li {
        margin-top: 4px;
        position: relative;
        padding-left: 17px;
        &:before {
            content: '';
            left: 2px;
            display: inline;

            width: 10px;
            height: 10px;
            border-radius: 100%;
            background: @color-global;
            position: absolute;
            background-size: contain;
            background-position: center center;
        }
        &:nth-child(1) {
            &:before {
                content: '';
                border-radius: 100%;
                background: rgba(160,160,160,0.85);
                top: 2px;
            }
        }
        &:nth-child(2) {
            &:before {
                content: '';
                border-radius: 100%;
                background: @color-global;
                top: 2px;
            }
        }
        &:nth-child(3) {
            &:before {
                background: url('../../img/production-icon_global.svg') no-repeat;
                width: 14px;
                height: 14px;
                border-radius: 0;
                left: 0;
            }
        }
        &:nth-child(4) {
            &:before {
                background: url('../../img/breeding-icon.svg') no-repeat;
                width: 14px;
                height: 14px;
                border-radius: 0;
                top: 0px;
                left: 0;
            }
        }
    }
}

.map--ssea {
    .map-legend {
        li {
            &:nth-child(2) {
                &:before {
                    background: @color-ssea;
                }
            }
            &:nth-child(3) {
                &:before {
                    background: url('../../img/production-icon_ssea.svg') no-repeat;
                }
            }
        }
    }
}
.map--global {
    .map-legend {
        li {
            &:nth-child(2) {
                &:before {
                    background: @color-global;
                }
            }
            &:nth-child(3) {
                &:before {
                    background: url('../../img/production-icon_global.svg') no-repeat;
                }
            }
        }
    }
}
.map--esa {
    .map-legend {
        li {
            &:nth-child(2) {
                &:before {
                    background: @color-esa;
                }
            }
            &:nth-child(3) {
                &:before {
                    background: url('../../img/production-icon_esa.svg') no-repeat;
                }
            }
        }
    }
}
.map--wca {
    .map-legend {
        li {
            &:nth-child(2) {
                &:before {
                    background: @color-wca;
                }
            }
            &:nth-child(3) {
                &:before {
                    background: url('../../img/production-icon_wca.svg') no-repeat;
                }
            }
        }
    }
}

.geo-title {
    padding-top: 0px;
    padding-bottom: 25px;
    margin: 0;
}

.containert {
    width: 100%;
    @media (max-width: @screen-xs-max) {
        width: 280px;
    }
    display: block;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
    text-align: center;

}

    span.map-wrapper {
        min-width: 780px;
        width: 100%;
        position: relative;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        #map-geoscope {
            display: block;
            position: relative;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
        }
    }

.geo-footer {
    text-align: center;
    .container {
        padding-bottom: 20px;
        .paragraph;
        color: @color-white;
    }

}
