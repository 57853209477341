.the-index__header {

    .longread__row--flexbox {
        flex-direction: column;
        @media (min-width: @screen-sm-min) {
            flex-direction: row;
        }
    }

    .longread__col--smaller {
        display: flex;
        justify-content: center;
        padding-top: 50px;
        padding-bottom: 50px;
        .header__logo {
            width: 180px;
            height: 170px;
            margin: 0;
        }
    }

    .longread__col--wider {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 50px;
        a {
            color: inherit;
            text-decoration: underline;
            &:hover,&:focus,&:active {
                text-decoration: none;
            }
        }
        @media (min-width: @screen-sm-min) {
            padding-top: 75px;
        }
        p {
            font-size: 15px;
            line-height: 1.6;
            margin-bottom: 0;
            color: @color-text;
            @media (min-width: @screen-sm-min) {
                padding-right: 40px;
            }
        }
    }

}


.the-index__menu {

    .main-title {
        font-size: 50px;
        padding-top: 50px;
        margin-bottom: 10px;
    }

    .nav-page {
        display: flex;
        flex-direction: column;
        @media (max-width: @screen-xs-max) {
            padding-top: 0;
            padding-bottom: 50px;
        }
        @media (min-width: @screen-sm-min) {
            flex-direction: row;
        }
        padding-bottom: 100px;
        li {
            @media (max-width: @screen-xs-max) {
                padding-top: 20px;
                padding-bottom: 20px;
                width: 100%;
            }
            a {
                display: flex;
                flex-direction: column;
            }
            .icon {
                margin-bottom: 20px;
                img {
                    width: 100px;
                    height: auto;
                }
            }
            .sub-titles {
                font-size: 22px;
                .font-smoothing;
            }
        }
        .page_item--unreleased {
            pointer-events: none;
            .icon {
                opacity: 0.3;
            }
        }
    }

}