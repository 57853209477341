



.single-wrapper {

	.modal-wrapper {
		padding-left: 10px;
		padding-right: 10px;
		.modal {
			background: none;
            .fullcolumn {
                .make-sm-column(12);
                float: left !important;
                left: 0 !important;
                margin-bottom: 100px;


                .wp-caption {
                    width: 100% !important;
                    height: auto !important;

                    img {
                        width: 100%;
                        max-width: 100% !important;
                        height: auto;
                        margin-bottom: 20px;
                    }

                    .wp-caption-text {
                        color: @text-color;
                        @media (min-width: @screen-sm-min) {
                            padding-right: 20%;
                        }

                        font-size: 11px;
                        line-height: 14px;
                        margin-bottom: 40px;
                    }

                }

                .entry-content-asset {
                    .embed-responsive;
                    .embed-responsive-16by9;
                    margin-bottom: 30px;

                }

            }
			.column-1 {
				@media (min-width: @screen-sm-min) {
					padding-left: 20px;
				}
				p {
					.paragraph;
					color: @bruin-donker;
				}
				ul {
					li {
						.paragraph;
						color: @bruin-donkerst;
					}
				}
			}
			.column-2 {
				@media (min-width: @screen-sm-min) {
					padding-right: 20px;
				}
				.caption {
					.serif;
					color: @bruin-donker;
					font-size: 11px;
					line-height: 16px;
					margin-top: 10px;
					margin-bottom: 40px;
					padding-right: 20px;
				}
			}
			h1 {
				.serif;
				text-align: left;
				font-weight: normal;
				font-size: 27px;
				line-height: 33px;
				padding: 0;
				margin: 0;
				margin-bottom: 10px;
			}
			.published {
				.sans-serif;
				display: inline-block;
				line-height: 1;
				padding-bottom: 30px;
			}
			p {
				.paragraph;
				color: @bruin-donkerst;
			}
			ul {
				li {
					.paragraph;
					color: @bruin-donkerst;
				}
			}


		}
	}
	.modal-title{
		padding-bottom: 10px;
		@media (min-width: @screen-sm-min) {
			padding-bottom: 80px;
		}
	}

}

.single-post {
	.wrapper{
		.a2a_kit {
			line-height: 13px !important;
			padding-left: 15px;
			.a2a_dd {
				display: inline;
			}
		}
	}
}

.share-content, .addtoany_no_icon {
	padding: 0;
	display: block;
	.sans-serif-small;
	font-size: 13px !important;
	margin-top: 0px;
	color: @color-white;
	text-decoration: none !important;
	&:hover, &:active, &:focus {
		text-decoration: underline !important;
		outline: 0;
	}
	&:before {
		content: url(../../img/ASF_share_button_light.svg);
		margin-right: 10px;
		position: relative;
		top: 4px;
	}
}

.a2a_mini_services {
	.sans-serif-book;
	a {
		&:nth-child(-n+2){
			display: block !important;
		}
		&:nth-last-child(-n+10) {
			display: none;
		}
	}
}

.media-single {
	.published {
		color: rgba(190,190,190,1);
	}
}


.single__img-link{
	text-align: center;
	position: relative;

}

.modal .container-div .row .half-column img.img-link-icon{
	position: absolute;
	z-index: 2;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	height: 70px;
	width: 70px;
}


/*

.container-single {
	.container-ats;
	position: relative;
	padding: 145px 0;

	.close {
		.square(16px);
		background: url(../img/close.svg);
		display: block;
		position: absolute;
		right: 37px;
		top: 28px;
	}

	.breadcrumbs {
		font-family: 'AvenirLTStd-Medium';
		font-size: 18px;
		line-height: 1.22;
		color: @grijs-inactive;
		position: absolute;
		top: 28px;
		left: 37px;
	}

	.breadcrumbs-bottom {
		top: auto;
		bottom: 28px;
	}

	.close-bottom {
		top: auto;
		bottom: 28px;
	}
}

.post-single {
	max-width: 788px;
	margin: 0 auto;
	//overflow: hidden;
	position: relative;
	.clearfix();

	.images, .text {
		float: left;
		width: 50%;
	}

	.images {
		padding-right: 10px;

		figure {
			width: 100%;
			padding-right: 20px;
			margin-bottom: 40px;
		}

		img {
			max-width: 100%;
		}
	}

	figcaption {
		font-size: 11px;
		line-height: 1.45;
		font-weight: 300;
		margin-top: 12px;
		padding-right: 35px;
	}

	.text {
		padding-left: 10px;
	}

	.single-post-heading {
		font-family: @font-family-sans-serif;
		line-height: 1.2;
		font-weight: 400;
		margin-top: 0;
		color: #fff;
	}

	.published {
		font-family: 'AvenirLTStd-Book', @font-family-sans-serif;
		font-size: 13px;
		line-height: 1.69;
		color: @grijs-donkerst;
		margin-bottom: 20px;
		display: inline-block;
	}

	.share-content {
		font-family: 'AvenirLTStd-Book', @font-family-sans-serif;
		font-size: 13px;
		line-height: 2.3;
		color: #fff;

		&:before {
			content: url(../img/ASF_share_button_dark.svg);
			margin-right: 10px;
			position: relative;
			top: 4px;
		}
	}

	p {
		font-size: 15px;
		line-height: 1.46;
	}

	.button-nav {
		.square(40px);
		display: block;
		//background: rgba(0, 0, 0, .2);
		background-color: #000;
		background-repeat: no-repeat;
		background-position: center center;
		position: absolute;
		top: 50%;
		margin-top: -20px;
		opacity: 0.2;

		&:hover {
			opacity: 0.3
		}

		&.next {
			right: -80px;
			background-image: url(../img/arrow_right.png);
		}

		&.prev {
			left: -80px;
			background-image: url(../img/arrow_left.png);
		}
	}
}

.entry {
	p, ol, ul, img {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 10px;
		}
	}
}

*/

